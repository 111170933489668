import { useState, createContext, useEffect, useCallback } from "react";
import { redirect } from "react-router-dom";
import { API_URI } from "../util/urls";
import { DataGridHeaders, createDataGridHeaders } from "../components/data-entry/headers";

let { name, version } = require("../../package.json");

export type UserRole = "Admin" | "User";
export type ReportingState = "NotStarted" | "Open" | "Closed" | "Locked";

export type User = {
    id: number;
    name: string;
    role: number;
    partnerId: number | null;
    token: string;
    userRole: UserRole;
    reportingState: ReportingState;
};

export type Organization = {
    uid?: number;
    name?: string;
};

export type EnterpriseInProjectState = {
    uid: number;
    projectInitialName: string;
    projectUID: number;
    entOrgInitialName: string;
    entOrgUID: null;
    regionID: number;
    regionName: string;
    countryUID: number;
    countryName: string;
    sectorUID: number;
    sectorName: string;
    areaUID: number;
    areaCode: "Urban";
    businessStatusUID: number;
    businessStatusName: string;
    enterpriseStartupYear: number;
    dateFormalized: null;
    missionUID: number;
    missionDesc: string;
    partnerServiceLine: string;
    interventionStartDate: string;
    interventionEndDate: string;
    baselineYear: number;
    revenueCurrencyUID: number;
    revenueCurrencyCode: string;
    revenue2011: number;
    revenue2012: number;
    revenue2013: number;
    revenue2014: number;
    revenue2015: number;
    revenue2016: number;
    revenue2017: number;
    revenue2018: number;
    revenue2019: number;
    revenue2020: number;
    revenue2021: number;
    revenue2022: number;
    revenue2023: number;
    fullTimeEmpl2011: number;
    fullTimeEmpl2012: number;
    fullTimeEmpl2013: number;
    fullTimeEmpl2014: number;
    fullTimeEmpl2015: number;
    fullTimeEmpl2016: number;
    fullTimeEmpl2017: number;
    fullTimeEmpl2018: number;
    fullTimeEmpl2019: number;
    fullTimeEmpl2020: number;
    fullTimeEmpl2021: number;
    fullTimeEmpl2022: number;
    fullTimeEmpl2023: number;
    partTimeEmpl2011: number;
    partTimeEmpl2012: number;
    partTimeEmpl2013: number;
    partTimeEmpl2014: number;
    partTimeEmpl2015: number;
    partTimeEmpl2016: number;
    partTimeEmpl2017: number;
    partTimeEmpl2018: number;
    partTimeEmpl2019: number;
    partTimeEmpl2020: number;
    partTimeEmpl2021: number;
    partTimeEmpl2022: number;
    partTimeEmpl2023: number;
    financeCurrencyUID: number;
    financeCurrencyCode: number;
    finance2011: number;
    financeType2011UID: number;
    financeType2011Name: string;
    finance2012: number;
    financeType2012UID: number;
    financeType2012Name: string;
    finance2013: number;
    financeType2013UID: number;
    financeType2013Name: string;
    finance2014: number;
    financeType2014UID: number;
    financeType2014Name: string;
    finance2015: number;
    financeType2015UID: number;
    financeType2015Name: string;
    finance2016: number;
    financeType2016UID: number;
    financeType2016Name: string;
    finance2017: number;
    financeType2017UID: number;
    financeType2017Name: string;
    finance2018: number;
    financeType2018UID: number;
    financeType2018Name: string;
    finance2019: number;
    financeType2019UID: number;
    financeType2019Name: string;
    finance2020: number;
    financeType2020UID: number;
    financeType2020Name: string;
    finance2021: number;
    financeType2021UID: number;
    financeType2021Name: string;
    finance2022: number;
    financeType2022UID: number;
    financeType2022Name: string;
    finance2023: number;
    financeType2023UID: number;
    financeType2023Name: string;
    seekingExternalFinance: number;
    reportingStatusUID: number;
    reportingStatusName: string;
    comments: string;
};

export type AppContextType = {
    user: User | null;
    setUser: (user: User | null) => void;
    selectedOrganizationsAdmin: Organization;
    setSelectedOrganizationsAdmin: any;
    sessionCode: string | undefined;
    setSessionCode: (code: undefined | string) => void;
    grid: any;
    setGrid: any;
    enterpriseReports: Array<EnterpriseInProjectState>;
    setEnterpriseReports: (reports: Array<EnterpriseInProjectState>) => void;
    historical: any;
    setHistorical: any;
    sortFiltered: any;
    setSortFiltered: any;
    done: boolean;
    setDone: any;
    dataQualityIndex: number;
    setDataQualityIndex: any;
    gridHealth: any;
    setGridHealth: any;
    closedCount: number;
    setClosedCount: any;
    page: number;
    setPage: (p: number) => void;
    selectedProjects: any;
    setSelectedProjects: any;
    selectedCountries: any;
    setSelectedCountries: any;
    selectedYears: any;
    setSelectedYears: any;
    headers: any;
    setHeaders: any;
    selectedEnterprise: any;
    setSelectedEnterprise: any;
    project: any;
    setProject: any;
    projectInfo: any;
    setProjectInfo: any;
    admin: any;
    setAdmin: any;
    selectedRows: any;
    setSelectedRows: any;
    totalPages: any;
    setTotalPages: any;
    search: any;
    setSearch: any;
    projectData: any;
    setProjectData: any;
    employmenData: any;
    setEmploymenData: any;
    showErrors: any;
    setShowErrors: any;
    selectedProjectAdminFilter: any;
    setSelectedProjectAdminFilter: any;
    projectsOrganization: any;
    setProjectsOrganization: any;
    selectedProject: any;
    setSelectedProject: any;
    selects: any;
    setSelects: any;
    uploadErrors: any;
    setUploadErrors: any;
    toggleLoggedUser: any;
    emptySearch: any;
    partnerUid: number | null;
    setPartnerUid: (uid: number | null) => void;
    updateCell: any;
    setReportingYearsList: any;
    reportingYearsList: any;
    getReportingYearsList: any;
    selectedHeaders: any;
    setSelectedHeaders: (a: any) => void;
    gridData: DataGridHeaders;
    setGridData: (a: any) => void;
};

export const appContextDefaults: AppContextType = {
    user: null,
    setUser: (user: User | null) => {},
    selectedOrganizationsAdmin: {},
    setSelectedOrganizationsAdmin: () => {},
    sessionCode: undefined,
    setSessionCode: (code: undefined | string) => {},
    grid: null,
    setGrid: null,
    enterpriseReports: [],
    setEnterpriseReports: () => {},
    historical: null,
    setHistorical: () => {},
    sortFiltered: null,
    setSortFiltered: () => {},
    done: false,
    setDone: () => {},
    dataQualityIndex: 0,
    setDataQualityIndex: () => {},
    gridHealth: null,
    setGridHealth: () => {},
    closedCount: 0,
    setClosedCount: () => {},
    page: 0,
    setPage: (p: number) => {},
    selectedProjects: null,
    setSelectedProjects: () => {},
    selectedCountries: null,
    setSelectedCountries: () => {},
    selectedYears: null,
    setSelectedYears: () => {},
    headers: null,
    setHeaders: () => {},
    selectedEnterprise: null,
    setSelectedEnterprise: () => {},
    project: null,
    setProject: () => {},
    projectInfo: null,
    setProjectInfo: () => {},
    admin: null,
    setAdmin: () => {},
    selectedRows: null,
    setSelectedRows: () => {},
    totalPages: null,
    setTotalPages: () => {},
    search: null,
    setSearch: () => {},
    projectData: null,
    setProjectData: () => {},
    employmenData: null,
    setEmploymenData: () => {},
    showErrors: null,
    setShowErrors: () => {},
    selectedProjectAdminFilter: null,
    setSelectedProjectAdminFilter: () => {},
    projectsOrganization: null,
    setProjectsOrganization: () => {},
    selectedProject: null,
    setSelectedProject: () => {},
    selects: null,
    setSelects: () => {},
    uploadErrors: null,
    setUploadErrors: () => {},
    toggleLoggedUser: () => {},
    emptySearch: null,
    partnerUid: null,
    setPartnerUid: () => {},
    updateCell: () => {},
    setReportingYearsList: () => {},
    reportingYearsList: null,
    getReportingYearsList: () => {},
    selectedHeaders: {},
    setSelectedHeaders: () => {},
    gridData: createDataGridHeaders(),
    setGridData: () => {},
};
export const AppContext = createContext<AppContextType | null>(null) as React.Context<AppContextType>;

const AppContextProvider = (props: any) => {
    const [selectedOrganizationsAdmin, setSelectedOrganizationsAdmin] = useState<Organization>({ uid: undefined, name: "" });
    const [user, setUser] = useState<null | User>(null);
    const [sessionCode, setSessionCode] = useState<string | undefined>(undefined);
    const [grid, setGrid] = useState([]);
    const [enterpriseReports, setEnterpriseReports] = useState<Array<EnterpriseInProjectState>>([]);
    const [historical, setHistorical] = useState([]);
    const [sortFiltered, setSortFiltered] = useState([]);
    const [done, setDone] = useState(true);
    const [dataQualityIndex, setDataQualityIndex] = useState(0);
    const [gridHealth, setGridHealth] = useState([]);
    const [closedCount, setClosedCount] = useState(0);
    const [page, setPage] = useState(1);
    const [selectedProjects, setSelectedProjects] = useState("");
    const [selectedCountries, setSelectedCountries] = useState([]);
    const [selectedYears, setSelectedYears] = useState([]);
    const [headers, setHeaders] = useState([]);
    const [selectedEnterprise, setSelectedEnterprise] = useState("");
    const [project, setProject] = useState([]);
    const [projectInfo, setProjectInfo] = useState({});
    const [partnerUid, setPartnerUid] = useState<null | number>(null);
    const [admin, setAdmin] = useState({ projects: [], users: [] });
    const [selectedRows, setSelectedRows] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [search, setSearch] = useState("");
    const [projectData, setProjectData] = useState({});
    const [employmenData, setEmploymenData] = useState({});
    const [showErrors, setShowErrors] = useState(false);
    const [selectedProjectAdminFilter, setSelectedProjectAdminFilter] = useState({});
    const [projectsOrganization, setProjectsOrganization] = useState([]);
    const [selectedProject, setSelectedProject] = useState("all");
    const [selects, setSelects] = useState([]);
    const [uploadErrors, setUploadErrors] = useState([]);
    const [reportingYearsList, setReportingYearsList] = useState([]);
    const [selectedHeaders, setSelectedHeaders] = useState<Record<string, boolean>>({});
    const [gridData, setGridData] = useState<DataGridHeaders>(createDataGridHeaders());
    const updateCell = (cell: any) => {
        const line = enterpriseReports[cell.row];
        //@ts-ignore
        line[cell.id] = cell.value;
        fetch(
            //@ts-ignore
            `${API_URI}/enterprise_report/${line.uid}/update`,
            {
                body: JSON.stringify(line),
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
    };

    const resetValues = () => {
        setEmploymenData({});
        setProjectData({});
        setDataQualityIndex(0);
        setPage(1);
        setEnterpriseReports([]);
        setShowErrors(false);
    };

    const toggleLoggedUser = useCallback((value: null | User) => {
        setUser(value);
        window.localStorage.setItem(`${name}-${version}`, JSON.stringify(value));
        resetValues();
        if (!value) {
            setSessionCode(undefined);
            document.cookie = "sessionId=null";
        }
    }, []);

    const emptySearch = () => {
        setSearch("");
    };

    const getReportingYearsList = () => {
        fetch(`${API_URI}/admin/reportingYear/all`)
            .then((response) => response.json())
            .then((data) => {
                setReportingYearsList(data);
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    useEffect(() => {
        fetch(`${API_URI}/users/userinfo`)
            .then((response) => {
                if (response.status === 403) {
                    redirect("/");
                } else {
                    return response.json();
                }
            })
            .then((data) => {
                if (data) {
                    data.role = data.userRole === "Admin" ? 0 : 1;
                    if (data.role === 1) {
                        if (data.partnerId !== undefined) {
                            setPartnerUid(data.partnerId); //set partner id when logged in
                        }
                    } else if (selectedOrganizationsAdmin.uid) {
                        setPartnerUid(selectedOrganizationsAdmin.uid);
                    }
                    toggleLoggedUser(data);
                    if (data.userRole === "Admin") {
                        getReportingYearsList();
                    }
                }
            });
    }, [setPartnerUid, toggleLoggedUser, selectedOrganizationsAdmin.uid, sessionCode]);

    return (
        <AppContext.Provider
            value={{
                selectedOrganizationsAdmin,
                setSelectedOrganizationsAdmin,
                user,
                setUser,
                sessionCode,
                setSessionCode,
                grid,
                setGrid,
                enterpriseReports,
                setEnterpriseReports,
                historical,
                setHistorical,
                sortFiltered,
                setSortFiltered,
                done,
                setDone,
                dataQualityIndex,
                setDataQualityIndex,
                gridHealth,
                setGridHealth,
                closedCount,
                setClosedCount,
                page,
                setPage,
                selectedProjects,
                setSelectedProjects,
                selectedCountries,
                setSelectedCountries,
                selectedYears,
                setSelectedYears,
                headers,
                setHeaders,
                selectedEnterprise,
                setSelectedEnterprise,
                project,
                setProject,
                projectInfo,
                setProjectInfo,
                admin,
                setAdmin,
                selectedRows,
                setSelectedRows,
                totalPages,
                setTotalPages,
                search,
                setSearch,
                projectData,
                setProjectData,
                employmenData,
                setEmploymenData,
                showErrors,
                setShowErrors,
                selectedProjectAdminFilter,
                setSelectedProjectAdminFilter,
                projectsOrganization,
                setProjectsOrganization,
                selectedProject,
                setSelectedProject,
                selects,
                setSelects,
                uploadErrors,
                setUploadErrors,
                toggleLoggedUser,
                emptySearch,
                partnerUid,
                setPartnerUid,
                updateCell,
                setReportingYearsList,
                reportingYearsList,
                getReportingYearsList,
                selectedHeaders,
                setSelectedHeaders,
                setGridData,
                gridData,
            }}
        >
            {props.children}
        </AppContext.Provider>
    );
};

export default AppContextProvider;
