import { IconButton, MenuItem, Select, SelectChangeEvent, TableCell, TextField, Tooltip } from "@mui/material"
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import { useContext } from "react";
import { AdminContext } from "../../../context/AdminContext";
import { API_URI } from "../../../util/urls";

export const EditPartnerForm = (props: any) => {
    const { setForm, row, form, defaultPartnerStateIndex, partnerStates, getPreviousAndNextPartnerState, setOpenEditForm, editFormId, setMessage } = props;
    const { countries, regions, getAllPartnerData } = useContext(AdminContext)

    const updateUserForm = (partnerId: number) => {
        fetch(`${API_URI}/admin/partner/${partnerId}/update`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(form),
        })
            .then((response) => response.json())
            .then((data) => {
                console.log('Success:', data);
                setMessage("Partner Successfully Updated")
                getAllPartnerData()
            })
            .catch((error) => {
                console.error('Error:', error);
                setMessage("Something went wrong!")
            });
    }
    return <>
        <TableCell align="left"> {row.id}  </TableCell>
        <TableCell align="left">
            <TextField
                id="standard-size-small"
                defaultValue={form.name}
                size="small"
                variant="standard"
                onChange={(e) => setForm({ ...form, "name": e.target.value })}
            />
        </TableCell>
        <TableCell align="left">
            <Select
                sx={{ width: "160px" }}
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={form.active}
                onChange={(e: SelectChangeEvent) => setForm({ ...form, "active": e.target.value })}
                label="regions"
                size='small'
            >
                <MenuItem value="true">Active
                </MenuItem>
                <MenuItem value="false">Inactive
                </MenuItem>
            </Select>
        </TableCell>
        <TableCell align="left">
            <TextField
                id="standard-size-small"
                defaultValue={form.salesForceId}
                size="small"
                variant="standard"
                onChange={(e) => setForm({ ...form, "salesForceId": e.target.value })}
            />
        </TableCell>
        <TableCell align="left">
            <Select
                sx={{ width: "160px" }}
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={form.countryId}
                onChange={(e: SelectChangeEvent) => setForm({ ...form, "countryId": e.target.value })}
                label="countries"
                size='small'
            >
                {/* @ts-ignore */}
                {countries.map(c => <MenuItem key={c.id} value={c.id}>{c.name}
                </MenuItem>)}
            </Select>
        </TableCell>
        <TableCell align="left">
            <Select
                sx={{ width: "160px" }}
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={form.regionId}
                onChange={(e: SelectChangeEvent) => setForm({ ...form, "regionId": e.target.value })}
                label="regions"
                size='small'
            >
                {/* @ts-ignore */}
                {regions.map(c => <MenuItem key={c.id} value={c.id}>{c.name}
                </MenuItem>)}
            </Select>
        </TableCell>
        <TableCell align="left" sx={{ display: "flex", alignItems: "center" }}>
            {(defaultPartnerStateIndex && form.reportingState) && (partnerStates.indexOf(form.reportingState) > defaultPartnerStateIndex) && <SkipPreviousIcon onClick={() => form.reportingState && setForm({ ...form, "reportingState": getPreviousAndNextPartnerState(form.reportingState, "prev") })} sx={{ color: "#416e71", cursor: "pointer" }} />}
            <span>{form.reportingState}</span>
            {form.reportingState !== "Locked" && <SkipNextIcon onClick={() => form.reportingState && setForm({ ...form, "reportingState": getPreviousAndNextPartnerState(form.reportingState, "next") })} sx={{ color: "#416e71", cursor: "pointer" }} />}
            <Tooltip title="Cancel Change">
                <IconButton onClick={() => setOpenEditForm(false)}>
                    <ClearIcon sx={{ color: "red", cursor: "pointer" }} />
                </IconButton>
            </Tooltip>
            <Tooltip title="Save Status">
                <IconButton onClick={() => {
                    updateUserForm(editFormId)
                    setOpenEditForm(false)
                }
                }>
                    <CheckIcon
                        sx={{ color: "green", cursor: "pointer" }} />
                </IconButton>
            </Tooltip>
        </TableCell>
    </>
}