import { useContext, useState } from "react";
import "./Grid.scss";
import { AppContext, EnterpriseInProjectState } from "../../context/AppContext";
import DataEntryCell from "./DataEntryCell";
import { Box, Checkbox } from "@mui/material";
import { API_URI } from "../../util/urls";
import { Stack } from "@mui/system";
import { GridPagination } from "./GridPagination";
import { GridHeaders } from "./GridHeaders";
import { Titles } from "./headers";

export type EnterpriseSelectionState = {
    uid: number;
    checked: boolean;
};

export type SubHeader = {
    id: string;
    hide?: boolean;
    type: string;
    select?: string;
    default?: boolean;
    show?: boolean;
    quality: any;
    list?: any;
    info?: any;
    name?: string | number;
    title: string;
};

function Grid(props: { titles: Titles; subHeaders: SubHeader[]; getGridData: any; setMessage: any }) {
    const style = "row content";
    const { enterpriseReports, totalPages, page, setPage, partnerUid, selectedHeaders, setSelectedHeaders } = useContext(AppContext);
    const [allChecked, setAllChecked] = useState(false);

    const { subHeaders } = props;
    const [multipleSelect, setMultipleSelect] = useState(false);
    const [enterpriseSelectionState, setEnterpriseSelectionState] = useState<Array<EnterpriseSelectionState>>([{ uid: -1, checked: false }]);

    let filterSelected = enterpriseSelectionState.filter((s) => s.checked === true).map((c) => c.uid);
    const boxStyle = {
        marginLeft: "180px",
        backgroundColor: "#ddd",
        maxWidth: "180px",
        textAlign: "center",
        pt: 0.5,
        pb: 0.5,
        cursor: "pointer",
        fontWeight: "700",
    };
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAllChecked(event.target.checked);
    };

    return (
        <div>
            <div className="holder-grid">
                <div>
                    <GridHeaders setSelectedHeaders={setSelectedHeaders} selectedHeaders={selectedHeaders} />
                    {enterpriseReports.map((l: EnterpriseInProjectState, itemIndex: number) => {
                        return (
                            <div key={l.uid}>
                                <div className={style}>
                                    {subHeaders.map((sh, cellIndex) => {
                                        if (selectedHeaders[sh.id]) {
                                            return (
                                                <DataEntryCell
                                                    selectedHeaders={selectedHeaders}
                                                    rowId={itemIndex}
                                                    index={itemIndex}
                                                    line={l}
                                                    cell={sh}
                                                    cellIndex={cellIndex}
                                                    key={cellIndex}
                                                    multipleSelect={multipleSelect}
                                                    setEnterpriseSelectionState={setEnterpriseSelectionState}
                                                    enterpriseSelectionState={enterpriseSelectionState}
                                                    getGridData={props.getGridData}
                                                    setMessage={props.setMessage}
                                                    allChecked={allChecked}
                                                />
                                            );
                                        } else {
                                            return null;
                                        }
                                    })}
                                </div>
                            </div>
                        );
                    })}
                    {!multipleSelect && (
                        <Box sx={boxStyle} onClick={() => setMultipleSelect(!multipleSelect)}>
                            Select Multiple
                        </Box>
                    )}
                    {multipleSelect && (
                        <Box
                            sx={{
                                marginLeft: "180px",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "180px",
                            }}
                        >
                            <span>Select All</span>
                            <Checkbox
                                sx={{
                                    color: "purple",
                                    "&.Mui-checked": {
                                        color: "purple",
                                    },
                                }}
                                checked={allChecked}
                                onChange={handleChange}
                                inputProps={{ "aria-label": "controlled" }}
                            />
                        </Box>
                    )}
                    {multipleSelect && (
                        <Box
                            sx={boxStyle}
                            onClick={() => {
                                if (filterSelected.length >= 1 && !allChecked) {
                                    fetch(`${API_URI}/enterprise_report/bulk/update`, {
                                        method: "PUT",
                                        headers: {
                                            "Content-Type": "application/json",
                                        },
                                        body: JSON.stringify({
                                            ids: filterSelected,
                                        }),
                                    }).then(async (response) => {
                                        if (response.status === 403) {
                                            props.setMessage("Current reporting state does not allow editing !");
                                        } else if (response.status === 400) {
                                            props.setMessage("This id does not correspond to a 'purple cell'");
                                        } else if (!response.ok) {
                                            props.setMessage("Something went wrong!");
                                        } else if (response.ok) {
                                            props.getGridData();
                                            props.setMessage("Enterprises Successfully Updated");
                                        }
                                    });
                                    setEnterpriseSelectionState([{ uid: -1, checked: false }]);
                                    setMultipleSelect(!multipleSelect);
                                } else if (allChecked) {
                                    fetch(`${API_URI}/enterprise_report/bulk/update?partnerId=${partnerUid}&all=true`, {
                                        method: "PUT",
                                        headers: {
                                            "Content-Type": "application/json",
                                        },
                                        body: JSON.stringify({
                                            ids: filterSelected,
                                        }),
                                    }).then(async (response) => {
                                        if (response.ok) {
                                            const data = await response.json();
                                            if (data.createdEnterprises === 0) {
                                                props.setMessage("Please fix partner project first");
                                            } else {
                                                props.getGridData();
                                                props.setMessage("Enterprises Successfully Updated");
                                            }
                                        } else if (!response.ok) {
                                            props.setMessage("Something went wrong!");
                                        }
                                    });
                                } else {
                                    props.setMessage("Select at least one purple Enterprise to update it");
                                }
                            }}
                        >
                            Add as new enterprise
                        </Box>
                    )}
                </div>
            </div>
            <Stack spacing={2} sx={{ display: "flex", alignItems: "flex-start" }}>
                <GridPagination {...{ totalPages, setPage, page }} />
            </Stack>
        </div>
    );
}
export default Grid;
