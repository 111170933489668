import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    Typography,
    Autocomplete,
    TextField,
    FormControl,
    Select,
    InputLabel,
    MenuItem,
    SelectChangeEvent,
} from "@mui/material";
import { useCallback, useContext, useEffect, useState } from "react";
import { AppContext } from "../context/AppContext";
import { API_URI } from "../util/urls";
import axios from "axios";
import { LoadingButton } from "@mui/lab";
import { AdminContext } from "../context/AdminContext";
import { projectStatuses } from "../pages/admin/project/CreateProjectForm";
import { Project } from "./Drawer";

type Props = {
    openDialog: boolean;
    setOpenDialog: (a: boolean) => void;
    projectList: any;
    setProjectList: (a: any) => void;
    filteredProjects: Project[];
    setFilteredProjects: (a: any) => void;
};

export const ExportExcelDialog = ({
    openDialog,
    setOpenDialog,
    projectList,
    setProjectList,
    filteredProjects,
    setFilteredProjects,
}: Props) => {
    const [year, setYear] = useState("");
    const [selectedProjectsId, setSelectedProjectsId] = useState<Array<number>>(
        []
    );
    const [selectedPartnersId, setSelectedPartnersId] = useState<Array<number>>(
        []
    );
    const [selectedStatuses, setSelectedStatuses] = useState<Array<string>>([]);
    const [loading, setLoading] = useState(false);
    const [notification, setNotification] = useState<string | null>();
    const { reportingYearsList } = useContext(AppContext);
    const { activePartnerList } = useContext(AdminContext);
    const handleClose = () => {
        setOpenDialog(false);
    };

    const handleChange = (event: SelectChangeEvent) => {
        setYear(event.target.value as string);
        getProjectsAccordingYear(parseInt(event.target.value));
    };

    const handleExport = () => {
        const exportData = {
            partnerIds:
                selectedPartnersId.length < 1 ? null : selectedPartnersId,
            projectIds:
                selectedProjectsId.length < 1 ? null : selectedProjectsId,
            projectStatuses:
                selectedStatuses.length < 1 ? null : selectedStatuses,
        };
        if (year.length < 1) {
            setNotification("Year should be selected");
        } else {
            setNotification("Download can take a long time.");
            setLoading(true);
            axios
                .post(`${API_URI}/partner/all/download_excel`, exportData, {
                    params: {
                        reportingYearId: year,
                    },
                    responseType: "arraybuffer",
                })
                .then((response) => {
                    const blob = new Blob([response.data], {
                        type: "application/excel",
                    });
                    const link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob);
                    const date = new Date();
                    link.download = `argidius-data-export-${date.getDate()}-${
                        date.getMonth() + 1
                    }-${date.getFullYear()}.xlsx`;
                    link.click();
                    setLoading(false);
                    setNotification("File successfully downloaded.");
                })
                .catch((err) => {
                    setNotification(err.response.statusText);
                    setLoading(false);
                });
        }
    };

    const getProjectsAccordingYear = useCallback(
        (yearId: number) => {
            fetch(
                `${API_URI}/admin/project/all?reportingYearId=${yearId}&format=short`
            )
                .then((response) => response.json())
                .then((data: Array<Project>) => {
                    setProjectList(data);
                })
                .catch((error) => console.log(error));
        },
        [setProjectList]
    );

    useEffect(() => {
        getProjectsAccordingYear(parseInt(year));
    }, [getProjectsAccordingYear, year]);

    useEffect(() => {
        // Filter projects based on selected partners and statuses
        const filterProject = (
            projectList: Project[],
            selectedPartnersId: number[],
            selectedStatuses: string[]
        ) => {
            return projectList?.filter((project: Project) => {
                if (
                    selectedStatuses.length >= 1 &&
                    selectedPartnersId.length >= 1
                ) {
                    const partnerFilter = selectedPartnersId.includes(
                        project.partnerId
                    );

                    const statusFilter = selectedStatuses.includes(
                        project.projectStatus
                    );

                    return partnerFilter && statusFilter;
                } else if (
                    selectedStatuses.length >= 1 &&
                    selectedPartnersId.length < 1
                ) {
                    const statusFilter = selectedStatuses.includes(
                        project.projectStatus
                    );

                    return statusFilter;
                } else if (
                    selectedStatuses.length < 1 &&
                    selectedPartnersId.length >= 1
                ) {
                    const partnerFilter = selectedPartnersId.includes(
                        project.partnerId
                    );
                    return partnerFilter;
                } else {
                    return true;
                }
            });
        };

        const filtered = filterProject(
            projectList,
            selectedPartnersId,
            selectedStatuses
        );

        setFilteredProjects(filtered);
    }, [
        projectList,
        selectedPartnersId,
        selectedStatuses,
        setFilteredProjects,
    ]);
    return (
        <Dialog
            open={openDialog}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Select year,partner,project and status to export excel file"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <FormControl fullWidth size="small" variant="standard">
                        <InputLabel id="years" size="small">
                            Year
                        </InputLabel>
                        <Select
                            size="small"
                            labelId="years"
                            id="years"
                            label="Years"
                            value={year}
                            onChange={handleChange}
                        >
                            {reportingYearsList.map(
                                (s: { id: number; year: number }) => (
                                    <MenuItem key={s.id} value={s.id}>
                                        {s.year}
                                    </MenuItem>
                                )
                            )}
                        </Select>
                    </FormControl>
                    <Autocomplete
                        multiple
                        id="status-select"
                        options={projectStatuses}
                        value={selectedStatuses}
                        onChange={(event, newValue) => {
                            setSelectedStatuses(newValue);
                            setSelectedProjectsId([]);
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Project Status"
                                size="small"
                                variant="standard"
                            />
                        )}
                    />
                    <Autocomplete
                        multiple
                        id="partners-select"
                        options={activePartnerList}
                        getOptionLabel={(option) => option.name}
                        value={activePartnerList.filter((partner) =>
                            selectedPartnersId.includes(partner.id)
                        )}
                        onChange={(event, newValue) => {
                            setSelectedPartnersId(
                                newValue.map((partner) => partner.id)
                            );
                            setSelectedProjectsId([]);
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Partner"
                                size="small"
                                variant="standard"
                            />
                        )}
                    />

                    <Autocomplete
                        multiple
                        id="projects"
                        options={filteredProjects || []}
                        getOptionLabel={(option) => option.prjID.toString()}
                        value={filteredProjects.filter((project: Project) =>
                            selectedProjectsId.includes(project.id)
                        )}
                        onChange={(event, newValue) => {
                            setSelectedProjectsId(
                                newValue.map((project: Project) => project.id)
                            );
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Project"
                                size="small"
                                variant="standard"
                                disabled={year.length < 1}
                            />
                        )}
                    />
                </DialogContentText>
                {notification && (
                    <Typography variant="caption" color="red">
                        {notification}
                    </Typography>
                )}
            </DialogContent>
            <DialogActions>
                <Button size="small" onClick={handleClose}>
                    Cancel
                </Button>
                <LoadingButton
                    size="small"
                    onClick={() => {
                        handleExport();
                    }}
                    autoFocus
                    loading={loading}
                    variant="contained"
                >
                    <span>Export</span>
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};
