import { API_URI } from "../util/urls";

export type Enterprise = {
    uid: number,
    name: string,
    projectUID: number
}

export async function fetchEnterprises(partnerUid: number): Promise<Array<Enterprise>> {
    const response = await fetch(`${API_URI}/partner/${partnerUid}/enterprises/all`);
    return await response.json()
}