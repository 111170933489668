import CreatePasswordForm from '../components/user/CreatePasswordForm'
import logo from '../assets/argidius-logo.jpg';
import logoSteward from '../assets/logo-steward-redqueen.png';
import Feedback from '../components/feedback/Feedback';

function CreatePassword() {

    return <>
        <main id="home">
            <span className="overlay"></span>
            <aside>
                <img src={logo} alt="Logo" />
                <CreatePasswordForm />
                <img src={logoSteward} alt="logoSteward" />
            </aside>
            <Feedback />
        </main>
    </>
}

export default CreatePassword
