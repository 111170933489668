import { useContext } from 'react';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import logo from "../../assets/argidius-logo.jpg";
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import './Bar.scss';
import { AppContext } from '../../context/AppContext';

function Bar() {
    const { toggleLoggedUser, user } = useContext(AppContext);
    const navigate = useNavigate();
    const location = useLocation();

    const getActive = (url: string) => {
        return location.pathname === url ? 'active' : '';
    };
    return <>
        <header>
            <div className="top">
                <span className="md-title">
                    <img src={logo} alt="logo" />
                </span>
                <div className="title">
                    Enterprise-Level Data Portal
                </div>
                <div className="utils">
                    <span className="name">{user && user.name}</span>
                    {user && user.userRole === "Admin" ? <Button sx={{ color: "gray" }} variant="text" onClick={() => navigate("/admin/users")}><SettingsApplicationsIcon sx={{ color: "gray" }} /></Button> : ""}
                    <Button variant="text" onClick={() => { toggleLoggedUser(null); navigate("/") }}><ExitToAppIcon sx={{ color: "gray" }} /></Button>
                </div>
            </div>
            <div className="green-bar">
            </div>
            <menu>
                <li>
                    <Button sx={{
                        textTransform: "capitalize", color: "#1D5D63", backgroundColor: "#FFFFFF", marginLeft: ".5rem", fontSize: "16px", borderRadius: "0px", '&:hover': {
                            backgroundColor: '#FFFFFFC3',
                        },
                    }} className={getActive('/data-entry')} variant="text" onClick={() => navigate("/data-entry")}>Data submission</Button>
                </li>
            </menu>
        </header >
    </>
}

export default Bar
