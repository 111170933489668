import React, { useCallback, useContext, useEffect, useState } from 'react'
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../context/AppContext';
import { API_URI } from '../../util/urls';
import './LoginForm.scss'
import { ReportingYearClosedMessage } from '../../util/messages';
import { isReportingYearClosed } from '../../util/checks';


interface State {
    amount: string;
    password: string;
    weight: string;
    weightRange: string;
    showPassword: boolean;
}
interface User {
    username: string,
    password: string
}

export const LoginForm = () => {
    const navigate = useNavigate();

    const { user, setSessionCode } = useContext(AppContext)
    const [values, setValues] = useState<State>({
        amount: '',
        password: '',
        weight: '',
        weightRange: '',
        showPassword: false,
    });
    const [form, setForm] = useState<User>({ username: '', password: '' });
    const [message, setMessage] = useState<string | React.ReactElement | null>(null);

    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const checkLogin = useCallback((event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        let formBody = [];
        for (const property in form) {
            const encodedKey = encodeURIComponent(property);
            //@ts-ignore
            const encodedValue = encodeURIComponent(form[property]);
            formBody.push(encodedKey + "=" + encodedValue);
        }

        //@ts-ignore
        formBody = formBody.join("&");

        fetch(
            `${API_URI}/users/login`, {
            //@ts-ignore
            body: formBody,
            method: 'POST',
            credentials: 'same-origin',
            'headers': {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
            }
        }
        )
            .then(async response => {
                const data = await response.json()
                switch (response.status) {
                    case 403:
                        if (data.errorMessage.indexOf('disabl') > 0)
                            data.errorMessage = 'To access the Argidius Enterprise Portal, please contact <a href="mailto:support@stewardredqueen.com">support@stewardredqueen.com</a>'
                        setMessage(data.errorMessage);
                        break;
                    case 200:
                        setSessionCode(data.sessionCode);
                        break;
                }
            })
            .catch((e) => {
                if (e.response.data.password) {
                    setMessage("Incorrect password.");
                }
                else {
                    setMessage("User not found");
                }
            });
    }, [form, setSessionCode]);

    useEffect(() => {
        if (user && user.userRole === 'User' && user.partnerId === null) {
            setMessage('You don\'t have any projects assigned to your account, please contact <a href="mailto:support@stewardredqueen.com">support@stewardredqueen.com</a>');
        }
        else if (isReportingYearClosed(user)) {
            setMessage(<ReportingYearClosedMessage />)
        }
        else if (user) {
            navigate("data-entry")
        }
    }, [user, navigate])

    return <>
        <div className="loginComponent">
            <h2 className='title'>Enterprise-Level Data Portal</h2>
            <Box component="form" onSubmit={checkLogin} sx={{ marginTop: '-1rem' }} >
                <TextField
                    label="Email"
                    id="username"
                    sx={{ margin: '1rem 0', width: '100%' }}
                    name="username"
                    variant="standard"
                    type="email"
                    value={form.username}
                    required
                    onChange={(e) => setForm({ ...form, username: e.target.value })}
                />
                <FormControl sx={{ margin: '1rem 0', width: '100%' }} variant="standard">
                    <InputLabel htmlFor="password">Password *</InputLabel>
                    <Input
                        id="password"
                        type={values.showPassword ? 'text' : 'password'}
                        value={values.password}
                        name="password"
                        onChange={(e) => { setForm({ ...form, password: e.target.value }); setValues({ ...values, 'password': e.target.value }); }}
                        required
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                >
                                    {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </FormControl>
                {message && <p className='notificationBox'>{message}</p>}
                <Button sx={{ backgroundColor: '#1D5D63', '&:hover': { backgroundColor: '#1d5d63c9' } }} type="submit" fullWidth variant="contained">
                    Login
                </Button>
            </Box>
            <hr />
            <Box className="buttons" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button variant="text" href="/forgot-password">Forgot my password</Button>
                <Button variant="text" href="/disclaimer">Disclaimer</Button>
            </Box>
        </div>

    </>
}
