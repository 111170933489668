import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
} from "@mui/material";
import { useContext, useState } from "react";
import type { Form } from "./Project";

import { AdminContext } from "../../../context/AdminContext";
import MuiDatePicker from "../../../widgets/MuiDatePicker";
import { Box } from "@mui/system";
import { API_URI } from "../../../util/urls";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};
export const projectStatuses = ["Unstarted", "Ongoing", "Closed", "Invalid"];

export default function CreateProjectForm(props: any) {
    const { isOpen, setIsOpen, setMessage, getAllProjects } = props;
    const {
        regions,
        countries,
        segments,
        strategies,
        interventionfamilies,
        interventionGenuses,
        activePartnerList,
        currencies,
    } = useContext(AdminContext);

    const [form, setForm] = useState<Form>({});

    const boxStyle = {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        mt: 1,
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    const addProject = () => {
        fetch(`${API_URI}/admin/project/add`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(form),
        })
            .then((response) => response.json())
            .then((data) => {
                console.log("Success:", data);
                setMessage("Project Successfully Added.");
                getAllProjects();
            })
            .catch((error) => {
                console.error("Error:", error);
                setMessage("Something went wrong !");
            });
        setIsOpen(false);
    };

    return (
        <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={isOpen}
        >
            <BootstrapDialogTitle
                id="customized-dialog-title"
                onClose={handleClose}
            >
                Add new Project
            </BootstrapDialogTitle>
            <DialogContent
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    minWidth: "500px",
                }}
                dividers
            >
                <TextField
                    label="Project Name"
                    id="standard-size-small"
                    value={form.name}
                    size="small"
                    variant="standard"
                    onChange={(e) => setForm({ ...form, name: e.target.value })}
                />

                <TextField
                    label="PrjID"
                    id="standard-size-small"
                    value={form.prjID}
                    size="small"
                    variant="standard"
                    onChange={(e) =>
                        setForm({ ...form, prjID: e.target.value })
                    }
                />

                <TextField
                    label="Sales Force Id"
                    id="standard-size-small"
                    value={form.salesForceId}
                    size="small"
                    variant="standard"
                    onChange={(e) =>
                        setForm({ ...form, salesForceId: e.target.value })
                    }
                />

                <TextField
                    label="Fiscal Year"
                    id="standard-size-small"
                    value={form.fiscalYear}
                    size="small"
                    variant="standard"
                    onChange={(e) =>
                        setForm({ ...form, fiscalYear: e.target.value })
                    }
                />

                <TextField
                    label="Amount Financed"
                    id="standard-size-small"
                    value={form.amountFinanced}
                    size="small"
                    variant="standard"
                    onChange={(e) =>
                        setForm({
                            ...form,
                            amountFinanced: parseInt(e.target.value),
                        })
                    }
                />

                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <MuiDatePicker
                        setDate={setForm}
                        form={form}
                        name="startDate"
                    ></MuiDatePicker>
                    <MuiDatePicker
                        setDate={setForm}
                        form={form}
                        name="endDate"
                    ></MuiDatePicker>
                </Box>

                <Box sx={boxStyle}>
                    <Box>
                        <InputLabel id="partners">Partner</InputLabel>
                        <Select
                            sx={{ width: "160px" }}
                            labelId="partners"
                            id="partners"
                            value={form.partnerId}
                            onChange={(e: SelectChangeEvent) =>
                                setForm({ ...form, partnerId: e.target.value })
                            }
                            label="countries"
                            size="small"
                        >
                            {/* @ts-ignore */}
                            {activePartnerList.map((p) => (
                                <MenuItem key={p.id} value={p.id}>
                                    {p.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </Box>

                    <Box>
                        <InputLabel id="segments">Segment</InputLabel>
                        <Select
                            sx={{ width: "160px" }}
                            labelId="segments"
                            id="segments"
                            value={form.segmentId}
                            onChange={(e: SelectChangeEvent) =>
                                setForm({ ...form, segmentId: e.target.value })
                            }
                            label="segments"
                            size="small"
                        >
                            {/* @ts-ignore */}
                            {segments.map((s) => (
                                <MenuItem key={s.id} value={s.id}>
                                    {s.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </Box>

                    <Box>
                        <InputLabel id="strategies">Strategy</InputLabel>
                        <Select
                            sx={{ width: "160px" }}
                            labelId="strategies"
                            id="strategies"
                            value={form.strategyId}
                            onChange={(e: SelectChangeEvent) =>
                                setForm({ ...form, strategyId: e.target.value })
                            }
                            label="strategies"
                            size="small"
                        >
                            {/* @ts-ignore */}
                            {strategies.map((s) => (
                                <MenuItem key={s.id} value={s.id}>
                                    {s.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </Box>
                </Box>

                <Box sx={boxStyle}>
                    <Box>
                        <InputLabel id="Interventionfamily">
                            Intervention Family
                        </InputLabel>
                        <Select
                            sx={{ width: "160px" }}
                            labelId="Interventionfamily"
                            id="Interventionfamily"
                            value={form.interventionFamilyId}
                            onChange={(e: SelectChangeEvent) =>
                                setForm({
                                    ...form,
                                    interventionFamilyId: e.target.value,
                                })
                            }
                            label="Interventionfamily"
                            size="small"
                        >
                            {/* @ts-ignore */}
                            {interventionfamilies.map((s) => (
                                <MenuItem key={s.id} value={s.id}>
                                    {s.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </Box>

                    <Box>
                        <InputLabel id="InterventionGenus">
                            Intervention Genus
                        </InputLabel>
                        <Select
                            sx={{ width: "160px" }}
                            labelId="InterventionGenus"
                            id="InterventionGenus"
                            value={form.interventionGenusId}
                            onChange={(e: SelectChangeEvent) =>
                                setForm({
                                    ...form,
                                    interventionGenusId: e.target.value,
                                })
                            }
                            label="Interventiongenus"
                            size="small"
                        >
                            {/* @ts-ignore */}
                            {interventionGenuses.map((s) => (
                                <MenuItem key={s.id} value={s.id}>
                                    {s.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </Box>

                    <Box>
                        <InputLabel id="country">Country</InputLabel>
                        <Select
                            sx={{ width: "160px" }}
                            labelId="country"
                            id="demo-simple-select-standard"
                            value={form.countryId}
                            onChange={(e: SelectChangeEvent) =>
                                setForm({ ...form, countryId: e.target.value })
                            }
                            label="countries"
                            size="small"
                        >
                            {/* @ts-ignore */}
                            {countries.map((c, i) => (
                                <MenuItem key={i} value={c.id}>
                                    {c.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </Box>
                </Box>

                <Box sx={boxStyle}>
                    <Box>
                        <InputLabel id="region">Region</InputLabel>
                        <Select
                            sx={{ width: "160px" }}
                            labelId="region"
                            id="demo-simple-select-standard"
                            value={form.regionId}
                            onChange={(e: SelectChangeEvent) =>
                                setForm({ ...form, regionId: e.target.value })
                            }
                            label="regions"
                            size="small"
                        >
                            {/* @ts-ignore */}
                            {regions.map((c, i) => (
                                <MenuItem key={i} value={c.id}>
                                    {c.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </Box>

                    <Box>
                        <InputLabel id="currency">Currency</InputLabel>
                        <Select
                            sx={{ width: "160px" }}
                            labelId="currency"
                            id="demo-simple-select-standard"
                            value={form.currencyId}
                            onChange={(e: SelectChangeEvent) =>
                                setForm({ ...form, currencyId: e.target.value })
                            }
                            label="currencys"
                            size="small"
                        >
                            {/* @ts-ignore */}
                            {currencies.map((c, i) => (
                                <MenuItem key={i} value={c.id}>
                                    {c.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </Box>

                    <Box>
                        <InputLabel id="status">Project Status</InputLabel>
                        <Select
                            sx={{ width: "160px" }}
                            labelId="status"
                            id="demo-simple-select-standard"
                            value={form.projectStatus}
                            onChange={(e: SelectChangeEvent) =>
                                setForm({
                                    ...form,
                                    projectStatus: e.target.value,
                                })
                            }
                            label="statuss"
                            size="small"
                        >
                            {/* @ts-ignore */}
                            {projectStatuses.map((c, i) => (
                                <MenuItem key={i} value={c}>
                                    {c}
                                </MenuItem>
                            ))}
                        </Select>
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={addProject}>
                    Save
                </Button>
            </DialogActions>
        </BootstrapDialog>
    );
}
