import { useContext, useEffect, useState } from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { Autocomplete, Button, TextField } from "@mui/material";
import ProjectsSelection from "./ProjectsSelection";
import Projects from "./Projects";
import "./SideBar.scss";
import MuiStepper from "./MuiStepper";
import { AppContext } from "../../context/AppContext";
import { API_URI } from "../../util/urls";
import { Enterprise, fetchEnterprises } from "../../api/enterprises";
import { createDataGridHeaders } from "./headers";
import axios from "axios";

export type SideBarProps = {
    setMessage: (m: string) => void;
    setMissingColumns: any;
    getGridData: () => void;
    setUploadErrors: (m: boolean) => void;
    reportingYearClosed: boolean;
    setEnterpriseFilter: (enterpriseId: number | null) => void;
};

type ProjectUid = number;

const iconStyle = {
    marginRight: "0.5rem",
};

export const buttonStyle = {
    borderRadius: "0px",
    border: "solid 1px #EFAF0A",
    color: "#F0AE0B",
};

function SideBar({ setMessage, setMissingColumns, getGridData, setUploadErrors, reportingYearClosed, setEnterpriseFilter }: SideBarProps) {
    const { emptySearch, user, partnerUid, selectedHeaders } = useContext(AppContext);
    const [toggleModal, setToggleModal] = useState(false);
    const [projectsId, setProjectsId] = useState<Array<ProjectUid>>([]);
    const [enterprises, setEnterprises] = useState<Enterprise[]>([]);

    useEffect(() => {
        if (partnerUid) {
            fetch(`${API_URI}/partner/${partnerUid}/project/all`).then(async (response) => {
                const data = await response.json();
                setProjectsId(data.map((item: any) => item.id));
            });
            fetchEnterprises(partnerUid).then((enterprises) => {
                setEnterprises(enterprises);
            });
        }
    }, [partnerUid]);
    const headers = createDataGridHeaders();

    const allColumns = headers.columns.map((a: any) => a.columns.map((p: any) => p.id)).flat(1);

    const selectedHeadersName = Object.keys(selectedHeaders);
    const hiddenColumns = allColumns.filter((item) => !selectedHeadersName.includes(item));
    const exportData = () => {
        setMessage("Your download is about to start.");

        axios
            .post(
                `${API_URI}/partner/${partnerUid}/download_excel`,
                {
                    hiddenColumns,
                },
                {
                    responseType: "arraybuffer",
                }
            )
            .then((response) => {
                const blob = new Blob([response.data], {
                    type: "application/excel",
                });
                const link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                const date = new Date();
                link.download = `argidius-data-export-${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}.xlsx`;
                link.click();
                setMessage("Successfully Downloaded");
            });
    };

    const handleFileUploads = (file: any) => {
        setToggleModal(false);
        setMessage("File is Loading...");

        let formData = new FormData();
        formData.append("uploadFile", file);

        fetch(`${API_URI}/partner/${partnerUid}/upload_excel`, {
            body: formData,
            method: "POST",
        })
            .then(async (response) => {
                if (response.ok) {
                    let data = await response.json();
                    if (data.length === 0) {
                        emptySearch();
                        getGridData();
                        setMessage("Successfully Uploaded");
                        setUploadErrors(false);
                    } else {
                        setMissingColumns(data);
                        setUploadErrors(true);
                        throw new Error("File could not upload.Please check the description above.");
                    }
                } else {
                    if (response.status === 400) {
                        let data = await response.json();

                        setMissingColumns(data);
                        setUploadErrors(true);
                        throw new Error("File could not upload.Please check the description above.");
                    } else if (response.status === 403) {
                        throw new Error("Current reporting state does not allow excel uploads.");
                    } else {
                        throw new Error("File could not uploaded. Something went wrong");
                    }
                }
            })
            .catch((error) => {
                setMessage(error.message);
            });
    };

    return (
        <aside className="sideBarComponent">
            <div className="content">
                <div className="grow">
                    {user && user.role === 0 && (
                        <>
                            <Projects />
                        </>
                    )}
                    <div className="sideBarDetailsComponent">
                        <p>
                            Partner : <strong>{user && user.name}</strong>
                        </p>
                        {projectsId.length > 0 && <p className="projectId">Project ID: {projectsId.join(", ")}</p>}
                    </div>
                    {user && user.role === 1 && <ProjectsSelection />}
                    {
                        <Autocomplete
                            options={enterprises.filter((e) => e).map((e) => ({ enterprise: e, label: e.name }))}
                            onChange={(event, value) => {
                                setEnterpriseFilter(value ? value.enterprise.uid : null);
                            }}
                            isOptionEqualToValue={(option, value) => option.enterprise.uid === value.enterprise.uid}
                            renderInput={(params) => <TextField label="Search enterprise" {...params} />}
                            disabled={!enterprises || partnerUid == null}
                        />
                    }
                    <Button
                        sx={buttonStyle}
                        className="button-yellow-full upload"
                        variant="text"
                        onClick={() => setToggleModal(true)}
                        disabled={reportingYearClosed}
                    >
                        <CloudUploadIcon sx={iconStyle} className="white" />
                        Upload new data
                    </Button>
                </div>
                <div className="double-button">
                    <Button sx={buttonStyle} className="button-yellow-hollow" variant="text" disabled={partnerUid === null} onClick={() => exportData()}>
                        <CloudDownloadIcon sx={iconStyle} className="white" />
                        Download current data
                    </Button>
                </div>
            </div>
            {toggleModal && (
                <MuiStepper
                    handleFileUploads={handleFileUploads}
                    projectsId={projectsId}
                    exportData={exportData}
                    toggleModal={toggleModal}
                    setToggleModal={setToggleModal}
                />
            )}
        </aside>
    );
}

export default SideBar;
