import { Pagination, Stack, Autocomplete, TextField } from "@mui/material";


export type Props = {
    totalPages: number;
    page: number;
    setPage: (page: number) => void;
};

function createOption(i: number): { page: number, label: string } {
    return { page: i, label: i.toString() };
}

export const GridPagination = ({ totalPages, setPage, page }: Props) => {
    if (Math.ceil(totalPages) > 1) {
        const options = []
        for (let i = 0; i < totalPages; ++i) {
            options.push(createOption(i + 1));
        }
        return <Stack direction='row'>
            <Pagination
                size="small"
                sx={{ mt: 'auto', mb: 'auto' }}
                count={Math.ceil(totalPages)}
                page={page}

                onChange={(event: React.ChangeEvent<unknown>, value: number) => {
                    setPage(value);
                }} />
            <Autocomplete
                size="small"
                options={options}
                value={createOption(page)}
                isOptionEqualToValue={(option, value) => option.page === value.page}
                disableClearable
                autoHighlight
                renderInput={(params) => <TextField {...params} />}
                onChange={(event, value) => {
                    if (value != null && value.page <= totalPages && value.page > 0) {
                        setPage(value.page)
                    }
                }} />
        </Stack >
    }
    else {
        return <></>;
    }
}
