export type SubHeader = {
    id: string;
    header: string;
    hide: boolean;
    pid: string;
    info?: string;
};
export type Header = {
    header: string;
    hide: boolean;
    id: string;
    columns: SubHeader[];
    info?: string;
};

export type DataGridHeaders = {
    columns: Header[];
};

const yearInterval = {
    start: 2011,
    final: new Date().getFullYear() + 5,
};
const getYears: any = () => {
    const years = [];
    for (let year = yearInterval.start; year <= yearInterval.final; year++) {
        years.push(year);
    }
    return years;
};

const getYearsWithType: any = () => {
    const years = [];
    for (let year = yearInterval.start; year <= yearInterval.final; year++) {
        years.push(`Type${year}UID`);
        years.push(year);
    }
    return years;
};

export const createDataGridHeaders = (): DataGridHeaders => {
    return {
        columns: [
            {
                header: "Enterprise-Level Data",
                hide: false,
                id: "enterprise-level-data",
                columns: [
                    {
                        header: "Partner Project ID",
                        hide: false,
                        pid: "enterprise-level-data",
                        id: "projectUID",
                    },
                    {
                        header: "Client or Company Name",
                        hide: false,
                        pid: "enterprise-level-data",
                        id: "entOrgUID",
                        info: "Name of individual entrepreneur or enterprise.",
                    },
                    {
                        header: "Reporting status",
                        hide: true,
                        pid: "enterprise-level-data",
                        id: "reportingStatusUID",
                        info: "Active: enterprise operating and reportingUnknown: Enterprise not sharing performance information but assumed operational.Closed: Enterprise has ceased operations.Completed: Enterprise has completed reporting requirements (4 years of reporting data, including baseline).",
                    },
                    {
                        header: "Region",
                        hide: true,
                        pid: "enterprise-level-data",
                        id: "regionID",
                    },
                    {
                        header: "Country",
                        hide: true,
                        pid: "enterprise-level-data",
                        id: "countryUID",
                        info: "Country of operations or headquaters if present in multiple countries.",
                    },
                    {
                        header: "Sector",
                        hide: true,
                        pid: "enterprise-level-data",
                        id: "sectorUID",
                        info: "In what industry or sector does the enterprise operate.",
                    },
                    {
                        header: "Urban or Rural",
                        hide: true,
                        pid: "enterprise-level-data",
                        id: "areaUID",
                        info: "Enterprise based in an large city/urban area or a small town/rural area.",
                    },
                    {
                        header: "Existing Business or Idea",
                        hide: true,
                        pid: "enterprise-level-data",
                        id: "businessStatusUID",
                        info: "Did this business have sales before you started working with them? If Yes, select 'existing business'.",
                    },
                    {
                        header: "Gender",
                        hide: true,
                        pid: "enterprise-level-data",
                        id: "gender",
                    },
                    {
                        header: "Enterprise Start-up Year",
                        hide: true,
                        pid: "enterprise-level-data",
                        id: "enterpriseStartupYear",
                        info: "Year that the enterprise started generating revenue.",
                    },
                    {
                        header: "Date formalized (if applicable)",
                        hide: true,
                        pid: "enterprise-level-data",
                        id: "dateFormalized",
                        info: "Date enterprise was formally registerd, or leave blank if unregistered or unknown.",
                    },
                    {
                        header: "Social or Environmental Enterprise?",
                        hide: true,
                        pid: "enterprise-level-data",
                        id: "missionUID",
                        info: "Businesses with an explicit social or environmental mission.",
                    },
                    {
                        header: "Partner Service Line",
                        hide: true,
                        pid: "enterprise-level-data",
                        id: "partnerServiceLine",
                    },
                    {
                        header: "Cohort/ Intervention start date",
                        hide: true,
                        pid: "enterprise-level-data",
                        id: "interventionStartDate",
                    },
                    {
                        header: "Cohort/ Intervention end date",
                        hide: true,
                        pid: "enterprise-level-data",
                        id: "interventionEndDate",
                    },
                    {
                        header: "Baseline Year",
                        hide: false,
                        pid: "enterprise-level-data",
                        id: "baselineYear",
                    },
                ],
            },
            {
                id: "revenues",
                header: "Revenues of Enterprises Assisted",
                hide: false,
                columns: [
                    {
                        header: "Currency",
                        hide: false,
                        pid: 2,
                        id: "revenueCurrencyUID",
                    },
                    ...getYears().map((year: string | number, i: number) => ({
                        header: year,
                        hide: year !== 2023 && year !== 2022 ? true : false,
                        pid: "revenues",
                        id: "revenue" + year,
                    })),
                ],
                info: "Revenue for the baseline year (for start-ups, this should be 0) and for all relevant subsequent years.",
            },

            {
                id: "fulltime",
                header: "Enterprise Full-Time Employees",
                hide: false,
                columns: [
                    ...getYears().map((year: string | number, i: number) => ({
                        header: year,
                        hide: year !== 2023 && year !== 2022 ? true : false,
                        pid: "fulltime",
                        id: "fullTimeEmpl" + year,
                    })),
                ],
                info: "Full-time employees on payroll for the enterprise at the end of each year.",
            },

            {
                id: "parttime",
                header: "Enterprise Part-Time Employees",
                hide: false,
                columns: [
                    ...getYears().map((year: string | number, i: number) => ({
                        header: year,
                        hide: year !== 2023 && year !== 2022 ? true : false,
                        pid: "parttime",
                        id: "partTimeEmpl" + year,
                    })),
                ],
                info: "Part-time employees of the enterprise at the end of each year.",
            },

            {
                id: "finance",
                header: "Value of Finance Mobilized for Enterprise",
                hide: false,
                columns: [
                    {
                        header: "Currency",
                        hide: false,
                        pid: "finance",
                        id: "financeCurrencyUID",
                    },

                    ...getYearsWithType().map(
                        (year: string | number, i: number) => ({
                            header: year,
                            hide:
                                year !== 2023 &&
                                year !== 2022 &&
                                year !== "Type2023UID" &&
                                year !== "Type2022UID"
                                    ? true
                                    : false,
                            pid: "finance",
                            id: "finance" + year,
                        })
                    ),
                    {
                        header: "Seeking external finance?",
                        hide: true,
                        pid: "finance",
                        id: "seekingExternalFinance",
                    },
                ],
                info: "External financing was mobilized for the enterprise in each year. If multiple types of financing were agreed, please select Other.",
            },
            {
                id: "general",
                header: "General",
                hide: false,
                columns: [
                    {
                        header: "Comments/ Historical Changes",
                        hide: false,
                        pid: "general",
                        id: "comments",
                    },
                ],
            },
        ],
    };
};

export type Title = {
    name: string;
    show: boolean;
    hide: boolean;
    color: string;
    info?: string;
    size?: any;
};
export type Titles = Array<Title>;

export const titlesState: Titles = [
    {
        name: "Enterprise-Level Data",
        show: true,
        hide: false,
        color: "dark-green",
    },
    {
        name: "Revenues of Enterprises Assisted",
        show: true,
        hide: false,
        color: "dark-green",
        info: "Revenue for the baseline year (for start-ups, this should be 0) and for all relevant subsequent years. ",
    },
    {
        name: "Enterprise Full-Time Employees",
        show: true,
        hide: false,
        color: "dark-green",
        info: "Full-time employees on payroll for the enterprise at the end of each year.",
    },
    {
        name: "Enterprise Part-Time Employees",
        show: true,
        hide: false,
        color: "dark-green",
        info: "Part-time employees of the enterprise at the end of each year.",
    },
    {
        name: "Value of Finance Mobilized for Enterprise",
        show: true,
        hide: false,
        color: "dark-green",
        info: "External financing was mobilized for the enterprise in each year. If multiple types of financing were agreed, please select Other.",
    },
    { name: "General", color: "dark-green", show: true, hide: false },
];

export const subtitles = [
    {
        type: "project",
        id: "projectUID",
        name: "Partner Project ID",
        title: "Enterprise-Level Data",
        quality: true,
    },
    {
        type: "enterprise",
        id: "entOrgUID",
        name: "Client or Company Name",
        title: "Enterprise-Level Data",
        quality: true,
        info: "Name of individual entrepreneur or enterprise.",
    },
    {
        type: "list",
        id: "reportingStatusUID",
        select: "reportingstatus",
        name: "Reporting status",
        title: "Enterprise-Level Data",
        info: "Active: enterprise operating and reportingUnknown: Enterprise not sharing performance information but assumed operational.Closed: Enterprise has ceased operations.Completed: Enterprise has completed reporting requirements (4 years of reporting data, including baseline).",
        quality: true,
    },
    {
        type: "list",
        id: "regionID",
        select: "region",
        name: "Region",
        title: "Enterprise-Level Data",
        quality: true,
    },
    {
        type: "list",
        id: "countryUID",
        select: "country",
        name: "Country",
        title: "Enterprise-Level Data",
        quality: true,
        info: "Country of operations or headquaters if present in multiple countries.",
    },

    {
        type: "list",
        id: "sectorUID",
        select: "gics2",
        name: "Sector",
        title: "Enterprise-Level Data",
        quality: true,
        info: "In what industry or sector does the enterprise operate.",
    },
    {
        type: "list",
        id: "areaUID",
        select: "area",
        name: "Urban or Rural",
        title: "Enterprise-Level Data",
        quality: true,
        info: "Enterprise based in an large city/urban area or a small town/rural area.",
    },
    {
        type: "list",
        id: "businessStatusUID",
        select: "businessstatus",
        name: "Existing Business or Idea",
        title: "Enterprise-Level Data",
        quality: true,
        info: "Did this business have sales before you started working with them? If Yes, select 'existing business'.",
    },
    {
        type: "input",
        id: "gender",
        select: "gender",
        name: "Gender",
        title: "Enterprise-Level Data",

        quality: false,
    },
    {
        type: "number",
        id: "enterpriseStartupYear",
        name: "Enterprise Start-up Year",
        title: "Enterprise-Level Data",
        quality: true,
        info: "Year that the enterprise started generating revenue.",
    },
    {
        type: "date",
        id: "dateFormalized",
        name: "Date formalized (if applicable)",
        title: "Enterprise-Level Data",
        quality: false,
        info: "Date enterprise was formally registerd, or leave blank if unregistered or unknown.",
    },
    {
        type: "list",
        id: "missionUID",
        select: "mission",
        name: "Social or Environmental Enterprise?",
        title: "Enterprise-Level Data",
        quality: true,
        info: "Businesses with an explicit social or environmental mission.",
    },
    {
        type: "input",
        id: "partnerServiceLine",
        name: "Partner Service Line",
        title: "Enterprise-Level Data",

        quality: true,
        info: "Level of service received by enterprise with Argidius funds.",
    },
    {
        type: "date",
        id: "interventionStartDate",
        name: "Cohort/ Intervention start date",
        title: "Enterprise-Level Data",

        quality: true,
        info: "Date the enterprise started receiving services (DD/MM/YYYY)",
    },
    {
        type: "date",
        id: "interventionEndDate",
        name: "Cohort/ Intervention end date",
        title: "Enterprise-Level Data",

        quality: true,
        info: "Date the enterprise ceased receiving service (DD/MM/YYYY). If the business dropped out during initiative please indicate here with DNF.",
    },
    {
        type: "baseline",
        id: "baselineYear",
        name: "Baseline Year",
        isBase: true,
        title: "Enterprise-Level Data",
        info: "If intervention happens in the first 6 months of the year then enter baseline as last year; if intervention happens in the last 6 months of the year, then enter baseline of same year. This is based on Cohort/Intervention start-date column.",

        quality: true,
    },
    {
        type: "list",
        id: "revenueCurrencyUID",
        select: "currency",
        name: "Currency",
        title: "Revenues of Enterprises Assisted",

        quality: true,
    },
    {
        type: "years",
        id: "revenue",
        title: "Revenues of Enterprises Assisted",
        quality: true,
    },
    {
        type: "years",
        id: "fullTimeEmpl",
        title: "Enterprise Full-Time Employees",
        quality: true,
    },
    {
        type: "years",
        id: "partTimeEmpl",
        title: "Enterprise Part-Time Employees",
        quality: true,
    },
    {
        type: "list",
        id: "financeCurrencyUID",
        select: "currency",
        name: "Currency",
        title: "Value of Finance Mobilized for Enterprise",

        quality: false,
    },
    {
        type: "years-type",
        id: "finance",
        title: "Value of Finance Mobilized for Enterprise",

        quality: false,
    },
    {
        type: "listbool",
        id: "seekingExternalFinance",
        list: ["Yes", "No"],
        name: "Seeking external finance?",
        title: "Value of Finance Mobilized for Enterprise",

        quality: false,
        info: "Is the enterprise looking for external finance?",
    },
    {
        type: "input",
        id: "comments",
        name: "Comments/ Historical Changes",
        title: "General",
        quality: false,
    },
];
