import { Button, FormControl, FormLabel, Stack, Tooltip, Typography } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useContext, useEffect, useState } from "react";
import { createDataGridHeaders } from "./headers";
import { GridHeaderSelectionDialog } from "./GridHeaderSelectionDialog";
import { updateColumnVisibility } from "../../util/data-entry/dataEntryUtils";
import { convertVfmeDate } from "../../util/convertVfmeDate";
import { AppContext } from "../../context/AppContext";

export interface SelectedHeaders {
    [key: string]: boolean;
}

type Column = {
    header: string;
    hide: boolean;
    id: string;
    pid: string;
    info?: string;
};

export type ColumnsGroupType = {
    header: string;
    hide: boolean;
    id: string;
    columns: Column[];
    info?: string;
};

type Props = {
    selectedHeaders: SelectedHeaders;
    setSelectedHeaders: React.Dispatch<React.SetStateAction<SelectedHeaders>>;
};

export const GridHeaders = ({ selectedHeaders, setSelectedHeaders }: Props) => {
    const { gridData, setGridData } = useContext(AppContext);
    const [openHeaderDialog, setOpenHeaderDialog] = useState("");
    const [previousSelection, setPreviousSelection] = useState<Record<string, boolean>>();

    // Function to update the dataGridTable based on selectedHeaders
    const updateDataGridTable = (selectedHeaders: SelectedHeaders) => {
        const updatedDataGridTable = { ...gridData };

        // Use the function to update column visibility
        updateColumnVisibility(updatedDataGridTable, selectedHeaders);

        // Update the state with the new dataGridTable
        setGridData(updatedDataGridTable);
        setOpenHeaderDialog("");
        setSelectedHeaders(selectedHeaders);
    };

    useEffect(() => {
        const temporarySelectedHeaders = { ...selectedHeaders };

        gridData.columns.forEach((c) =>
            c.columns.forEach((b) => {
                if (b.hide !== true) {
                    temporarySelectedHeaders[b.id] = true;
                }
            })
        );

        setSelectedHeaders(temporarySelectedHeaders);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const setAllColumn = (selectAll: boolean) => {
        const defaultSelectedHeaders = createDataGridHeaders().columns.reduce((acc: { [key: string]: boolean }, group) => {
            group.columns.forEach((column) => {
                acc[column.id] = selectAll ? true : false; // Set all columns to true by default
            });
            return acc;
        }, {});

        updateDataGridTable(defaultSelectedHeaders);
    };

    const setDefault = () => {
        const temporarySelectedHeaders: SelectedHeaders = {};
        const updatedDataGridTable = createDataGridHeaders();
        updatedDataGridTable.columns.forEach((c) =>
            c.columns.forEach((b) => {
                if (b.hide !== true) {
                    temporarySelectedHeaders[b.id] = true;
                }
            })
        );
        updateDataGridTable(temporarySelectedHeaders);
    };

    const handleSelectColumns = (c: ColumnsGroupType, isChecked: boolean) => {
        const newSelectedHeaders = {
            ...selectedHeaders,
        };

        const checkSelected = (isChecked: boolean) => {
            c?.columns.forEach((sb: Column) => {
                newSelectedHeaders[sb.id] = isChecked;
            });
        };
        if (isChecked) {
            checkSelected(true);
        } else if (!isChecked) {
            c?.columns.forEach((sb: Column) => {
                if (newSelectedHeaders[sb.id]) {
                    delete newSelectedHeaders[sb.id];
                }
            });
        }
        setSelectedHeaders(newSelectedHeaders);
        const updatedDataGridTable = {
            ...gridData,
        };

        updateColumnVisibility(updatedDataGridTable, newSelectedHeaders);

        setGridData(updatedDataGridTable);
    };
    return (
        <Stack>
            {/* Checkbox selection controls */}
            <Stack direction="row" spacing={1} mt={1}>
                {gridData?.columns.map((c) => {
                    return (
                        <FormControl component="fieldset" variant="standard" key={c.id}>
                            <Tooltip title="Select columns you want to display.">
                                <FormLabel
                                    component="legend"
                                    onClick={() => {
                                        setOpenHeaderDialog(c.header);
                                        setPreviousSelection(selectedHeaders);
                                    }}
                                    sx={{
                                        cursor: "pointer",
                                        backgroundColor: "#52B998",
                                        p: 1,
                                        fontSize: 12,
                                        color: "#FFFFFF",
                                    }}
                                >
                                    {c.header.toLocaleUpperCase()}
                                </FormLabel>
                            </Tooltip>
                            {openHeaderDialog === c.header && (
                                <GridHeaderSelectionDialog
                                    {...{
                                        openHeaderDialog,
                                        column: c,
                                        handleSelectColumns,
                                        selectedHeaders,
                                        setSelectedHeaders,
                                        gridData,
                                        setGridData,
                                        updateDataGridTable,
                                        previousSelection,
                                    }}
                                />
                            )}
                        </FormControl>
                    );
                })}
                <Button
                    onClick={() => setAllColumn(true)}
                    variant="outlined"
                    size="small"
                    sx={{
                        borderRadius: "0px",
                        color: "#F0AE0B",
                        border: "solid 1px #F0AE0B",
                        "&:hover": {
                            border: "solid 1px #F0AE0B",
                        },
                    }}
                >
                    Select All
                </Button>
                <Button
                    onClick={setDefault}
                    variant="outlined"
                    size="small"
                    sx={{
                        borderRadius: "0px",
                        color: "#F0AE0B",
                        border: "solid 1px #F0AE0B",
                        "&:hover": {
                            border: "solid 1px #F0AE0B",
                        },
                    }}
                >
                    Set Default
                </Button>
            </Stack>

            {/* Render the data grid table */}
            <Stack direction="column" mt={3} boxSizing="border-box" position="relative">
                <Stack width="100%" direction="row">
                    {gridData?.columns.map((c: ColumnsGroupType, columnIndex: number) => {
                        return (
                            <Stack direction="column" key={columnIndex}>
                                {!c.hide && (
                                    <Stack
                                        sx={{
                                            backgroundColor: "#52B998",
                                        }}
                                        direction="row"
                                        justifyContent="center"
                                        spacing={1}
                                        alignItems="center"
                                        border="solid 1px #999999"
                                        height="55px"
                                    >
                                        <Typography
                                            fontSize="12px"
                                            color="white"
                                            textAlign="center"
                                            sx={{ cursor: "pointer" }}
                                            onClick={() => {
                                                setOpenHeaderDialog(c.header);
                                                setPreviousSelection(selectedHeaders);
                                            }}
                                        >
                                            {c.header}
                                        </Typography>
                                        {c.info && (
                                            <Tooltip title={c.info}>
                                                <InfoOutlinedIcon
                                                    sx={{
                                                        fontSize: "16px",
                                                        color: "white",
                                                        cursor: "pointer",
                                                    }}
                                                />
                                            </Tooltip>
                                        )}
                                    </Stack>
                                )}
                                <Stack direction="row" width="100%" alignItems="stretch">
                                    {c.columns.map((subHeader: Column) => {
                                        if (!subHeader.hide) {
                                            return (
                                                <Stack
                                                    key={subHeader.header}
                                                    sx={{
                                                        backgroundColor: "#C4E9DC",
                                                    }}
                                                    direction="row"
                                                    spacing={1}
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    border="solid 1px #999999"
                                                    width="180px"
                                                    height="50px"
                                                >
                                                    <Typography fontSize="12px">{convertVfmeDate(subHeader.header)}</Typography>
                                                    {subHeader.info && (
                                                        <Tooltip title={subHeader.info}>
                                                            <InfoOutlinedIcon
                                                                sx={{
                                                                    fontSize: "16px",
                                                                    cursor: "pointer",
                                                                }}
                                                            />
                                                        </Tooltip>
                                                    )}
                                                </Stack>
                                            );
                                        }
                                        return null; // Render nothing if c.hide is true
                                    })}
                                </Stack>
                            </Stack>
                        );
                    })}
                </Stack>
            </Stack>
        </Stack>
    );
};
