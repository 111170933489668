import { useContext, useEffect, useState } from "react";
import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";
import { AppContext } from "../../context/AppContext";
type Props = {
    editMode: boolean;
    title: string;
    id: number;
    row: number;
    cellValue: string;
    enterpriseId: number;
    name: number;
    isClosed: boolean;
    isCompleted: boolean;
    isInvalid: boolean;
    isValid: boolean;
    baselineYear: number;
    isOutScope: boolean;
    previousValue: number;
    typeError: boolean;
    hasSpace: boolean;
    isPurple?: boolean;
    historical?: boolean;
};

function computeCellStyle(
    props: Props,
    isWarning: boolean,
    conflict: boolean,
    isInvalidValue: boolean,
    isValidValue: boolean
) {
    let style = "";

    if (props.isPurple) {
        style = "purple";
    } else if (
        props.name < props.baselineYear ||
        props.isClosed ||
        props.isCompleted
    ) {
        style = "out-scope";
    } else if (isWarning || conflict) {
        style = "warning";
    } else if (isInvalidValue || props.hasSpace || props.typeError) {
        style = "invalid";
    } else if (isValidValue) {
        style = "valid";
    }

    return [style, "cell"];
}

function CellNumber(props: Props) {
    const { updateCell } = useContext(AppContext);

    const [inputValue, setInputValue] = useState<undefined | number>(undefined);
    const [conflict, setConflict] = useState(false);
    const [isValidValue, setIsValidValue] = useState(false);
    const [isInvalidValue, setIsInvalidValue] = useState(false);
    const [isWarning, setIsWarning] = useState(false);

    const style = computeCellStyle(
        props,
        isWarning,
        conflict,
        isInvalidValue,
        isValidValue
    ).join(" ");

    const confirmWarning = () => {
        setIsWarning(false);
        setIsValidValue(true);
    };

    const confirmConflict = () => {
        setConflict(false);
        setIsValidValue(true);
    };
    const validate = (e: any) => {
        if (e.keyCode === 13) e.target.blur();
        if (e) setInputValue(e.target.value);
        setIsWarning(false);
        //@ts-ignore
        if (timeout)
            //@ts-ignore
            clearTimeout(timeout);
        if (
            props.name === new Date().getFullYear() - 1 &&
            props.previousValue > 0 &&
            inputValue &&
            inputValue > props.previousValue * 2
        )
            setIsWarning(true);
        if (inputValue) {
            //@ts-ignore
            if (parseFloat(inputValue) < 1) setIsInvalidValue(true);
        }
        if (!isWarning) {
            setIsValidValue(true);
            setIsInvalidValue(false);
        }
        setTimeout(() => {
            updateCell({ row: props.row, id: props.id, value: inputValue });
        }, 500);
    };

    const goBackHistory = (e: any) => {
        setConflict(false);
        setIsValidValue(true);
        // setInputValue(props.historical);
        validate(e);
    };

    const removeSpace = () => {
        // I think this logic is wrong inputValue should be number

        const trimmedInput = inputValue
            ? //@ts-ignore
              inputValue.replace(/ /g, "")
            : undefined;
        setInputValue(trimmedInput);
        props.typeError = false;
        updateCell({ row: props.row, id: props.id, value: inputValue });
    };

    useEffect(() => {
        if (props.cellValue === null) {
            setInputValue(undefined);
        } else {
            //@ts-ignore
            setInputValue(props.cellValue);
        }
        if (inputValue) {
            if (
                inputValue > 0 &&
                props.name === new Date().getFullYear() - 1 &&
                props.previousValue > 0 &&
                inputValue > props.previousValue * 2
            ) {
                setIsWarning(true);
            }
        }
        setIsValidValue(props.isValid);
        setIsInvalidValue(props.isInvalid);
    }, [
        inputValue,
        props.cellValue,
        props.isInvalid,
        props.isValid,
        props.name,
        props.previousValue,
    ]);

    return (
        <div className={style}>
            <input
                type="text"
                disabled={props.isClosed || !props.editMode}
                value={inputValue}
            />

            {isWarning && (
                <span>
                    <button onClick={confirmWarning}>
                        <DoneIcon />
                    </button>
                </span>
            )}
            {conflict && (
                <span>
                    <button onClick={confirmConflict}>
                        <DoneIcon />
                    </button>
                    <button className="clear" onClick={goBackHistory}>
                        <ClearIcon />
                    </button>
                </span>
            )}
            {props.hasSpace && (
                <span>
                    <button onClick={removeSpace}>
                        <DoneIcon />
                    </button>
                </span>
            )}
            {props.isClosed && (
                <span className="tooltip" md-direction="top">
                    <strong>Enterprise is closed</strong>
                    <br />
                    No data required.
                </span>
            )}
            {isInvalidValue && !props.isOutScope && !props.isClosed && (
                <span className="tooltip" md-direction="top">
                    <strong>Cell empty</strong>
                    <br />
                    Data is missing
                    <br />
                    <i>Improvement: fill in the blanks</i>
                </span>
            )}
            {isWarning && (
                <span className="tooltip" md-direction="left">
                    <strong>Potential outlier detected</strong>
                    <br />
                    The value entered is potentially incorrect, as the increase
                    is relatively high compared to previous years.
                    <br />
                    <i>Improvement: Double check the figure</i>
                </span>
            )}
            {props.isOutScope && (
                <span className="tooltip" md-direction="left">
                    Current year is before baseline year
                </span>
            )}
            {props.hasSpace && (
                <span className="tooltip" md-direction="top">
                    <span>
                        <strong>Typing error</strong>
                        <br />
                        The data provided contains a space.
                        <br />
                        <i>Improvement: Check cell to remove space</i>
                    </span>
                </span>
            )}
            {props.typeError && (
                <span className="tooltip" md-direction="top">
                    <span>
                        <strong>Data type not correct</strong>
                        <br />
                        The value entered does not have the correct data type.
                        Please insert only numbers (i.e. 1000000)
                        <br />
                        <i>
                            Improvement: change the data type to the right
                            format
                        </i>
                    </span>
                </span>
            )}
        </div>
    );
}

export default CellNumber;
