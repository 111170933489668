import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react'
import { AppContext } from '../../context/AppContext';
import { API_URI } from '../../util/urls';

function ProjectsSelection() {
    const { setSelectedProject, setSelects, partnerUid } = useContext(AppContext);

    const [projectsFull, setProjectsFull] = useState([]);
    const [projectsId, setProjectsId] = useState('all');

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setProjectsId((event.target as HTMLInputElement).value);
    };

    useEffect(() => {

        fetch(`${API_URI}/partner/${partnerUid}/project/all`)
            .then(async response => {
                const data = await response.json();
                setProjectsFull(data)
            });
        fetch(`${API_URI}/partner/${partnerUid}/enterprises/all`)
            .then(async response => {
                const data = await response.json();
                setSelects([{ data: data, "name": 'enterprises' }])
            });
    }, [partnerUid, setSelects]);

    useEffect(() => {
        setSelectedProject(projectsId)
    }, [projectsId, setSelectedProject])
    return (
        <div>
            <FormControl>
                <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={projectsId}
                    onChange={handleChange}
                >
                    <FormControlLabel value="all" control={<Radio size="small" />} label="All projects" />
                    {/*  @ts-ignore*/}
                    {projectsFull.map((item, i) => <FormControlLabel key={i} value={item.uid} control={<Radio size="small" />} label={item.id} />)}
                </RadioGroup>
            </FormControl>
        </div>
    )
}

export default ProjectsSelection
