import { Box } from "@mui/system";
import { Link, useRouteError } from "react-router-dom";
import "./ErrorPage.scss"

export default function ErrorPage() {
    const error: any = useRouteError();
    console.error(error);

    return (
        <>
            <Box className="text">
                <Box sx={{ lineHeight: "1rem", display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <h1>404</h1>
                    <h2>Uh, Ohh</h2>
                </Box>
                <h3>Sorry we cant find what you are looking for <i>{error.statusText || error.message}</i></h3>
                <Link to="/data-entry">Go to Home Page</Link>
            </Box>
        </>
    );
}