import { Box, Button, TextField } from '@mui/material';
import axios from 'axios';
import { useState } from 'react'
import { API_URI } from '../../util/urls';

type Form = {
    title: string,
    text: string,
    name: string,
    email: string,
}
const emptyFormState = {
    title: '',
    text: '',
    name: '',
    email: '',
}

function Bug() {
    const [form, setForm] = useState<Form>(emptyFormState)
    const [message, setMessage] = useState('')
    const formInValid = form.email.length === 0 || form.name.length === 0 || form.text.length === 0 || form.title.length === 0;
    const handleFormSubmit = () => {
        if (!formInValid) {
            axios
                .post(`${API_URI}feedbacks/create/`, form)
                .then(() => {
                    setMessage("Thanks for submiting your feedback!");
                    setForm({
                        title: '',
                        text: '',
                        name: '',
                        email: '',
                    })
                })
                .catch(() => {
                    setMessage("Something went wrong creating the feedback");
                });
        } else setMessage('Please fill all inputs')
    }

    return <>
        <Box component="form" onSubmit={(e) => {
            e.preventDefault();
        }} >
            <TextField
                label="Topic"
                id="topic"
                sx={{ m: 1, width: '100%' }}
                name="title"
                variant="standard"
                type="text"
                value={form.title}
                onChange={(e) => setForm({ ...form, title: e.target.value })}
            />
            <TextField
                label="Description of the problem"
                id="text"
                sx={{ m: 1, width: '100%' }}
                name="text"
                variant="standard"
                type="text"
                value={form.text}
                onChange={(e) => setForm({ ...form, text: e.target.value })}
            />
            <TextField
                label="Name"
                id="name"
                sx={{ m: 1, width: '100%' }}
                name="name"
                variant="standard"
                type="text"
                value={form.name}
                onChange={(e) => setForm({ ...form, name: e.target.value })}
            />
            <TextField
                label="E-mail"
                id="email"
                sx={{ m: 1, width: '100%' }}
                name="email"
                variant="standard"
                type="email"
                value={form.email}
                onChange={(e) => setForm({ ...form, email: e.target.value })}
            />
            {message.length > 1 ? <span style={{ color: 'red' }}>{message}</span> : null}
            <Button
                onClick={handleFormSubmit}
                type="submit"
                fullWidth
                variant="contained"
                sx={{ backgroundColor: '#1D5D63', '&:hover': { backgroundColor: '#1d5d63c9' } }}>
                Submit
            </Button>
        </Box>
    </>
}

export default Bug
