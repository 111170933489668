import ResponsiveDrawer from '../widgets/Drawer';
import './Admin.scss';

function Admin() {
    return (
        <main id="admin">
            <ResponsiveDrawer />
        </main>
    )
}

export default Admin
