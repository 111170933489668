import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Box, Button, FormControl, IconButton, Input, InputAdornment, InputLabel } from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { API_URI } from '../../util/urls';

interface State {
    password: string;
    password2: string;
    passwordlength: number,
    password2length: number,
    showPassword: boolean;
}

interface PasswordGroup {
    password: string,
    password2: string
}

function CreatePasswordForm() {
    const minLength = 8;
    let { hash } = useParams();
    const navigate = useNavigate();
    const [values, setValues] = useState<State>({
        password: '',
        passwordlength: 0,
        password2: '',
        password2length: 0,
        showPassword: false,
    });

    const [form, setForm] = useState<PasswordGroup>({ password: '', password2: '' });
    const [message, setMessage] = useState('');
    const [success, setSuccess] = useState(false);

    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    }

    const createPassword = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (values.password === values.password2) {
            setMessage('');
            setSuccess(false);
            axios
                .post(`${API_URI}/users/resetPassword`, {
                    code: hash,
                    newPassword: values.password,
                })
                .then(response => {
                    setValues({ ...values, password: '', password2: '' })
                    if (response.data.result) {
                        setSuccess(true);
                        setMessage("Password Successfully Updated")
                        navigate("/")
                    } else {
                        setMessage(response.data.error);
                    }
                })
                .catch(async e => {
                    setValues({ ...values, password: '', password2: '' })
                    let error = e.response.data.error
                    if (!error)
                        error = e.response.data.errorMessage
                    setMessage(error);
                });
        }
    }

    return <>
        <div className="loginComponent">
            <h2 className='title'>Enterprise-Level Data Portal</h2>
            <p className='subTitle'>Welcome! You can create your new password using the form below: </p>
            <Box component="form" onSubmit={createPassword} sx={{ marginTop: '-1rem' }} >
                <FormControl sx={{ margin: '1rem 0', width: '100%' }} variant="standard">
                    <InputLabel htmlFor="password">Password *</InputLabel>
                    <Input
                        id="password"
                        type={values.showPassword ? 'text' : 'password'}
                        value={values.password}
                        name="password"
                        onChange={(e) => { setForm({ ...form, password: e.target.value }); setValues({ ...values, 'password': e.target.value }); }}
                        required
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                >
                                    {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                    {values.password.length > 0 && values.password.length < minLength && <span className='md-error'>The password needs to be at least 8 characters</span>}
                </FormControl>
                <FormControl sx={{ margin: '1rem 0', width: '100%' }} variant="standard">
                    <InputLabel htmlFor="confirmpassword">Confirm Password *</InputLabel>
                    <Input
                        id="conformpassword"
                        type={values.showPassword ? 'text' : 'password'}
                        value={values.password2}
                        name="password2"
                        onChange={(e) => { setForm({ ...form, password2: e.target.value }); setValues({ ...values, 'password2': e.target.value }); }}
                        required
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                >
                                    {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                    {values.password !== values.password2 && <span className='md-error'>The password should be equal</span>}
                </FormControl>
                {success && <span className='md-error'>Your password has been updated, you can login now </span>}
                {message && <p className='notificationBox'>{message}</p>}
                <Button sx={{ backgroundColor: '#1D5D63', '&:hover': { backgroundColor: '#1d5d63c9' } }} type="submit" fullWidth variant="contained">
                    Create Password
                </Button>
            </Box>
            <hr />
            <Button variant="text" onClick={() => navigate("/")}>Login</Button>
        </div>
    </>
}

export default CreatePasswordForm
