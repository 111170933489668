import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { Checkbox, Modal } from "@mui/material";

type Props = {
    exportData: (a: any) => void,
    handleFileUploads: (a: any) => void,
    projectsId: Array<any>,
    setToggleModal: (a: boolean) => void,
    toggleModal: boolean,
}

export default function MuiStepper(props: Props) {
    const [activeStep, setActiveStep] = React.useState(0);
    const [checked, setChecked] = React.useState(false);
    const [skipped, setSkipped] = React.useState(new Set<number>());

    const isStepSkipped = (step: number) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
    };

    const StepOne = (props: any) => {
        return <Box>
            <div>
                <strong>Download the current data</strong>
                <br />
                Click on the button “Download Current Data” to download the latest
                data saved in the system.
                <br />
                If you have the latest data, plese click on “Next” to continue to the
                next step.
            </div>
            <Button
                className="button-yellow-full upload"
                variant="text"
                onClick={() => props.exportData()}
            >
                <CloudDownloadIcon sx={{ mr: 1 }} className="white" />
                Download current data
            </Button>
        </Box>



    };

    const StepTwo = (props: any) => {
        return (
            <Box>
                <b> Please check your excel file if it has the projects mentioned below:  </b>
                <p>Make sure you are using the latest template provided to avoid errors on upload.</p>
                <p> Click on "Confirm" if you have the projects mentioned below in your excel file: </p>
                <strong>{props.projectsId.join(', ')}</strong>
                <p>Click "Next" to continue to the next step.</p>
                <Box>
                    <Checkbox checked={checked} onChange={handleChange} />
                    <span>Confirm</span>
                </Box>
            </Box>
        );
    };
    const StepThree = (props: any) => {
        const handleUpload = (e: any) => {
            props.handleFileUploads(e.target.files[0])
        }
        return (
            <Box>
                <p>
                    <strong>You are about to upload a file</strong>
                    <br />
                    If you have your template ready, make sure you have the following
                    points covered:
                    <br />
                    1 - You have filled in the correct Partner Project ID for all
                    enterprises
                    <br />
                    2 - You have the right column headers (Pro tip: You can check if you
                    have the right column headers by downloading the latest version of the
                    template from the portal)
                    <br />3 - You have your data formatted as values (i.e. not as text
                    strings)
                </p>
                <p>Click the “Next” button to select a file for the upload.</p>
                <p>
                    This popup will close and a new windows will allow you to select a
                    file to upload.
                </p>
                <Box className="double-button">
                    <Button
                        className="button-yellow-hollow"
                        variant="contained"
                        component="label"
                    >
                        <CloudUploadIcon className="white" />
                        Select file
                        <input hidden accept="file" multiple type="file" onChange={handleUpload} />
                    </Button>
                </Box>
            </Box>
        );
    };
    const steps = [
        { step: "Step 1", content: <StepOne exportData={props.exportData} /> },
        { step: "Step 2", content: <StepTwo projectsId={props.projectsId} /> },
        { step: "Step 3", content: <StepThree handleFileUploads={props.handleFileUploads} /> }
    ];
    return (
        <Modal
            open={props.toggleModal}
            onClose={() => props.setToggleModal(false)}
            aria-labelledby="uploadModal"
            aria-describedby="modal-modal-description">
            <Box sx={{ width: "30%", margin: "auto", marginTop: "7%", backgroundColor: "white", padding: "1rem" }} id="uploadModal">
                <h1>Upload Data</h1>
                <p> Please follow the steps shown below: </p>
                <Stepper activeStep={activeStep}>
                    {steps.map((label) => {
                        const stepProps: { completed?: boolean } = {};

                        return (
                            <Step key={label.step} {...stepProps}>
                                <StepLabel>{label.step}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
                {activeStep === steps.length ? (
                    <React.Fragment>
                        <p >
                            All steps completed - you are finished
                        </p>
                        <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                            <Box sx={{ flex: "1 1 auto" }} />
                            <Button onClick={handleReset}>Reset</Button>
                        </Box>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <Box >
                            {steps[activeStep].content}
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                            <Button
                                color="inherit"
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                sx={{ mr: 1 }}
                            >
                                Back
                            </Button>
                            <Box sx={{ flex: "1 1 auto" }} />

                            <Button sx={[
                                {
                                    '&:hover': {
                                        color: 'white',
                                        backgroundColor: '#EFAF0A',
                                    },
                                }, { backgroundColor: '#EFAF0A', color: "white", }]}
                                onClick={handleNext}
                                disabled={activeStep === 1 && !checked}
                            >
                                {activeStep === steps.length - 1 ? "Finish" : "Next"}
                            </Button>
                        </Box>
                    </React.Fragment>
                )}
            </Box>
        </Modal>
    );
}
