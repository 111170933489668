import { FormControl, Typography } from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react'
import { AppContext } from '../../context/AppContext';
import { API_URI } from '../../util/urls';
import './Projects.scss';
import type { Organization } from '../../context/AppContext';
import { PartnerSearch } from '../../widgets/PartnerSearch';
import { Box } from '@mui/system';

function Projects() {
    const { selectedOrganizationsAdmin, user, setSelectedOrganizationsAdmin } = useContext(AppContext);

    const [listOrganizations, setListOrganizations] = useState<Array<Organization>>();
    const [showSearch, setShowSearch] = useState(false);
    const [filteredPartnerLists, setFilteredPartnerLists] = useState<Array<Organization> | undefined>([])
    const [, setPage] = useState(0)

    const getData = useCallback(() => {
        if (user && user.role === 0)
            fetch(`${API_URI}/partner/all`)
                .then(async response => {
                    const data = await response.json();
                    setListOrganizations(data);
                });
    }, [user])

    useEffect(() => {
        getData()
    }, [getData])

    useEffect(() => {
        setFilteredPartnerLists(listOrganizations)
    }, [listOrganizations])

    return <div>
        <FormControl variant="standard" sx={{ m: 1, width: "240px" }}>
            <Box onFocus={() => setShowSearch(true)}>
                <PartnerSearch partnerList={listOrganizations} setFilteredPartnerLists={setFilteredPartnerLists} setPage={setPage} />
            </Box>
            <p>{selectedOrganizationsAdmin.name}</p>
            <Box sx={{ maxHeight: "200px", overflow: "auto" }}>
                {filteredPartnerLists && showSearch && filteredPartnerLists.map((o: any, i: number) => <Typography
                    sx={{
                        cursor: "pointer",
                        p: 1,
                        '&:hover': {
                            backgroundColor: '#C4E9DC',
                        }
                    }}
                    variant="body1"
                    gutterBottom key={i}
                    onClick={() => { setSelectedOrganizationsAdmin(o); setShowSearch(false) }}>{o.name}</Typography>)}
            </Box>
        </FormControl>
    </div>
}

export default Projects
