import {
    Alert,
    Box,
    Button,
    Card,
    CardContent,
    Modal,
    Stack,
    Typography,
} from "@mui/material";
import { useCallback, useContext, useEffect, useState } from "react";
import Bar from "../components/common/Bar";
import Grid, { SubHeader } from "../components/data-entry/Grid";
import ModalDataEntry from "../components/data-entry/Modal";
import SideBar from "../components/data-entry/SideBar";
import Utils from "../components/data-entry/Utils";
import Feedback from "../components/feedback/Feedback";
import { AppContext } from "../context/AppContext";
import { ReportingYearClosedMessage } from "../util/messages";
import { API_URI } from "../util/urls";
import { isReportingYearClosed } from "../util/checks";
import "./DataEntry.scss";
import {
    Title,
    Titles,
    subtitles,
    titlesState,
} from "../components/data-entry/headers";

const currentYear = new Date().getFullYear();

const yearInterval = {
    start: 2011,
    final: currentYear + 6,
};

const typeList = ["Debt", "Equity", "Other"];
export const modalStyle = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

function DataEntry() {
    const {
        user,
        selectedOrganizationsAdmin,
        page,
        selectedProject,
        setTotalPages,
        setGrid,
        setEnterpriseReports,
        setSelects,
        partnerUid,
    } = useContext(AppContext);

    const [uploadErrors, setUploadErrors] = useState(false);
    const [done, setDone] = useState(true);
    const [subHeaders, setSubHeaders] = useState<SubHeader[]>([]);
    const [enableGridTitles, setEnableGridTitles] = useState<any>([]);
    const [message, setMessage] = useState("");
    const [missingColumns, setMissingColumns] = useState([]);
    const [reportingYearClosed, setReportingYearClosed] = useState(true);
    const [enterpriseFilter, setEnterpriseFilter] = useState<null | number>(
        null
    );
    const [titles, setTitles] = useState<Titles>();

    const [modal, setModal] = useState({ open: false, title: "", text: "" });
    const handleModalClose = useCallback(
        () => setModal({ open: false, title: "", text: "" }),
        []
    );

    const style = [
        "grid",
        !done || (user && user.role === 0 && !selectedOrganizationsAdmin.uid)
            ? "center"
            : "",
    ].join(" ");
    const showGrid =
        done &&
        ((user && user.role === 1) ||
            (user &&
                user.role === 0 &&
                selectedOrganizationsAdmin.uid &&
                selectedOrganizationsAdmin.uid > 0));

    useEffect(() => {
        setTitles(titlesState);
    }, []);

    useEffect(() => {
        let tempSubHeader: SubHeader[] = [];
        subtitles.forEach((item) => {
            if (item.type === "years") {
                for (let i = yearInterval.start; i < yearInterval.final; i++) {
                    tempSubHeader.push({
                        type: "number",
                        id: `${item.id}${i}`,
                        quality: item.quality,
                        name: i,
                        title: item.title,
                    });
                }
            }
            if (item.type === "years-type") {
                for (let i = yearInterval.start; i < yearInterval.final; i++) {
                    tempSubHeader.push({
                        type: "number",
                        id: `finance${i}`,
                        quality: item.quality,

                        name: i,
                        title: item.title,
                    });
                    tempSubHeader.push({
                        type: "list",
                        id: `financeType${i}UID`,
                        select: "vfmetype",
                        quality: item.quality,

                        list: typeList,
                        name: `${i} Type`,
                        title: item.title,
                    });
                }
            }
            if (["years", "years-type"].indexOf(item.type) < 0) {
                tempSubHeader.push({
                    type: item.type,
                    id: item.id,
                    select: item.select,
                    quality: item.quality,
                    list: item.list,
                    name: item.name,
                    info: item.info,
                    title: item.title,
                });
            }
        });
        let newTitles = titles?.map((item: Title) => {
            return (item.size =
                tempSubHeader.filter(
                    (subheader: any) =>
                        subheader.title === item.name && !subheader.hide
                ).length * 180);
        });
        if (newTitles) {
            setEnableGridTitles(titles);
        }
        setSubHeaders(tempSubHeader);
    }, [titles]);

    const getGridData = useCallback(() => {
        if (!user) return;

        setDone(false);

        const pagesAmount = 15;

        let urlAll =
            partnerUid !== -1 &&
            partnerUid !== undefined &&
            `${API_URI}/partner/${partnerUid}/enterprise_report/all/size`;
        let urlPaginated =
            partnerUid !== -1 &&
            partnerUid !== undefined &&
            `${API_URI}/partner/${partnerUid}/enterprise_report/all?pageNumber=${page}&pageSize=${pagesAmount}`;

        if (selectedProject !== "all") {
            urlAll = `${API_URI}/project/${selectedProject}/enterprise_report/all/size`;
            urlPaginated = `${API_URI}/project/${selectedProject}/enterprise_report/all?pageNumber=${page}&pageSize=${pagesAmount}`;
        }

        if (enterpriseFilter !== null) {
            const query = `enterpriseFilter=${enterpriseFilter}`;
            urlAll = urlAll + "?" + query;
            urlPaginated = urlPaginated + "&" + query;
        }

        urlAll &&
            fetch(urlAll).then(async (response) => {
                const data = await response.json();
                setTotalPages(data.size / pagesAmount);
            });

        urlPaginated &&
            fetch(urlPaginated).then(async (response) => {
                const data = await response.json();
                setGrid(data);
                setEnterpriseReports(data);
                setDone(true);
            });
    }, [
        user,
        page,
        selectedProject,
        setTotalPages,
        setGrid,
        setEnterpriseReports,
        partnerUid,
        enterpriseFilter,
    ]);

    const getSelectOptions = useCallback(() => {
        const selectsGroup = [
            "currency",
            "area",
            "businessstatus",
            "country",
            "vfmetype",
            "mission",
            "region",
            "reportingstatus",
            "gics1",
            "gics2",
        ];

        Promise.all(
            selectsGroup.map((select) =>
                fetch(`${API_URI}/masterdata/${select}/all`).then(
                    async (response) => {
                        const data = await response.json();
                        return { data: data, name: select };
                    }
                )
            )
        ).then((selects) => {
            setSelects(selects);
        });
    }, [setSelects]);

    useEffect(() => {
        getGridData();
        getSelectOptions();
    }, [getGridData, getSelectOptions]);

    useEffect(() => {
        const reportingYearClosed = isReportingYearClosed(user);
        setReportingYearClosed(reportingYearClosed);
    }, [user]);

    const clearMessage = useCallback(() => setMessage(""), []);

    if (!user) {
        return <div>Please log in</div>;
    } else {
        return (
            <main>
                <Bar />
                <SideBar
                    setMessage={setMessage}
                    setMissingColumns={setMissingColumns}
                    getGridData={getGridData}
                    setUploadErrors={setUploadErrors}
                    reportingYearClosed={reportingYearClosed}
                    setEnterpriseFilter={setEnterpriseFilter}
                />
                <article>
                    {reportingYearClosed && (
                        <Alert severity="warning">
                            <ReportingYearClosedMessage />
                        </Alert>
                    )}
                    {!uploadErrors && (
                        <div className={style}>
                            {showGrid && (
                                <Grid
                                    titles={enableGridTitles}
                                    subHeaders={subHeaders}
                                    getGridData={getGridData}
                                    setMessage={setMessage}
                                />
                            )}
                            {!done && user.role === 1 && (
                                <div className="text-main">
                                    <h2 className="md-title">
                                        Hi {user.name}, welcome!
                                    </h2>
                                    <p>
                                        Please wait, your data is being loaded.
                                    </p>
                                    <div className="loading">
                                        <div className="holder">
                                            <div className="dot-flashing"></div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {!done && user.role === 0 && (
                                <div className="text-main">
                                    <h2 className="md-title">
                                        Hi {user.name}, welcome!
                                    </h2>
                                    <p>
                                        You can start selecting a project on the
                                        left side
                                    </p>
                                </div>
                            )}
                        </div>
                    )}
                    {uploadErrors && (
                        <div>
                            <div className="center-card-error">
                                <Card>
                                    <div className="md-title">
                                        Data quality improvement steps
                                    </div>
                                    <CardContent className="overflow">
                                        <div className="md-body-2">
                                            We have found the following data
                                            quality improvements in the Excel
                                            sheet you uploaded. Please make the
                                            following changes to the Excel
                                            sheet. Then click “Upload new data”
                                            again to upload an updated version:
                                        </div>
                                        {missingColumns.map((m: any) => {
                                            return (
                                                <div
                                                    className="md-body-1"
                                                    key="index"
                                                >
                                                    {" "}
                                                    &bull; At cell
                                                    <span className="errorColor">
                                                        {" "}
                                                        {m.colString}
                                                        {m.rowNumber}
                                                    </span>{" "}
                                                    value {m.cellValue} with the
                                                    error: {m.errorMessage}
                                                </div>
                                            );
                                        })}
                                    </CardContent>
                                    <Button
                                        onClick={() => setUploadErrors(false)}
                                        variant="text"
                                    >
                                        Dismiss
                                    </Button>
                                </Card>
                            </div>
                        </div>
                    )}
                    <Utils />
                    {message.length > 1 && (
                        <Stack
                            sx={{
                                width: "50%",
                                position: "absolute",
                                bottom: "150px",
                                left: "600px",
                            }}
                            spacing={2}
                        >
                            <Alert
                                severity={
                                    message.includes("Successfully")
                                        ? "success"
                                        : message.includes("not allow")
                                        ? "error"
                                        : "info"
                                }
                                onClose={clearMessage}
                            >
                                {message}
                            </Alert>
                        </Stack>
                    )}
                </article>
                <Modal
                    open={modal.open}
                    onClose={handleModalClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={modalStyle}>
                        <Typography
                            id="modal-modal-title"
                            variant="h6"
                            component="h2"
                        >
                            {modal.title}
                        </Typography>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            {modal.text}
                        </Typography>
                    </Box>
                </Modal>
                <Feedback />
                <ModalDataEntry />
            </main>
        );
    }
}

export default DataEntry;
