import { Alert, Breadcrumbs, Button, FormControl, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import { useContext, useState } from 'react'
import { Link } from 'react-router-dom';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import AddIcon from '@mui/icons-material/Add';
import AlertDialog from '../../../widgets/AlertDialog';
import { API_URI } from '../../../util/urls';
import { AppContext } from '../../../context/AppContext';

export type Year = { "year": number, "id": number, "active": boolean, "reportingState": string };

function ReportingYear() {
    const { getReportingYearsList, reportingYearsList } = useContext(AppContext);
    const [isAlertOpen, setIsAlertOpen] = useState(false);
    const [notification, setNotification] = useState(false);
    const [showStatus, setShowStatus] = useState(false);
    const [status, setStatus] = useState('');
    const [statusArrows, setStatusArrows] = useState({ prev: true, next: true })
    const [message, setMessage] = useState("");

    const style = {
        color: 'black',
        fontSize: '14px',
        textDecoration: 'none'
    };

    const modalTitle = "Are You Sure to Create New Year";
    const modalContent = "You will create a new year. It means it will close previously current year and you can not change anything anymore. New year will be created one of copy of previous year.";

    const columns = ["Years", "Status", "Reporting State"];

    const reportingYearStatuses = [
        "NotStarted",
        "Open",
        "Closed",
        "Locked"];

    const getPreviousAndNextYearState = (reportingState: string, step: "prev" | "next") => {
        return step === "prev" ? reportingYearStatuses[reportingYearStatuses.indexOf(reportingState) - 1] : reportingYearStatuses[reportingYearStatuses.indexOf(reportingState) + 1];
    }

    const handleSaveStatus = (status: string, reportingYearId: number) => {
        fetch(`${API_URI}/admin/reportingYear/${reportingYearId}/update`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ "reportingState": status }),
        })
            .then((response) => {
                if (response.status === 409) {
                    throw new Error("Before lock to reporting year all purple cells should be fixed")
                }
                else if (!response.ok) {
                    throw new Error("Something went wrong !")
                } else if (response.ok) {
                    setMessage("Successfully updated !")
                    getReportingYearsList()
                    setStatusArrows({ prev: true, next: true })
                    response.json()
                }
            })
            .catch((error) => {
                setMessage(error.message);
            })
        setShowStatus(false)
    }

    const getActiveReportingYearState = (reportingYearsList: Array<Year>) => {
        return reportingYearsList.find(y => y.active === true)?.reportingState
    }

    const addNewYear = () => {
        fetch(`${API_URI}/admin/reportingYear/add`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(async (response) => {
                if (response.ok) {
                    setMessage("New Reporting Year Successfully Created")
                    getReportingYearsList()
                }
                else {
                    const errorMessage = await response.text()
                    setMessage(errorMessage)
                }
            })

        setIsAlertOpen(false)
    }

    return <main id='muiItemsContainer'>
        <Breadcrumbs aria-label="breadcrumb">
            <Link style={style} to="/data-entry">
                Home
            </Link>
            <Link style={style} to="/admin/reporting-year">
                Reporting Year
            </Link>
        </Breadcrumbs>
        <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", mt: 1 }} onClick={() => {
            const reportingYearState = getActiveReportingYearState(reportingYearsList);

            (reportingYearState === "Locked") || (reportingYearState === "Closed") ? setIsAlertOpen(true) : setNotification(true);
        }}>
            Start New Reporting Year <AddIcon sx={{ color: 'green', fontSize: '40px', cursor: "pointer" }} />
        </Box>
        <Box sx={{ mr: 3 }}>
            <TableContainer component={Paper}>
                <Table
                    sx={{ minWidth: 650, textAlign: 'left' }}
                    size="small"
                    aria-label="a dense table">
                    <TableHead>
                        <TableRow sx={{ backgroundColor: "#A4BEC0" }}>
                            {columns.map((c, i) =>
                                <TableCell
                                    key={i}
                                    sx={{ fontWeight: "600", color: "white" }}>
                                    {c}
                                </TableCell>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {reportingYearsList.map((row: Year) => (
                            <TableRow
                                key={row.id}
                                sx={{ "&:last-child td, &:last-child th": { border: 0 }, transition: 'ease all .5s', "&:hover": { backgroundColor: '#F5F5F5' } }}
                            >
                                <TableCell align="left">
                                    {row.year}
                                </TableCell>
                                <TableCell
                                    align="left">
                                    {row.active === false ? "Inactive" : "Active"}
                                </TableCell>
                                {row.active && <TableCell align='left'>
                                    {!showStatus && <Box
                                        sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                        <span>{row.reportingState}</span>
                                        <Button
                                            onClick={() => {
                                                setStatus(row.reportingState)
                                                setShowStatus(true)
                                            }}
                                            sx={{ color: "black", border: "solid 1px #ddd" }}
                                            variant='outlined'
                                            size='small'>
                                            Change Status
                                        </Button>
                                    </Box>}
                                    {showStatus && <FormControl variant="standard">
                                        <Box sx={{ display: "flex", justifyContent: "space-between", width: '180px', alignItems: "center" }}>

                                            {(status !== "Open" && statusArrows.prev) && <SkipPreviousIcon onClick={() => {
                                                setStatus(getPreviousAndNextYearState(status, "prev"));
                                                setStatusArrows({ prev: false, next: true })
                                            }} sx={{ color: "#416e71", cursor: "pointer" }} />}
                                            <span>{status}</span>
                                            {(status !== "Locked" && statusArrows.next) && <SkipNextIcon onClick={() => {
                                                setStatus(getPreviousAndNextYearState(status, "next"));
                                                setStatusArrows({ prev: true, next: false })
                                            }} sx={{ color: "#416e71", cursor: "pointer" }} />}

                                            <Tooltip title="Cancel Change">
                                                <IconButton onClick={() => setShowStatus(false)}>
                                                    <ClearIcon sx={{ color: "red", cursor: "pointer" }} />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Save Status">
                                                <IconButton onClick={() => handleSaveStatus(status, row.id)}>
                                                    <CheckIcon sx={{ color: "green", cursor: "pointer" }} />
                                                </IconButton>
                                            </Tooltip>
                                        </Box>
                                    </FormControl>
                                    }
                                </TableCell>}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {message.length > 1 && <Alert severity={message.includes("Successfully") ? "success" : "error"} onClose={() => setMessage("")}>{message}</Alert>}
        </Box>
        <AlertDialog
            isOpen={isAlertOpen}
            setIsOpen={setIsAlertOpen}
            modalTitle={modalTitle}
            modalContent={modalContent}
            handleAgree={addNewYear} />

        <AlertDialog
            isOpen={notification}
            setIsOpen={setNotification}
            modalTitle="REPORTING STATE SHOULD BE LOCKED OR CLOSED" //if this changed dont forgot to update AlertDialog component
            modalContent="To add new year change active reporting state locked or closed"
            handleAgree={() => setNotification(false)} />

    </main>
}

export default ReportingYear
