import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Modal,
    Select,
    Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useContext, useState } from "react";
import { AppContext } from "../../context/AppContext";
import CellBaseline from "./CellBaseline";
import CellDate from "./CellDate";
import CellEnterprise from "./CellEnterprise";
import CellInput from "./CellInput";
import CellList from "./CellList";
import CellListBool from "./CellListBool";
import CellNumber from "./CellNumber";
import CellProject from "./CellProject";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./DataEntryCell.scss";
import { API_URI } from "../../util/urls";

type Props = {
    index: any;
    line: any;
    cell?: any;
    cellIndex: any;
    historical?: any;
    rowId: any;
    multipleSelect: boolean;
    setEnterpriseSelectionState: any;
    enterpriseSelectionState: any;
    getGridData: any;
    setMessage: any;
    allChecked: boolean;
    selectedHeaders: any;
};

type SelectEnterprise = { uid: number; name: string; projectUID?: any };

function DataEntryCell(props: Props) {
    const { enterpriseReports, setSelects, selects, emptySearch, partnerUid } =
        useContext(AppContext);
    const [entValidationType, setEntValidationType] = useState("");
    const [entValidation, setEntValidation] = useState(false);
    const [projectValidation, setProjectValidation] = useState(false);
    const [, setErrorRename] = useState(false);
    const [, setHasProjectSelected] = useState(false);
    const [projectSelect, setProjectSelect] = useState([]);
    const [, setHasEnterpriseSelected] = useState(false);
    const [, setProjectSelected] = useState("");
    const [projectSelectedUid, setProjectSelectedUid] = useState(0);
    const [enterpriseSelect, setEnterpriseSelect] = useState([]);
    const [enterpriseSelectedUid, setEnterpriseSelectedUid] = useState(0);
    const [validationError, setValidationError] = useState(false);
    const [expanded, setExpanded] = React.useState<string | false>(false);

    const handleClose = () => setEntValidation(false);
    const handleProjectValidationClose = () => setProjectValidation(false);

    const handleChange =
        (panel: string) =>
        (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };
    const enableCheckBox = !props.line.entOrgUID && !props.line.projectUID;

    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        border: "2px solid #000",
        boxShadow: 24,
        p: 4,
    };

    const selectEnterprise = (item: SelectEnterprise) => {
        setHasEnterpriseSelected(true);
        setEnterpriseSelectedUid(item.uid);
        setValidationError(false);
        let existingEnts: any = [];
        enterpriseReports.map((l: any) => existingEnts.push(l.entOrgUID));
        setValidationError(existingEnts.indexOf(enterpriseSelectedUid) >= 0);
    };

    const selectProject = (item: any) => {
        setHasProjectSelected(true);
        setProjectSelected(item.id);
        setProjectSelectedUid(item.uid);
        setValidationError(false);
    };

    const toggleModalEnt = () => {
        setEnterpriseSelectedUid(0);
        setValidationError(false);

        switch (props.cell.type) {
            case "enterprise":
                if (!props.line.entOrgUID && props.line.projectUID) {
                    fetch(
                        `${API_URI}/partner/${partnerUid}/enterprises/all`
                    ).then(async (response) => {
                        const data = await response.json();
                        setSelects([
                            ...selects,
                            { data: data, name: "enterprises" },
                        ]);
                        setEnterpriseSelect(data);
                        setEntValidation(true);
                    });
                } else if (!props.line.entOrgUID && !props.line.projectUID) {
                    setEntValidation(false);
                    props.setMessage("Please fix partner project first");
                }
                break;
            case "project":
                if (!props.line.projectUID) {
                    fetch(`${API_URI}/partner/${partnerUid}/project/all`).then(
                        async (response) => {
                            const data = await response.json();
                            setProjectSelect(data);
                        }
                    );
                    setProjectValidation(true);
                }
                break;
        }
    };

    const doRename = () => {
        props.line.entOrgUID = enterpriseSelectedUid;
        fetch(`${API_URI}/partner/${partnerUid}/enterprises/rename`, {
            body: JSON.stringify({
                name: props.line.entOrgInitialName,
                uid: props.line.entOrgUID,
            }),
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
        }).then(async (response) => {
            if (response.ok) {
                setErrorRename(false);
                fetch(`${API_URI}/partner/${partnerUid}/enterprises/all`).then(
                    async (response) => {
                        const data = await response.json();
                        setSelects([
                            ...selects,
                            { data: data, name: "enterprises" },
                        ]);

                        fetch(
                            `${API_URI}/enterprise_report/${props.line.uid}/update`,
                            {
                                body: JSON.stringify(props.line),
                                method: "PUT",
                                headers: {
                                    "Content-Type": "application/json",
                                },
                            }
                        ).then(async (response) => {
                            if (response.ok) {
                                emptySearch();
                                setEntValidation(false);
                                props.setMessage("Successfully updated");
                            } else {
                                const errorMessage = await response.text();
                                props.setMessage(errorMessage);
                            }
                        });
                    }
                );
            } else {
                const errorMessage = await response.text();
                setEntValidation(false);
                props.setMessage(errorMessage);
            }
            props.getGridData();
        });
    };

    const doExistingProject = () => {
        props.line.projectUID = projectSelectedUid;
        fetch(`${API_URI}/enterprise_report/${props.line.uid}/update`, {
            body: JSON.stringify(props.line),
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
        }).then(async (response) => {
            if (response.ok) {
                fetch(`${API_URI}/partner/${partnerUid}/enterprises/all`).then(
                    async (response) => {
                        const data = await response.json();
                        setSelects([
                            ...selects,
                            { data: data, name: "enterprises" },
                        ]);
                        props.setMessage("Successfully updated");
                    }
                );
            } else {
                const errorMessage = await response.text();
                props.setMessage(errorMessage);
            }
            props.getGridData();
            setProjectValidation(false);
        });
    };

    const doExisting = () => {
        props.line.entOrgUID = enterpriseSelectedUid;
        fetch(`${API_URI}/enterprise_report/${props.line.uid}/update`, {
            body: JSON.stringify(props.line),
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
        }).then(async (response) => {
            if (response.ok) {
                props.setMessage("Successfully updated.");
            } else {
                const errorMessage = await response.text();
                props.setMessage(errorMessage);
            }
            setEntValidation(false);
            props.getGridData();
        });
    };

    const doCreate = () => {
        fetch(`${API_URI}/partner/${partnerUid}/enterprises/add`, {
            body: JSON.stringify({
                name: props.line.entOrgInitialName,
                projectUID: props.line.projectUID,
            }),
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then(async (response) => {
                if (!response.ok) {
                    setEntValidation(false);
                    return response.text().then((text) => {
                        throw new Error(text);
                    });
                } else {
                    props.setMessage("Successfully updated.");
                    return response.json();
                }
            })
            .then((data) => {
                props.line.entOrgUID = data.uid;
                fetch(`${API_URI}/partner/${partnerUid}/enterprises/all`).then(
                    async (response) => {
                        const data = await response.json();
                        setSelects([
                            ...selects,
                            { data: data, name: "enterprises" },
                        ]);

                        fetch(
                            `${API_URI}/enterprise_report/${props.line.uid}/update`,
                            {
                                body: JSON.stringify(props.line),
                                method: "PUT",
                                headers: {
                                    "Content-Type": "application/json",
                                },
                            }
                        ).then(() => {
                            emptySearch();
                            setEntValidation(false);
                        });
                    }
                );
            })
            .catch((error) => props.setMessage(error.message));
    };
    return (
        <div>
            {
                <div className="cell-holder">
                    {props.cell.type === "input" && (
                        <CellInput
                            editMode={
                                props.line.entOrgUID && props.line.projectUID
                            }
                            title={props.cell.title}
                            id={props.cell.id}
                            row={props.rowId}
                            cellValue={props.line[props.cell.id]}
                            enterpriseId={props.line["entOrgUID"]}
                            name={props.cell.name}
                            isClosed={
                                props.line["reportingStatusName"] === "Closed"
                            }
                            isCompleted={
                                props.line["reportingStatusName"] ===
                                "Completed"
                            }
                            isInvalid={!props.line[props.cell.id]}
                            isValid={props.line[props.cell.id]}
                        />
                    )}
                    {props.cell.type === "number" && (
                        <CellNumber
                            editMode={
                                props.line.entOrgUID && props.line.projectUID
                            }
                            title={props.cell.title}
                            id={props.cell.id}
                            row={props.rowId}
                            cellValue={props.line[props.cell.id]}
                            enterpriseId={props.line["entOrgUID"]}
                            name={props.cell.name}
                            isClosed={
                                props.line["reportingStatusName"] === "Closed"
                            }
                            isCompleted={
                                props.line["reportingStatusName"] ===
                                "Completed"
                            }
                            isInvalid={!props.line[props.cell.id]}
                            isValid={props.line[props.cell.id]}
                            baselineYear={props.line["baselineYear"]}
                            isOutScope={
                                props.line["baselineYear"] > props.cell.name
                            }
                            typeError={
                                props.line[props.cell.title] &&
                                props.line[props.cell.title][props.cell.name] &&
                                props.line[props.cell.title][props.cell.name]
                                    .typeError
                            }
                            hasSpace={
                                props.line[props.cell.title] &&
                                props.line[props.cell.title][props.cell.name] &&
                                props.line[props.cell.title][props.cell.name]
                                    .hasSpace
                            }
                            previousValue={
                                props.line[props.cell.title] &&
                                props.line[props.cell.title][
                                    parseInt(props.cell.name) - 1
                                ] &&
                                props.line[props.cell.title][
                                    parseInt(props.cell.name) - 1
                                ].value
                            }
                        />
                    )}
                    {props.cell.type === "baseline" && (
                        <CellBaseline
                            editMode={
                                props.line.entOrgUID && props.line.projectUID
                            }
                            title={props.cell.title}
                            id={props.cell.id}
                            row={props.rowId}
                            cellValue={props.line[props.cell.id]}
                            enterpriseId={props.line["entOrgUID"]}
                            name={props.cell.name}
                            isClosed={
                                props.line["reportingStatusName"] === "Closed"
                            }
                            isCompleted={
                                props.line["reportingStatusName"] ===
                                "Completed"
                            }
                            isInvalid={!props.line[props.cell.id]}
                            isValid={props.line[props.cell.id]}
                            baselineYear={props.line["baselineYear"]}
                            baselineValue={props.line["baselineYear"]}
                            isOutScope={
                                props.line["baselineYear"] > props.cell.name
                            }
                            typeError={
                                props.line[props.cell.title] &&
                                props.line[props.cell.title][props.cell.name] &&
                                props.line[props.cell.title][props.cell.name]
                                    .typeError
                            }
                            hasSpace={
                                props.line[props.cell.title] &&
                                props.line[props.cell.title][props.cell.name] &&
                                props.line[props.cell.title][props.cell.name]
                                    .hasSpace
                            }
                        />
                    )}
                    {props.cell.type === "date" && (
                        <CellDate
                            editMode={
                                props.line.entOrgUID && props.line.projectUID
                            }
                            title={props.cell.title}
                            id={props.cell.id}
                            row={props.rowId}
                            cellValue={props.line[props.cell.id]}
                            enterpriseId={props.line["entOrgUID"]}
                            name={props.cell.name}
                            isClosed={
                                props.line["reportingStatusName"] === "Closed"
                            }
                            isCompleted={
                                props.line["reportingStatusName"] ===
                                "Completed"
                            }
                            isInvalid={!props.line[props.cell.id]}
                            isValid={props.line[props.cell.id]}
                            baselineYear={props.line["baselineYear"]}
                            typeError={
                                props.line[props.cell.title] &&
                                props.line[props.cell.title][props.cell.name] &&
                                props.line[props.cell.title][props.cell.name]
                                    .typeError
                            }
                            hasSpace={
                                props.line[props.cell.title] &&
                                props.line[props.cell.title][props.cell.name] &&
                                props.line[props.cell.title][props.cell.name]
                                    .hasSpace
                            }
                        />
                    )}
                    {props.cell.type === "enterprise" && (
                        <CellEnterprise
                            title={props.cell.title}
                            id={props.cell.id}
                            row={props.rowId}
                            cellValue={props.line[props.cell.id]}
                            name={props.cell.name}
                            isClosed={
                                props.line["reportingStatusName"] === "Closed"
                            }
                            isCompleted={
                                props.line["reportingStatusName"] ===
                                "Completed"
                            }
                            isInvalid={
                                props.line[props.cell.id] &&
                                (props.line[props.cell.id].length < 0 ||
                                    !props.line.projectUID)
                            }
                            isValid={props.line[props.cell.id]}
                            isPurple={!props.line.entOrgUID}
                            isDuplicate={
                                props.line[props.cell.title] &&
                                props.line[props.cell.title][props.cell.name] &&
                                props.line[props.cell.title][props.cell.name]
                                    .isDuplicate
                            }
                            entName={props.line.entOrgInitialName}
                            multipleSelect={props.multipleSelect}
                            uid={props.line.uid}
                            setEnterpriseSelectionState={
                                props.setEnterpriseSelectionState
                            }
                            enterpriseSelectionState={
                                props.enterpriseSelectionState
                            }
                            allChecked={props.allChecked}
                            enableCheckBox={enableCheckBox}
                            toggleModalEnt={toggleModalEnt}
                        />
                    )}
                    {props.cell.type === "list" && (
                        <CellList
                            list={props.cell.list}
                            name={props.cell.name}
                            select={props.cell.select}
                            title={props.cell.title}
                            row={props.rowId}
                            id={props.cell.id}
                            editMode={
                                props.line.entOrgUID && props.line.projectUID
                            }
                            enterpriseId={props.line["entOrgUID"]}
                            isValid={
                                props.line[props.cell.title] &&
                                props.line[props.cell.title][props.cell.name] &&
                                props.cell.list.indexOf(
                                    props.line[props.cell.title][
                                        props.cell.name
                                    ].value
                                ) >= 0 &&
                                props.line[props.cell.title][props.cell.name]
                                    .value.length >= 0
                            }
                            isInvalid={
                                !props.line[props.cell.title] ||
                                !props.line[props.cell.title][
                                    props.cell.name
                                ] ||
                                props.line[props.cell.title][props.cell.name]
                                    .isInvalid ||
                                (props.line[props.cell.title] &&
                                    props.line[props.cell.title][
                                        props.cell.name
                                    ] &&
                                    props.cell.list.indexOf(
                                        props.line[props.cell.title][
                                            props.cell.name
                                        ].value
                                    ) < 0)
                            }
                            isClosed={
                                props.line["reportingStatusName"] === "Closed"
                            }
                            isCompleted={
                                props.line["reportingStatusName"] ===
                                "Completed"
                            }
                            cellValue={props.line[props.cell.id]}
                        />
                    )}
                    {props.cell.type === "listbool" && (
                        <CellListBool
                            list={props.cell.list}
                            name={props.cell.name}
                            select={props.cell.select}
                            title={props.cell.title}
                            row={props.rowId}
                            id={props.cell.id}
                            editMode={
                                props.line.entOrgUID && props.line.projectUID
                            }
                            enterpriseId={props.line["entOrgUID"]}
                            isValid={
                                props.line[props.cell.title] &&
                                props.line[props.cell.title][props.cell.name] &&
                                props.cell.list.indexOf(
                                    props.line[props.cell.title][
                                        props.cell.name
                                    ].value
                                ) >= 0 &&
                                props.line[props.cell.title][props.cell.name]
                                    .value.length >= 0
                            }
                            isInvalid={
                                !props.line[props.cell.title] ||
                                !props.line[props.cell.title][
                                    props.cell.name
                                ] ||
                                props.line[props.cell.title][props.cell.name]
                                    .isInvalid ||
                                (props.line[props.cell.title] &&
                                    props.line[props.cell.title][
                                        props.cell.name
                                    ] &&
                                    props.cell.list.indexOf(
                                        props.line[props.cell.title][
                                            props.cell.name
                                        ].value
                                    ) < 0)
                            }
                            isClosed={
                                props.line["reportingStatusName"] === "Closed"
                            }
                            isCompleted={
                                props.line["reportingStatusName"] ===
                                "Completed"
                            }
                            cellValue={props.line[props.cell.id]}
                        />
                    )}
                    {props.cell.type === "project" && (
                        <CellProject
                            list={props.cell.list}
                            name={props.cell.name}
                            title={props.cell.title}
                            row={props.rowId}
                            id={props.cell.id}
                            isPurple={!props.line.projectUID}
                            enterprise={
                                props.line[props.cell.title] &&
                                props.line[props.cell.title][props.cell.name]
                                    ? props.line[props.cell.title][
                                          props.cell.name
                                      ].value
                                    : ""
                            }
                            enterpriseId={props.line["entOrgUID"]}
                            isValid={props.line[props.cell.id]}
                            isInvalid={!props.line[props.cell.id]}
                            isClosed={
                                props.line["reportingStatusName"] === "Closed"
                            }
                            isCompleted={
                                props.line["reportingStatusName"] ===
                                "Completed"
                            }
                            cellValue={
                                props.line[props.cell.projectInitialName]
                            }
                            prjName={props.line["projectInitialName"]}
                            toggleModalEnt={toggleModalEnt}
                        />
                    )}
                </div>
            }
            {entValidation && (
                <Modal
                    sx={{ style }}
                    open={entValidation}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box
                        sx={{
                            width: "50%",
                            margin: "10% auto ",
                            backgroundColor: "white",
                            padding: "1rem",
                        }}
                    >
                        <div className="md-title center">
                            Please select an action for this cell
                        </div>
                        <div>
                            <p className="center">
                                {props.line.entOrgInitialName} is not recognized
                                as an existing enterprise so an extra validation
                                step is necessary to prevent duplicates in the
                                database.
                            </p>
                            <p className="center">
                                <strong>
                                    Please select the right scenario from below:
                                </strong>
                            </p>
                            {enterpriseSelect.length >= 0 && (
                                <div>
                                    <Accordion
                                        expanded={expanded === "panel1"}
                                        onChange={handleChange("panel1")}
                                    >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <div
                                                className="pointer"
                                                onClick={() =>
                                                    setEntValidationType("new")
                                                }
                                            >
                                                <Typography
                                                    sx={{
                                                        width: "100%",
                                                        flexShrink: 0,
                                                    }}
                                                >
                                                    {
                                                        props.line
                                                            .entOrgInitialName
                                                    }{" "}
                                                    is a new enterprise
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        color: "text.secondary",
                                                    }}
                                                >
                                                    Choose this option to add
                                                    this enterprise to the
                                                    database as a new entry{" "}
                                                </Typography>
                                            </div>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                }}
                                            >
                                                {entValidationType ===
                                                    "new" && (
                                                    <p>
                                                        Please click confirm to
                                                        create{" "}
                                                        {
                                                            props.line
                                                                .entOrgInitialName
                                                        }{" "}
                                                        as a new enterprise
                                                    </p>
                                                )}

                                                <Button
                                                    sx={{
                                                        color: "white",
                                                        backgroundColor:
                                                            "#efaf0a",
                                                        "&:hover": {
                                                            backgroundColor:
                                                                "#f0ae0b",
                                                        },
                                                        width: "120px",
                                                        margin: "auto",
                                                        marginTop: "1.5rem",
                                                    }}
                                                    onClick={doCreate}
                                                >
                                                    Confirm
                                                </Button>
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion
                                        expanded={expanded === "panel2"}
                                        onChange={handleChange("panel2")}
                                    >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <div
                                                className="pointer"
                                                onClick={() =>
                                                    setEntValidationType(
                                                        "rename"
                                                    )
                                                }
                                            >
                                                <Typography
                                                    sx={{
                                                        width: "100%",
                                                        flexShrink: 0,
                                                    }}
                                                >
                                                    {
                                                        props.line
                                                            .entOrgInitialName
                                                    }{" "}
                                                    is the new name for an
                                                    existing enterprise
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        color: "text.secondary",
                                                    }}
                                                >
                                                    Choose this option to select
                                                    an existing enterprise from
                                                    a list and rename this
                                                    enterprise to the name
                                                    provided in the Excel upload
                                                </Typography>
                                            </div>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>
                                                <FormControl
                                                    variant="standard"
                                                    sx={{ m: 1, width: "100%" }}
                                                >
                                                    <InputLabel id="demo-simple-select-standard-label">
                                                        Please select the
                                                        enterprise you have
                                                        reported on before:
                                                    </InputLabel>
                                                    {
                                                        <Select
                                                            sx={{
                                                                width: "100%",
                                                            }}
                                                            labelId="demo-simple-select-standard-label"
                                                            id="demo-simple-select-standard"
                                                        >
                                                            {enterpriseSelect.map(
                                                                (
                                                                    item,
                                                                    index
                                                                ) => (
                                                                    <MenuItem
                                                                        key={
                                                                            index
                                                                        }
                                                                        value={
                                                                            //@ts-ignore
                                                                            item.name
                                                                        }
                                                                        onClick={() =>
                                                                            selectEnterprise(
                                                                                {
                                                                                    //@ts-ignore
                                                                                    uid: item.uid,
                                                                                    //@ts-ignore
                                                                                    name: item.name,
                                                                                    projectUID:
                                                                                        //@ts-ignore
                                                                                        item.projectUID,
                                                                                }
                                                                            )
                                                                        }
                                                                    >
                                                                        {
                                                                            //@ts-ignore
                                                                            item.name
                                                                        }
                                                                    </MenuItem>
                                                                )
                                                            )}
                                                        </Select>
                                                    }
                                                    {validationError && (
                                                        <p>
                                                            {
                                                                props.line
                                                                    .entOrgInitialName
                                                            }{" "}
                                                            is already reported
                                                            in your current
                                                            Excel file. Please
                                                            update your Excel
                                                            sheet and reupload
                                                        </p>
                                                    )}

                                                    <Button
                                                        sx={{
                                                            color: "white",
                                                            backgroundColor:
                                                                "#efaf0a",
                                                            "&:hover": {
                                                                backgroundColor:
                                                                    "#f0ae0b",
                                                            },
                                                            width: "120px",
                                                            margin: "auto",
                                                            marginTop: "1.5rem",
                                                        }}
                                                        onClick={doRename}
                                                    >
                                                        Confirm
                                                    </Button>
                                                </FormControl>
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion
                                        expanded={expanded === "panel3"}
                                        onChange={handleChange("panel3")}
                                    >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <div
                                                className="pointer"
                                                onClick={() =>
                                                    setEntValidationType(
                                                        "select"
                                                    )
                                                }
                                            >
                                                <Typography
                                                    sx={{
                                                        width: "100%",
                                                        flexShrink: 0,
                                                    }}
                                                >
                                                    {
                                                        props.line
                                                            .entOrgInitialName
                                                    }{" "}
                                                    refers to an existing
                                                    enterprise, but is spelled
                                                    wrong
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        color: "text.secondary",
                                                    }}
                                                >
                                                    Choose this option to select
                                                    an existing enterprise form
                                                    a list and set this entry to
                                                    the existing name in the
                                                    database{" "}
                                                </Typography>
                                            </div>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>
                                                <FormControl
                                                    variant="standard"
                                                    sx={{ m: 1, width: "100%" }}
                                                >
                                                    <InputLabel id="demo-simple-select-standard-label">
                                                        Please select the
                                                        enterprise you have
                                                        reported on before:
                                                    </InputLabel>
                                                    {
                                                        <Select
                                                            sx={{
                                                                width: "100%",
                                                            }}
                                                            labelId="demo-simple-select-standard-label"
                                                            id="demo-simple-select-standard"
                                                        >
                                                            {
                                                                //@ts-ignore
                                                                enterpriseSelect.map(
                                                                    (
                                                                        item,
                                                                        index
                                                                    ) => (
                                                                        <MenuItem
                                                                            key={
                                                                                index
                                                                            }
                                                                            value={
                                                                                //@ts-ignore
                                                                                item.name
                                                                            }
                                                                            onClick={() =>
                                                                                selectEnterprise(
                                                                                    {
                                                                                        //@ts-ignore
                                                                                        uid: item.uid,
                                                                                        //@ts-ignore
                                                                                        name: item.name,
                                                                                        projectUID:
                                                                                            //@ts-ignore
                                                                                            item.projectUID,
                                                                                    }
                                                                                )
                                                                            }
                                                                        >
                                                                            {
                                                                                //@ts-ignore
                                                                                item.name
                                                                            }
                                                                        </MenuItem>
                                                                    )
                                                                )
                                                            }
                                                        </Select>
                                                    }
                                                    <p>
                                                        Please click on the
                                                        button below to confirm
                                                        you selection
                                                    </p>

                                                    {validationError && (
                                                        <p>
                                                            {
                                                                props.line
                                                                    .entOrgInitialName
                                                            }{" "}
                                                            is already reported
                                                            in your current
                                                            Excel file. Please
                                                            update your Excel
                                                            sheet and reupload
                                                        </p>
                                                    )}

                                                    <Button
                                                        sx={{
                                                            color: "white",
                                                            backgroundColor:
                                                                "#efaf0a",
                                                            "&:hover": {
                                                                backgroundColor:
                                                                    "#f0ae0b",
                                                            },
                                                            width: "120px",
                                                            margin: "auto",
                                                            marginTop: "1.5rem",
                                                        }}
                                                        disabled={
                                                            validationError
                                                        }
                                                        onClick={doExisting}
                                                    >
                                                        Confirm
                                                    </Button>
                                                </FormControl>
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                            )}
                        </div>
                    </Box>
                </Modal>
            )}
            {projectValidation && (
                <Modal
                    sx={{ style }}
                    open={projectValidation}
                    onClose={handleProjectValidationClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box
                        sx={{
                            width: "50%",
                            margin: "10% auto ",
                            backgroundColor: "white",
                            padding: "1rem",
                        }}
                    >
                        <h2 className="md-title center">PROJECT VALIDATION</h2>
                        <div>
                            <p className="center">
                                The project ID does not match one of your
                                existing, active projects
                            </p>
                            <p className="center">
                                <strong>
                                    Please select the right scenario from below:
                                </strong>
                            </p>
                        </div>
                        <FormControl
                            variant="standard"
                            sx={{ m: 1, width: "100%" }}
                        >
                            <InputLabel id="demo-simple-select-standard-label">
                                Select a project:
                            </InputLabel>
                            {
                                <Select
                                    sx={{ width: "100%" }}
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                >
                                    {
                                        //@ts-ignore
                                        projectSelect.map((item, index) => (
                                            <MenuItem
                                                key={index}
                                                //@ts-ignore
                                                value={item.id}
                                                onClick={() =>
                                                    selectProject({
                                                        //@ts-ignore
                                                        uid: item.uid,
                                                        //@ts-ignore
                                                        title: item.title,
                                                        //@ts-ignore
                                                        id: item.id,
                                                    })
                                                }
                                            >
                                                {/* @ts-ignore */}
                                                {item.id}
                                            </MenuItem>
                                        ))
                                    }
                                </Select>
                            }
                            <Button
                                sx={{
                                    color: "white",
                                    backgroundColor: "#efaf0a",
                                    "&:hover": { backgroundColor: "#f0ae0b" },
                                    width: "120px",
                                    margin: "auto",
                                    marginTop: "1.5rem",
                                }}
                                disabled={validationError}
                                onClick={doExistingProject}
                            >
                                Confirm
                            </Button>
                        </FormControl>
                    </Box>
                </Modal>
            )}
        </div>
    );
}

export default DataEntryCell;
