import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import { AppContext } from '../../../context/AppContext';
import { useContext, useState } from 'react';
import type { Year } from '../reporting-year/ReportingYear';
import type { Form } from './Partner'
import { API_URI } from '../../../util/urls';
import { AdminContext } from '../../../context/AdminContext';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

export default function CreatePartnerForm(props: any) {
    const { isOpen, setIsOpen, setMessage } = props
    const { reportingYearsList } = useContext(AppContext);
    const { regions, countries, getAllPartnerData } = useContext(AdminContext);
    const [form, setForm] = useState<Form>({})

    const handleClose = () => {
        setIsOpen(false);
    };

    const addPartner = () => {
        fetch(`${API_URI}/admin/partner/add`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ ...form, "reportingState": defaultPartnerState }),
        })
            .then((response) => {
                if (response.ok) {
                    setMessage("Partner Successfully Added.")
                    getAllPartnerData()
                    return response.json()
                }
                else {
                    if (response.status === 400) {
                        throw new Error("Partner mandatory fields missing.")
                    } else if (response.status === 500) {
                        throw new Error("Server Error")
                    }
                }
            })
            .then((data) => {
                console.log('Success:', data);
            })
            .catch((error) => {
                setMessage(error.message)
            });
        setIsOpen(false);
    }

    const getActiveReportingYearState = (reportingYearsList: Array<Year>) => {
        return reportingYearsList.find(y => y.active === true)?.reportingState;
    }

    const defaultPartnerState = getActiveReportingYearState(reportingYearsList);

    return (

        <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={isOpen}
        >
            <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                Add new Partner
            </BootstrapDialogTitle>
            <DialogContent sx={{ display: "flex", flexDirection: "column", minWidth: "500px" }} dividers>
                <TextField
                    id="standard-size-small"
                    value={form.name}
                    label="Name"
                    size="small"
                    variant="standard"
                    onChange={(e) => setForm({ ...form, "name": e.target.value })}
                />
                <TextField
                    id="standard-size-small"
                    value={form.salesForceId}
                    label="SaleForceId"
                    size="small"
                    variant="standard"
                    onChange={(e) => setForm({ ...form, "salesForceId": e.target.value })}
                />
                <InputLabel id="status">Status</InputLabel>
                <Select
                    sx={{ width: "160px" }}
                    labelId="status"
                    id="demo-simple-select-standard"
                    value={form.active}
                    onChange={(e: SelectChangeEvent) => setForm({ ...form, "active": e.target.value })}
                    label="regions"
                    size='small'
                >
                    <MenuItem value="true">Active
                    </MenuItem>
                    <MenuItem value="false">Inactive
                    </MenuItem>
                </Select>
                <InputLabel id="country">Country</InputLabel>
                <Select
                    sx={{ width: "160px" }}
                    labelId="country"
                    id="demo-simple-select-standard"
                    value={form.countryId}
                    onChange={(e: SelectChangeEvent) => setForm({ ...form, "countryId": e.target.value })}
                    label="countries"
                    size='small'

                >
                    {/* @ts-ignore */}
                    {countries.map((c, i) => <MenuItem key={i} value={c.id}>{c.name}
                    </MenuItem>)}
                </Select>
                <InputLabel id="region">Region</InputLabel>
                <Select
                    sx={{ width: "160px" }}
                    labelId="region"
                    id="demo-simple-select-standard"
                    value={form.regionId}
                    onChange={(e: SelectChangeEvent) => setForm({ ...form, "regionId": e.target.value })}
                    label="regions"
                    size='small'
                >
                    {/* @ts-ignore */}
                    {regions.map((c, i) => <MenuItem key={i} value={c.id}>{c.name}
                    </MenuItem>)}
                </Select>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={addPartner}>
                    Save
                </Button>
            </DialogActions>
        </BootstrapDialog>
    );
}
