import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Checkbox } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../context/AppContext';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 730,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

export default function ModalDataEntry() {
    const [checked, setChecked] = useState(false);
    const [open, setOpen] = useState(checked);
    const handleClose = () => setOpen(false);
    const { user } = useContext(AppContext)

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
    };

    useEffect(() => {
        const status = window.localStorage.getItem('ardius.welcome');
        if (!status) {
            setOpen(true);
        }
    }, []);

    useEffect(() => {
        if (checked) {
            setOpen(false);
            window.localStorage.setItem('ardius.welcome', "true")
        }
    }, [checked]);
    return (
        <div className='modalComponent'>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h4" component="h2">
                        Argidius - Enterprise-Level Data Portal
                    </Typography>
                    <Box sx={{ mt: 2, display: "flex", flexDirection: "column" }}>
                        <p className="margin">
                            Hi, {user && user.name} welcome!
                        </p>
                        <span>
                            We hope you enjoy using the Portal. If this is the first time you are logging in,<br />
                            please start by downloading the instruction materials using this <a href="/Slides instruction video.pptx" target="_blank">link</a>.
                        </span>
                        <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
                            <Checkbox
                                sx={{ p: 0, mr: 1 }}
                                checked={checked}
                                onChange={handleChange}
                            />
                            <span>Please don’t show this message again.</span>
                        </Box>
                        <Button sx={{ alignSelf: "flex-end" }} variant="text" onClick={() => setOpen(false)}>Close</Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}
