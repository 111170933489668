import { Box, Breadcrumbs, IconButton, InputBase, Paper } from '@mui/material';
import axios from 'axios';
import { useCallback, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { API_URI } from '../../../util/urls';
import './Grid.scss';
import MuiTable from '../../../widgets/MuiTable';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';

export type User = {
    disabled: boolean,
    email: string,
    id: number,
    name: string,
    partnerId?: number,
    partnerName?: string,
    userRole: "Admin" | "User"
}
export type Users = Array<User>

function Grid() {

    const style = {
        color: 'black',
        fontSize: '14px',
        textDecoration: 'none'
    }

    let navigate = useNavigate();
    const [data, setData] = useState<Users | []>([]);
    const [userList, setUserList] = useState<Users | []>([]);
    const [page, setPage] = useState(0);

    const columns = ['Id', 'Name', 'E-mail', 'Role', 'Partner Name', 'Status', '', ''];

    const getData = useCallback(() => {
        axios
            .get(`${API_URI}/users/all`)
            .then(response => {
                setData(response.data);
            });
    }, []);

    useEffect(() => getData(), [getData]);

    useEffect(() => {
        setUserList(data);
    }, [data]);

    const filterData = (searchParam: string) => {
        if (searchParam === "") {
            setUserList(data);
        }
        else {
            let filteredUsers = data.filter(d => d.name.toLowerCase().includes(searchParam.toLowerCase()));
            setUserList(filteredUsers);
        }
    }

    return <main id='muiItemsContainer'>
        <Breadcrumbs aria-label="breadcrumb">
            <Link style={style} to="/data-entry">
                Home
            </Link>
            <Link style={style} to="/admin/users">
                Users
            </Link>
        </Breadcrumbs>
        <Box
            sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", mt: 1 }}
            onClick={() => { navigate("/admin/users/create") }}>
            Add new User
            <AddIcon
                sx={{ color: 'green', fontSize: '40px', cursor: "pointer" }}
            />
        </Box>
        <Box sx={{ mr: 3, mb: 1 }}>
            <Paper
                component="form"
                sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: "100%" }}
            >
                <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Search user"
                    inputProps={{ 'aria-label': 'Search user' }}
                    onChange={(e) => {
                        setPage(0)
                        filterData(e.target.value);
                    }}
                />
                <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                    <SearchIcon />
                </IconButton>
            </Paper>
        </Box>
        {userList && columns && <MuiTable
            data={userList}
            columns={columns}
            getData={getData}
            page={page}
            setPage={setPage}
        />}

    </main>
}

export default Grid
