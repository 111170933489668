//Define type regex here

export const convertVfmeDate = (date: string) => {
    const regex = /Type(\d+)UID/;
    const itemString = String(date);
    const match = itemString.match(regex);
    const year = match ? match[1] : "";

    // Create the transformed string
    return year ? `${year} Type` : date;
};
