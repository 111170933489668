import { useCallback, useContext, useEffect, useState } from 'react'
import { AppContext } from '../../context/AppContext';
import DoneIcon from '@mui/icons-material/Done';
import { Checkbox } from '@mui/material';
import { EnterpriseSelectionState } from './Grid';


type Props = {
    title: string,
    id: number,
    row: number,
    cellValue: string,
    name: string,
    isClosed: boolean,
    isCompleted: boolean,
    isInvalid: boolean,
    isValid: boolean,
    isPurple: boolean,
    isDuplicate: boolean,
    entName: string,
    historical?: any
    editMode?: boolean,
    multipleSelect: boolean,
    uid: number,
    enterpriseSelectionState: Array<EnterpriseSelectionState>,
    setEnterpriseSelectionState: (c: any) => void,
    allChecked: boolean,
    enableCheckBox: boolean,
    toggleModalEnt: () => void,
}
type EntList = {
    data: any,
    name: string,
    uid?: any
}

function computeCellStyle(props: Props, conflict: boolean) {
    return [props.isPurple ? 'purple' : (props.isClosed || props.isCompleted) ? 'out-scope' : conflict ? 'warning' : props.isDuplicate ? 'warning' : props.isInvalid ? 'invalid' : props.isValid ? 'valid' : '', 'cell'];
}

function CellEnterprise(props: Props) {
    const { selects } = useContext(AppContext);
    const [conflict, setConflict] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [checked, setChecked] = useState(false);

    const style = computeCellStyle(props, conflict).join(' ');

    const getData = useCallback(() => {
        let entList: Array<EntList> = selects.filter((item: EntList) => item.name === 'enterprises')
        if (entList.length > 0)
            entList = entList[entList.length - 1].data
        const ent = entList.filter(item => item.uid === props.cellValue)
        if (ent[0])
            setInputValue(ent[0].name);
        else
            setInputValue(props.entName);
        if ((inputValue && props.historical) && props.historical !== inputValue)
            setConflict(true);
    }, [inputValue, props.cellValue, props.entName, selects, props.historical])

    const confirmDuplicate = () => {
        props.isDuplicate = false
        props.isValid = true
    }

    useEffect(() => {
        getData()
    }, [getData])

    return (
        <div className={style}>
            <input
                type="text"
                readOnly={props.isClosed || !props.editMode}
                value={inputValue}
                style={{ cursor: 'pointer' }}
                onClick={() => props.toggleModalEnt()}
            />
            {props.isDuplicate && <span >
                <button onClick={confirmDuplicate}>
                    <DoneIcon />
                </button>
            </span>}
            {props.isInvalid && <span className="tooltip" md-direction="top">
                <strong>Cell empty</strong><br />
                Data is missing<br />
                <i>Improvement: fill in the blanks</i>
            </span>}
            {props.isDuplicate && <span className="tooltip" md-direction="top" >
                <strong>Potential duplicate detected</strong><br />
                <i>Improvement: Please check name of enterprise. Delete duplicate if necessary.</i>
            </span>}
            {props.isClosed && <span className="tooltip" md-direction="top">
                <strong>Enterprise is closed</strong><br />
                No data required.
            </span>}
            {props.isPurple && props.multipleSelect && <Checkbox
                sx={{
                    color: "white",
                    '&.Mui-checked': {
                        color: "white",
                    },
                }}
                size="small"
                disabled={props.enableCheckBox}
                checked={props.allChecked ? props.allChecked : checked}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    props.setEnterpriseSelectionState([...props.enterpriseSelectionState, { "uid": props.uid, "checked": e.target.checked }]);
                    setChecked(e.target.checked)
                }
                }
            />}

        </div>
    )
}

export default CellEnterprise;
