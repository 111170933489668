import { useCallback, useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";

type Props = {
    list: string;
    name: string;
    select: string;
    title: string;
    row: number;
    id: number;
    editMode: boolean;
    enterpriseId: number;
    isValid: boolean;
    isInvalid: boolean;
    isClosed: boolean;
    isCompleted: boolean;
    cellValue: string;
    isPurple?: boolean;
    historical?: any;
};

function computeCellStyle(
    props: Props,
    conflict: boolean,
    isInvalidStatus: boolean,
    isValidStatus: boolean
) {
    return [
        props.isPurple
            ? "purple"
            : props.isClosed || props.isCompleted
            ? "out-scope"
            : conflict
            ? "warning"
            : isInvalidStatus
            ? "invalid"
            : isValidStatus
            ? "valid"
            : "",
        "cell",
    ];
}

function CellList(props: Props) {
    const { selects, updateCell, grid } = useContext(AppContext);
    const [list, setList] = useState<any>(undefined);
    const [inputValue, setInputValue] = useState("");
    const [conflict, setConflict] = useState(false);
    const [isValidStatus, setIsValidStatus] = useState(false);
    const [isInvalidStatus, setIsInvalidStatus] = useState(false);

    const style = computeCellStyle(
        props,
        conflict,
        isInvalidStatus,
        isValidStatus
    ).join(" ");

    const getStatusCell = useCallback(() => {
        if (!inputValue || !list) {
            return;
        }
        //@ts-ignore
        const hasOption = list.filter((item) => item.uid === inputValue);
        if (inputValue && hasOption[0]) {
            setIsValidStatus(true);
            setIsInvalidStatus(false);
        }
        if (inputValue && props.historical && props.historical !== inputValue) {
            setConflict(true);
        }
    }, [inputValue, list, props.historical]);

    const getStatus = useCallback(() => {
        const listTmp = selects.filter(
            (select: { name: string }) =>
                select.name.toLowerCase() === props.select.toLowerCase()
        );
        if (listTmp[0]) {
            setList(listTmp[0].data);
        }
        setInputValue(props.cellValue);
        getStatusCell();

        //@ts-ignore
        const select = selects.filter(
            (select: { name: string }) =>
                select.name.toLowerCase() === props.select.toLowerCase()
        );
        if (select[0]) {
            setList(select[select.length - 1].data);
        }
    }, [getStatusCell, props.cellValue, props.select, selects]);

    const save = () => {
        if (inputValue.length > 0) {
            setIsValidStatus(true);
            setIsInvalidStatus(false);
        } else {
            setIsValidStatus(false);
            setIsInvalidStatus(true);
        }
        updateCell({ row: props.row, id: props.id, value: inputValue });
    };

    const confirmConflict = () => {
        setConflict(false);
        props.isValid = true;
    };

    const goBackHistory = () => {
        setConflict(false);
        props.isValid = true;
        setInputValue(props.historical);
    };

    useEffect(() => {
        getStatus();
    }, [getStatus]);

    return (
        <div className={style}>
            <select defaultValue={inputValue} disabled={true} onChange={save}>
                {list &&
                    // eslint-disable-next-line array-callback-return
                    list.map((l: any) => {
                        if (props.name === "Reporting status") {
                            if (props.isClosed) {
                                return (
                                    <option key={l.id} value={l.id} selected>
                                        {l.name}
                                    </option>
                                );
                            } else {
                                return (
                                    <option key={l.id} value={l.id}>
                                        {l.name}
                                    </option>
                                );
                            }
                        } else if (props.name === "Country") {
                            return (
                                <option key={l.id} value={l.id} selected>
                                    {grid[props.row].countryName}
                                </option>
                            );
                        } else if (props.name === "Region") {
                            return (
                                <option key={l.id} value={l.id} selected>
                                    {grid[props.row].regionName}
                                </option>
                            );
                        } else if (props.name === "Sector") {
                            return (
                                <option key={l.id} value={l.id} selected>
                                    {grid[props.row].sectorName}
                                </option>
                            );
                        } else if (props.name === "Currency") {
                            return (
                                <option key={l.id} value={l.id} selected>
                                    {grid[props.row].revenueCurrencyCode}
                                </option>
                            );
                        } else if (props.name === "Urban or Rural") {
                            return (
                                <option key={l.id} value={l.id} selected>
                                    {grid[props.row].areaCode}
                                </option>
                            );
                        } else if (
                            props.name === "Social or Environmental Enterprise?"
                        ) {
                            return (
                                <option key={l.id} value={l.id} selected>
                                    {grid[props.row].missionDesc}
                                </option>
                            );
                        } else if (props.name === "Existing Business or Idea") {
                            return (
                                <option key={l.id} value={l.id} selected>
                                    {grid[props.row].businessStatusName}
                                </option>
                            );
                        }
                    })}
                {props.name.includes("Type") && (
                    <option value={1} selected>
                        {(props.cellValue !== null && props.cellValue !== undefined )
                            ? list && list[parseInt(props.cellValue) - 1].name
                            : ""}
                    </option>
                )}
            </select>
            {isInvalidStatus && !props.isClosed && (
                <span className="tooltip" md-direction="top">
                    <strong>Cell empty</strong>
                    <br />
                    Data is missing
                    <br />
                    <i>Improvement: fill in the blanks</i>
                </span>
            )}
            {props.isClosed && (
                <span className="tooltip" md-direction="top">
                    <strong>Enterprise is closed</strong>
                    <br />
                    No data required.
                </span>
            )}
            {conflict && (
                <span>
                    <button onClick={confirmConflict}>
                        <DoneIcon />
                    </button>
                    <button className="clear" onClick={goBackHistory}>
                        <ClearIcon />
                    </button>
                </span>
            )}
            {props.isClosed && (
                <span className="tooltip" md-direction="top">
                    <strong>Enterprise is closed</strong>
                    <br />
                    No data required.
                </span>
            )}
            {conflict && (
                <span className="tooltip" md-direction="top">
                    <strong>Potential data conflict</strong>
                    <br />
                    This data conflicts with the previously value:{" "}
                    {props.historical}.<br />
                    <i>
                        Please confirm the change, or go back to the original
                        number.
                    </i>
                </span>
            )}
        </div>
    );
}

export default CellList;
