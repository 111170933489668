import { Button } from '@mui/material';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useContext } from 'react'
import { AppContext } from '../../context/AppContext';
import './Bar.scss';
import { useNavigate } from 'react-router-dom';

function Bar() {
    const { toggleLoggedUser } = useContext(AppContext);
    let navigate = useNavigate();
    return <main className='adminBarComponent'>
        <span className="md-title">Argidius</span>
        <div className="utils">
            <Button variant="text" onClick={() => { navigate("/data-entry") }}><ArrowBackIcon sx={{ color: "black" }} /></Button>
            <Button variant="text" onClick={() => { toggleLoggedUser(null); navigate("/") }}><ExitToAppIcon sx={{ color: "black" }} /></Button>
        </div>
    </main>

}

export default Bar
