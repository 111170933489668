import { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import PersonIcon from "@mui/icons-material/Person";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import HandshakeIcon from "@mui/icons-material/Handshake";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import GetAppIcon from "@mui/icons-material/GetApp";
import Bar from "../components/admin/Bar";
import { Link, Outlet } from "react-router-dom";
import { ExportExcelDialog } from "./ExportExcelDialog";

const drawerWidth = 200;

interface Props {
    window?: () => Window;
}
export type Project = {
    amountFinanced: any;
    countryId: number;
    countryName: string;
    currencyId?: number;
    currencyName: any;
    endDate: any;
    fiscalYear: number;
    id: number;
    interventionFamilyId: any;
    interventionFamilyName: any;
    interventionGenusId: any;
    interventionGenusName: any;
    name: string;
    partnerId: number;
    partnerName: string;
    prjID: string;
    projectStatus: string;
    regionId: number;
    regionName: string;
    salesForceId: string;
    segmentId: number;
    segmentName: string;
    startDate?: any;
    strategyId?: number;
    strategyName?: string;
};

export default function ResponsiveDrawer(props: Props) {
    const { window } = props;
    const [mobileOpen, setMobileOpen] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [projectList, setProjectList] = useState<Array<Project>>([]);
    const [filteredProjects, setFilteredProjects] = useState<
        Array<Project> | []
    >([]);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawer = (
        <div>
            <List sx={{ a: { textDecoration: "none" } }}>
                <Link to="/admin/users">
                    <ListItem disablePadding>
                        <ListItemButton>
                            <ListItemIcon>
                                <PersonIcon />
                            </ListItemIcon>
                            <ListItemText primary="Users" />
                        </ListItemButton>
                    </ListItem>
                </Link>

                <Link to="/admin/reporting-year">
                    <ListItem disablePadding>
                        <ListItemButton>
                            <ListItemIcon>
                                <EventAvailableIcon />
                            </ListItemIcon>
                            <ListItemText primary="Reporting Year" />
                        </ListItemButton>
                    </ListItem>
                </Link>

                <Link to="/admin/partner">
                    <ListItem disablePadding>
                        <ListItemButton>
                            <ListItemIcon>
                                <HandshakeIcon />
                            </ListItemIcon>
                            <ListItemText primary="Partner" />
                        </ListItemButton>
                    </ListItem>
                </Link>

                <Link to="/admin/project">
                    <ListItem disablePadding>
                        <ListItemButton>
                            <ListItemIcon>
                                <AccountTreeIcon />
                            </ListItemIcon>
                            <ListItemText primary="Project" />
                        </ListItemButton>
                    </ListItem>
                </Link>

                <ListItem disablePadding onClick={() => setOpenDialog(true)}>
                    <ListItemButton>
                        <ListItemIcon>
                            <GetAppIcon />
                        </ListItemIcon>
                        <ListItemText primary="Export Partners" />
                    </ListItemButton>
                </ListItem>
            </List>
            {openDialog && (
                <ExportExcelDialog
                    openDialog={openDialog}
                    setOpenDialog={setOpenDialog}
                    projectList={projectList}
                    setProjectList={setProjectList}
                    filteredProjects={filteredProjects}
                    setFilteredProjects={setFilteredProjects}
                />
            )}
        </div>
    );

    const container =
        window !== undefined ? () => window().document.body : undefined;

    return (
        <Box sx={{ width: "100%" }}>
            <CssBaseline />
            <AppBar
                position="fixed"
                sx={{
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    ml: { sm: `${drawerWidth}px` },
                    backgroundColor: "#F5F5F5",
                }}
            >
                <Toolbar sx={{ width: "100%" }}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: "none" } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Bar />
                </Toolbar>
            </AppBar>
            <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                aria-label="mailbox folders"
            >
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true,
                    }}
                    sx={{
                        display: { xs: "block", sm: "none" },
                        "& .MuiDrawer-paper": {
                            boxSizing: "border-box",
                            width: drawerWidth,
                        },
                    }}
                >
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: "none", sm: "block" },
                        "& .MuiDrawer-paper": {
                            boxSizing: "border-box",
                            width: drawerWidth,
                        },
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    p: 0,
                    width: { sm: `calc(100% - ${drawerWidth}px)`, lg: "100%" },
                    m: 0,
                }}
            >
                <Toolbar />
                <Outlet />
            </Box>
        </Box>
    );
}
