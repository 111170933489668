import ForgotPasswordForm from '../components/user/ForgotPasswordForm';
import logo from '../assets/argidius-logo.jpg';
import logoSteward from '../assets/logo-steward-redqueen.png';
import Feedback from '../components/feedback/Feedback';

function ForgotPassword() {
    return <main id="home">
        <span className="overlay"></span>
        <aside>
            <img src={logo} alt="Logo" />
            <ForgotPasswordForm />
            <img src={logoSteward} alt="Logo Steward Red Queen" />
        </aside>
        <Feedback />
    </main>
}

export default ForgotPassword
