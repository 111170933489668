import { SyntheticEvent, useState } from 'react'
import Idea from './Idea';
import Bug from './Bug';
import FeedbackIcon from '@mui/icons-material/Feedback';
import './Feedback.scss';
import { Modal } from '@mui/material';
import Tab from '@mui/material/Tab';
import { Box } from '@mui/system';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

function Feedback() {
    const [enableModal, setEnableModal] = useState(false);
    const [value, setValue] = useState('1');

    const handleChange = (event: SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    const handleClose = () => setEnableModal(false);

    return <>
        <div className='feedBackContainer'>
            <div className='feedBackIcon' onClick={() => setEnableModal(true)}>
                <FeedbackIcon sx={{ width: '20px', height: '20px', margin: '0.3rem 0.3rem' }} />
            </div>
        </div>
        {enableModal && <Modal
            open={enableModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{ width: '80%', backgroundColor: 'white', margin: '10% 10%', padding: '1rem 1rem' }}>
                <h1>Give us your feedback!</h1>
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChange} aria-label="lab API tabs example">
                            <Tab sx={{ fontWeight: '600' }} label="Add New Idea" value="1" />
                            <Tab sx={{ fontWeight: '600' }} label="Report a bug" value="2" />
                        </TabList>
                    </Box>
                    <TabPanel value="1"><Idea /></TabPanel>
                    <TabPanel value="2"><Bug /></TabPanel>
                </TabContext>
            </Box>
        </Modal>}
    </>
}

export default Feedback
