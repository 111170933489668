import { Button, Card, CardContent } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import './Disclaimer.scss';

function Disclaimer() {

    const navigate = useNavigate();

    return <>
        <main className='disclaimer'>
            <div id="overlay"></div>
            <Card className="cardContainer">
                <Button sx={{ textTransform: "capitalize", position: "absolute", left: '2px', top: '20px', fontSize: '14px' }} variant="text" onClick={() => navigate("/")}>Back</Button>
                <div className="md-title">
                    <h2 className='card-header'>Disclaimer</h2>
                </div>
                <CardContent sx={{ fontSize: "14px", lineHeight: "22px" }}>
                    <h3>Roles</h3>
                    The Argidius Enterprise-Level Data Portal (“Portal”) is developed in partnership between Argidius and Steward Redqueen. Argidius is registered in Switzerland at Grafenauweg 10, CH-6301 Zug. Steward Redqueen is registered in the Netherlands at Teerketelsteeg 1, 1012 TB Amsterdam.<br />
                    Partner means the individual accessing the Portal, or the company, or other legal entity on behalf of which such individual is accessing or using the Portal, as applicable.<br />
                    <hr />
                    <h3>Errors and Omissions</h3>
                    The data and information in the Portal have been compiled with the greatest of care. Nevertheless, we provide no guarantee of its correctness, completeness, or reliability, nor any guarantee that it is up-to-date, and it accepts no liability for losses which may arise from the use of this information.<br />
                    <hr />
                    <h3>Liability</h3>
                    We will not be liable to Partner or anyone else for any decision made or action taken in reliance on the information given by the Portal or for any consequential, special or similar damages, even if advised of the possibility of such damages.<br />
                    <hr />
                    <h3>Duration</h3>
                    The access to Portal is of unlimited duration. It may be terminated by either Party at any time with immediate effect and without need to give reasons. We shall be entitled to block a Partner's access to some or all services without giving its reasons and without advance notice.<br />
                    <hr />
                    <h3>External Links</h3>
                    The Portal may contain links to external websites that are not provided or maintained by or in any way affiliated with us. We do not guarantee the accuracy, relevance, timeliness, or completeness of any information on these external websites.<br />
                    <hr />
                    <h3>Privacy</h3>
                    Privacy of Partner data is governed under the separate Privacy Statement of Porticus Group entities.
                </CardContent>
            </Card>
        </main>
    </>
}

export default Disclaimer
