import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Alert, Box, Button, IconButton, Input, InputAdornment, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { Stack } from '@mui/system';
import axios from 'axios';
import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { UserRole } from '../../../context/AppContext';
import { API_URI } from '../../../util/urls';


type Form = {
    userRole: UserRole,
    partnerId?: number,
    partnerName?: string,
    name: string,
    email: string,
    password?: string,
    showPassword?: boolean,
    uid?: number
}

type Partners = [Form]

const emptyFormState = {
    userRole: 'User' as UserRole,
    partnerId: undefined,
    partnerName: undefined,
    name: '',
    email: '',
    password: undefined,
    showPassword: false,
}

function UserForm() {

    const [form, setForm] = useState<Form>(emptyFormState);
    const [message, setMessage] = useState('');
    const [partners, setPartners] = useState<Partners>([emptyFormState]);

    const { id } = useParams();
    const location = useLocation();
    let navigate = useNavigate();

    const updateUser = () => {
        if (form.password && form.password.length < 8) {
            setMessage("Password needs to be at least 8 characters.")
        }
        else if (form.email.length === 0 || form.name.length === 0 || form.userRole.length === 0) {
            setMessage("One or more user information do not require min requirements")
        }
        else {
            if (!location.pathname.includes("create")) {
                if (id) {
                    axios
                        .put(`${API_URI}/users/${id}/update`, { password: form.password, email: form.email, name: form.name, userRole: form.userRole, partnerId: form.partnerId })
                        .then(() => {
                            setMessage("User updated");
                            navigate('/admin/users')
                        })
                        .catch(() => {
                            setMessage("Something went wrong, try again later.");
                        });
                }
            }

            if (location.pathname.includes("create")) {
                axios
                    .post(`${API_URI}/users/add`, { userRole: "User", password: form.password, email: form.email, name: form.name, partnerId: form.partnerId })
                    .then(() => {
                        setMessage("User successfully created.");
                        setForm(emptyFormState)
                    })
                    .catch((error) => {
                        setMessage(error.response.data)
                    });
            }
        }
    }

    useEffect(() => {
        fetch(`${API_URI}/partner/all`)
            .then(async response => {
                const dataPartners = await response.json()
                setPartners(dataPartners);
            })
        if (id && id.length > 0) {
            axios
                .get(`${API_URI}/users/all`)
                .then(response => {
                    const user = response.data.filter((item: any) => item.id === parseInt(id));
                    setForm({
                        "email": user[0].email,
                        "name": user[0].name,
                        "userRole": user[0].userRole,
                        "partnerName": user[0].partnerName,
                        "partnerId": parseInt(user[0].partnerId)
                    })
                })
        }
    }, [id])

    useEffect(() => {
        if (message && message.includes("successfully")) {
            const timer = setTimeout(() => {
                navigate("/admin/users")
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [message, navigate])

    return <>
        <Box component="form" onSubmit={(e) => e.preventDefault()} >
            <TextField
                label="Name"
                id="name"
                sx={{ m: 1, width: '100%' }}
                name="name"
                variant="standard"
                type="text"
                required
                value={form.name}
                onChange={(e) => setForm({ ...form, name: e.target.value })}
            />
            <TextField
                label="Email"
                id="email"
                sx={{ m: 1, width: '100%' }}
                name="email"
                variant="standard"
                type="email"
                required
                value={form.email}
                onChange={(e) => setForm({ ...form, email: e.target.value })}
            />
            <FormControl sx={{ m: 1, width: '100%' }} variant="standard">
                <InputLabel htmlFor="password">Password </InputLabel>
                <Input
                    id="password"
                    type={form.showPassword ? 'text' : 'password'}
                    value={form.password}
                    name="password"
                    onChange={(e) => { setForm({ ...form, password: e.target.value }) }}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setForm({ ...form, showPassword: !form.showPassword })}
                            >
                                {form.showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    }
                />
            </FormControl>
            <FormControl sx={{ m: 1, width: '100%' }} variant="standard">
                <InputLabel id="partnerId">Partner</InputLabel>
                <Select
                    id="partnerId"
                    /*   @ts-ignore */
                    value={parseInt(form.partnerId)}
                    disabled={form.userRole === "Admin"}
                    onChange={(e) => setForm({ ...form, partnerId: e.target.value as number })}
                    label="Select Partner"
                >
                    {partners.map((partner: Form, i: number) => <MenuItem key={i} value={partner.uid}>{partner.name}</MenuItem>)}
                </Select>
            </FormControl>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="role">Role</InputLabel>
                <Select
                    labelId="role"
                    id="role"
                    value={form.userRole}
                    onChange={(e) => setForm({ ...form, userRole: e.target.value as UserRole })}
                    label="Role"
                >
                    <MenuItem value="User">User</MenuItem>
                    <MenuItem value="Admin">Admin</MenuItem>
                </Select>
            </FormControl>
            {message.length > 1 && <Stack sx={{ m: 1 }} spacing={2}>
                <Alert severity={message.includes("successfully") ? "success" : "error"} onClose={() => setMessage("")}>{message}</Alert>
            </Stack>}
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button
                    onClick={() => navigate('/admin/users')}
                    fullWidth
                    variant="contained"
                    sx={{ backgroundColor: 'white', color: 'black', width: '100px', '&:hover': { backgroundColor: '#ddd' } }}>
                    Back
                </Button>
                <Button
                    onClick={updateUser}
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ width: '120px' }}>
                    {location.pathname.includes("create") ? "Create" : "Update"}
                </Button>
            </Box>
        </Box>
    </>
}

export default UserForm
