import React, { useCallback, useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";
import { Box, Modal, TextField } from "@mui/material";

type Props = {
    editMode: boolean;
    title: string;
    id: number;
    row: number;
    cellValue: string;
    enterpriseId: number;
    name: string;
    isClosed: boolean;
    isCompleted: boolean;
    isInvalid: boolean;
    isValid: boolean;
    historical?: any;
    isPurple?: boolean;
    enterprise?: string;
};

function computeCellStyle(
    props: Props,
    isCompletedValue: boolean | undefined,
    conflict: boolean
) {
    return [
        props.isPurple
            ? "purple"
            : props.isClosed || isCompletedValue
            ? "out-scope"
            : conflict
            ? "warning"
            : props.isInvalid
            ? "invalid"
            : props.isValid
            ? "valid"
            : "",
        "cell",
    ].join(" ");
}

function CellInput(props: Props) {
    const { updateCell } = useContext(AppContext);
    const [enableModal, setEnableModal] = useState(false);
    const [inputValue, setInputValue] = useState("");
    const [projectUpdate] = useState("");
    const [conflict, setConflict] = useState(false);
    const [timeout, setTimeout] = useState(undefined);
    const [isCompletedValue, setIsCompletedValue] = useState<
        boolean | undefined
    >(undefined);

    const style = computeCellStyle(props, isCompletedValue, conflict);
    const handleClose = useCallback(
        () => setEnableModal(false),
        [setEnableModal]
    );

    const confirmConflict = () => {
        setConflict(false);
        props.isValid = true;
    };

    const goBackHistory = (e: any) => {
        setConflict(false);
        props.isValid = true;
        validate(e);
    };
    const validate = (e: any) => {
        if (e.keyCode === 13) e.target.blur();
        setInputValue(e.target.value);
        if (timeout) clearTimeout(timeout);
        setTimeout(() => {
            updateCell({ row: props.row, id: props.id, value: inputValue });
            //@ts-ignore
        }, 500);
    };

    useEffect(() => {
        if (typeof props.cellValue !== "object") {
            setInputValue(props.cellValue);
        }
        if (inputValue && props.historical && props.historical !== inputValue) {
            setConflict(true);
        }
        setIsCompletedValue(props.isCompleted);
    }, [inputValue, props.cellValue, props.historical, props.isCompleted]);

    return (
        <div className={style}>
            <input
                type="text"
                readOnly={props.isClosed || !props.editMode}
                value={inputValue}
                onKeyUp={(e: any) => validate(e)}
            />
            {props.isInvalid && (
                <span className="tooltip" md-direction="top">
                    <strong>Cell empty</strong>
                    <br />
                    Data is missing
                    <br />
                    <i>Improvement: fill in the blanks</i>
                </span>
            )}
            {conflict && (
                <span>
                    <button onClick={confirmConflict}>
                        <DoneIcon />
                    </button>
                    <button className="clear" onClick={goBackHistory}>
                        <ClearIcon />
                    </button>
                </span>
            )}
            {props.isClosed && (
                <span className="tooltip" md-direction="top">
                    <strong>Enterprise is closed</strong>
                    <br />
                    No data required.
                </span>
            )}
            {conflict && (
                <span className="tooltip" md-direction="top">
                    <strong>Potential data conflict</strong>
                    <br />
                    This data conflicts with the previously value:{" "}
                    {props.historical}.<br />
                    <i>
                        Please confirm the change, or go back to the original
                        number.
                    </i>
                </span>
            )}
            {props.name === "Partner Project ID" && (
                <Modal
                    open={enableModal}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box
                        sx={{
                            width: "80%",
                            backgroundColor: "white",
                            margin: "10% 10%",
                            padding: "1rem 1rem",
                        }}
                    >
                        <h1>Argidius - Project id change</h1>
                        <div className="project-update">
                            <p>
                                This is where you can request a project Id
                                change for {props.enterprise}
                            </p>
                            <div className="md-layout">
                                <div className="md-layout-item">
                                    <TextField
                                        label="Old project Id"
                                        id="identifier"
                                        name="identifier"
                                        variant="standard"
                                        type="text"
                                        disabled
                                        value={"inputValue"}
                                    />
                                </div>
                                <div className="md-layout-item">
                                    <TextField
                                        label="New project Id"
                                        id="newProject"
                                        variant="standard"
                                        type="text"
                                        value={projectUpdate}
                                    />
                                </div>
                            </div>
                        </div>
                    </Box>
                </Modal>
            )}
        </div>
    );
}

export default CellInput;
