import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../context/AppContext';
import './Utils.scss';

function Utils() {
    const [purpleCellWarning, setPurpleCellWarning] = useState(false);
    const { enterpriseReports } = useContext(AppContext)

    useEffect(() => {
        //@ts-ignore
        setPurpleCellWarning(enterpriseReports.some(item => !item.entOrgUID))
    }, [enterpriseReports]);

    return <>
        <div className="holder">
            {!purpleCellWarning && <span className="quality" >
            </span>}
            <span className="quality">
                <span>
                    {purpleCellWarning && <p >
                        <span style={{ fontWeight: "bold" }}>Attention! Action required for purple cells</span>
                        Please click on the purple cells and select one of the 2 options. This step is necessary for data validation.
                    </p>}
                </span>
            </span>
            {!purpleCellWarning && <div className="quality" >
            </div>}
        </div>
    </>
}

export default Utils
