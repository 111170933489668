import { useCallback, useContext, useEffect, useState } from 'react'
import { AppContext } from '../../context/AppContext';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';

type Props = {
    list: string,
    name: string,
    select: string,
    title: string,
    row: number,
    id: number,
    editMode: boolean,
    enterpriseId: number,
    isValid: boolean,
    isInvalid: boolean,
    isClosed: boolean,
    isCompleted: boolean,
    cellValue: string,
    isPurple?: boolean,
    historical?: any
}

function computeCellStyle(props: Props, conflict: boolean, isInvalidStatus: boolean, isValidStatus: boolean) {
    return [props.isPurple ? 'purple' : (props.isClosed || props.isCompleted) ? 'out-scope' : conflict ? 'warning' : isInvalidStatus ? 'invalid' : isValidStatus ? 'valid' : '', 'cell'];
}

function CellListBool(props: Props) {
    const { selects, updateCell } = useContext(AppContext);
    const [inputValue, setInputValue] = useState('');
    const [conflict, setConflict] = useState(false);
    const [isValidStatus, setIsValidStatus] = useState(false);
    const [isInvalidStatus, setIsInvalidStatus] = useState(false);
    const [list, setList] = useState(undefined);
    const style = computeCellStyle(props, conflict, isInvalidStatus, isValidStatus).join(' ');

    const getStatusCell = useCallback(() => {
        if (!inputValue || !list)
            return;
        //@ts-ignore
        const hasOption = list.filter(item => item.uid === inputValue)
        if (inputValue && hasOption[0]) {
            setIsValidStatus(true);
            setIsInvalidStatus(false);
        }
        if ((inputValue && props.historical) && props.historical !== inputValue)
            setConflict(true);
    }, [inputValue, list, props.historical])

    const getStatus = useCallback(() => {
        //@ts-ignore
        const listTmp = selects.filter(item => item.name === props.select)
        if (listTmp[0])
            setList(listTmp[0].data);
        setInputValue(props.cellValue);
        getStatusCell();
    }, [getStatusCell, props.cellValue, selects, props.select])

    const save = () => {
        if (inputValue.length > 0) {
            setIsValidStatus(true);
            setIsInvalidStatus(false);
        } else {
            setIsValidStatus(false);
            setIsInvalidStatus(true);
        }
        updateCell({ 'row': props.row, 'id': props.id, 'value': inputValue })
    }

    const confirmConflict = () => {
        setConflict(false);
        props.isValid = true;
    }

    const goBackHistory = () => {
        setConflict(false);
        props.isValid = true;
        setInputValue(props.historical);
    }

    useEffect(() => {
        getStatus()
    }, [getStatus])

    return (
        <div className={style}>

            <select defaultValue={inputValue} disabled={true} onChange={save}>
                <option value="true">Yes</option>
                <option value="false">No</option>
            </select>

            {isInvalidStatus && !props.isClosed && <span className="tooltip" md-direction="top">
                <strong>Cell empty</strong><br />
                Data is missing<br />
                <i>Improvement: fill in the blanks</i>
            </span>}

            {props.isClosed && <span className="tooltip" md-direction="top">
                <strong>Enterprise is closed</strong><br />
                No data required.
            </span>}

            {conflict && <span>
                <button onClick={confirmConflict}>
                    <DoneIcon />
                </button>
                <button className="clear" onClick={goBackHistory}>
                    <ClearIcon />
                </button>
            </span>}

            {props.isClosed && <span className="tooltip" md-direction="top">
                <strong>Enterprise is closed</strong><br />
                No data required.
            </span>}

            {conflict && <span className="tooltip" md-direction="top">
                <strong>Potential data conflict</strong><br />
                This data conflicts with the previously value: {props.historical}.<br />
                <i>Please confirm the change, or go back to the original number.</i>
            </span>}



        </div>
    )
}

export default CellListBool

