import Feedback from '../components/feedback/Feedback'
import { LoginForm } from '../components/user/LoginForm'
import logo from '../assets/argidius-logo.jpg';
import logoSteward from '../assets/logo-steward-redqueen.png';

const Home = () => {
    return <>
        <main id="home">
            <span className="overlay"></span>
            <aside>
                <img src={logo} alt="Logo" />
                <LoginForm />
                <img src={logoSteward} alt="Logo Steward Red Queen" />
            </aside>
            <Feedback />
        </main>
    </>
}

export default Home
