import { Breadcrumbs } from '@mui/material'
import { Link } from 'react-router-dom'
import UserForm from '../../../components/admin/user/Form'

function Form() {

    const style = {
        color: 'black',
        fontSize: '14px',
        textDecoration: 'none',
    }

    return <>
        <Breadcrumbs aria-label="breadcrumb">
            <Link style={style} to="/data-entry">
                Home
            </Link>
            <Link style={style} to="/admin/users">
                Users
            </Link>
            <Link style={style} to="#">
                Form
            </Link>
        </Breadcrumbs>
        <UserForm />
    </>
}

export default Form
