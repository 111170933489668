import { useEffect, useState } from 'react'
import DoneIcon from '@mui/icons-material/Done';

type Props = {
    editMode: boolean,
    title: string,
    id: number,
    row: number,
    cellValue: string,
    enterpriseId: number,
    name: string,
    isClosed: boolean,
    isCompleted: boolean,
    isInvalid: boolean,
    isValid: boolean,
    baselineYear: string,
    baselineValue: boolean,
    isOutScope: boolean,
    typeError: string,
    hasSpace: boolean,
    isPurple?: boolean
}

function computeCellStyle(props: Props, isValidYear: boolean) {
    return [props.isPurple ? 'purple' : (props.isClosed || props.isCompleted) ? 'out-scope' : !isValidYear ? 'warning' : props.isInvalid ? 'invalid' : props.isValid ? 'valid' : '', 'cell'];
}

function CellBaseline(props: Props) {
    const [inputValue, setInputValue] = useState('');
    const [isValidYear, setIsValidYear] = useState(true);

    const style = computeCellStyle(props, isValidYear).join(' ')

    const confirmValidYear = () => {
        setIsValidYear(true);
        props.isValid = true;
    }

    useEffect(() => {
        if (typeof (props.cellValue) !== 'object')
            setInputValue(props.cellValue);
        setIsValidYear(props.baselineValue)

    }, [props.baselineValue, props.cellValue])

    return (
        <div className={style}>
            <input type="text" value={inputValue} disabled={props.isClosed || !props.editMode} />
            {(!props.isClosed && !props.isCompleted) && !isValidYear && <span>
                <button onClick={confirmValidYear}>
                    <DoneIcon />
                </button>
            </span>}
            {props.isInvalid && <span className="tooltip" md-direction="top">
                <span v-if="inputValue.length < 1">
                    <strong>Cell empty</strong><br />
                    Data is missing<br />
                    <i>Improvement: fill in the blanks</i>
                </span>
            </span>}
            {(!props.isClosed && !props.isCompleted) && !isValidYear && <span className="tooltip" md-direction="top" v-if="">
                <span>
                    <strong>Potential error in baseline year</strong><br />
                    The value entered is potentially incorrect as it differs from the intervention start year.<br />
                    <i>Improvement: Double check the figure.</i>
                </span>
            </span>}



        </div>
    )
}

export default CellBaseline

