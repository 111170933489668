import {
    useState,
    createContext,
    useEffect,
    useCallback,
    useContext,
} from "react";
import type { Year } from "../pages/admin/reporting-year/ReportingYear";
import { API_URI } from "../util/urls";
import { AppContext } from "./AppContext";
import type { ReportingState } from "./AppContext";

type Partner = {
    id: number;
    active: boolean;
    countryId: number;
    countryName: string;
    name: string;
    regionId: number;
    regionName: string;
    reportingState: ReportingState;
    saleForceId: string;
};

export type FilteredPartners = {
    active: string;
    countryId?: any;
    countryName: null | string;
    id: number;
    name: string;
    regionId: any;
    regionName: null | string;
    reportingState?: ReportingState;
    salesForceId: string;
};

type Country = {
    id: number;
    name: string;
};

type Region = {
    id: number;
    name: string;
};

type Segment = {
    id: number;
    name: string;
};

type Strategy = {
    id: number;
    name: string;
};

type Interventionfamilies = {
    id: number;
    name: string;
};

type InterventionGenuses = {
    id: number;
    name: string;
};

type Currency = {
    id: number;
    name: string;
};

export type AdminContextType = {
    activePartnerList: Array<Partner>;
    setActivePartnerList: (partner: Array<Partner>) => void;
    countries: Array<Country>;
    setCountries: (country: Array<Country>) => void;
    currencies: Array<Currency>;
    setCurrencies: (c: Array<Currency>) => void;
    getAllPartnerData: () => void;
    interventionfamilies: Array<Interventionfamilies>;
    setInterventionFamilies: (f: Array<Interventionfamilies>) => void;
    interventionGenuses: Array<InterventionGenuses>;
    setInterventionGenuses: (f: Array<InterventionGenuses>) => void;
    partnerList: Array<FilteredPartners>;
    setPartnerList: (partner: Array<FilteredPartners>) => void;
    regions: Array<Region>;
    setRegions: (region: Array<Region>) => void;
    segments: Array<Segment>;
    setSegments: (segment: Array<Segment>) => void;
    strategies: Array<Strategy>;
    setStrategies: (strategy: Array<Strategy>) => void;
    isLoading: boolean;
    setIsLoading: (isLoading: boolean) => void;
    projectList: any;
    setProjectList: (projectList: any) => void;
    getAllProjects: () => void;
};

export const AdminContext = createContext<AdminContextType | null>(
    null
) as React.Context<AdminContextType>;

const AdminContextProvider = (props: any) => {
    const { reportingYearsList } = useContext(AppContext);

    const [countries, setCountries] = useState<Array<Country>>([]);
    const [regions, setRegions] = useState<Array<Region>>([]);
    const [activePartnerList, setActivePartnerList] = useState<Array<Partner>>(
        []
    );
    const [partnerList, setPartnerList] = useState<Array<FilteredPartners>>([]);
    const [segments, setSegments] = useState<Array<Segment>>([]);
    const [strategies, setStrategies] = useState<Array<Strategy>>([]);
    const [interventionfamilies, setInterventionFamilies] = useState<
        Array<Interventionfamilies>
    >([]);
    const [interventionGenuses, setInterventionGenuses] = useState<
        Array<InterventionGenuses>
    >([]);
    const [isLoading, setIsLoading] = useState(false);
    const [currencies, setCurrencies] = useState<Array<Currency>>([]);

    const [projectList, setProjectList] = useState([]);

    const getCountryOptions = useCallback(() => {
        fetch(`${API_URI}/masterdata/country/all`)
            .then((response) => response.json())
            .then((data) => setCountries(data));
    }, []);

    const getRegionOptions = useCallback(() => {
        fetch(`${API_URI}/masterdata/region/all`)
            .then((response) => response.json())
            .then((data) => setRegions(data));
    }, []);

    useEffect(() => {
        getCountryOptions();
        getRegionOptions();
    }, [getCountryOptions, getRegionOptions]);

    const getActiveReportingYearId = (reportingYearsList: Array<Year>) => {
        return reportingYearsList.find((y) => y.active === true)?.id;
    };

    const reportingYearId = getActiveReportingYearId(reportingYearsList);

    const getActivePartnerData = useCallback(() => {
        if (reportingYearId) {
            setIsLoading(true);
            fetch(`${API_URI}/admin/partner/${reportingYearId}/all`)
                .then((response) => response.json())
                .then((data: Array<Partner>) => {
                    let activePartners = data.filter((d) => d.active);
                    setActivePartnerList(activePartners);
                    setIsLoading(false);
                })
                .catch((error) => {
                    console.error("Error:", error);
                });
        }
    }, [reportingYearId]);
    const getAllProjects = useCallback(() => {
        fetch(`${API_URI}/admin/project/all`)
            .then((response) => response.json())
            .then((data) => setProjectList(data))
            .catch((error) => console.log(error));
    }, []);

    useEffect(() => {
        getActivePartnerData();
    }, [getActivePartnerData]);

    const getAllPartnerData = useCallback(() => {
        if (reportingYearId) {
            fetch(
                `${API_URI}/admin/partner/${reportingYearId}/all?includeAll=true`
            )
                .then((response) => response.json())
                .then((data) => {
                    setPartnerList(data);
                })
                .catch((error) => {
                    console.error("Error:", error);
                });
        }
    }, [reportingYearId]);

    useEffect(() => {
        getAllPartnerData();
    }, [getAllPartnerData]);

    const getSegmentsData = useCallback(() => {
        fetch(`${API_URI}/masterdata/segment/all`)
            .then((response) => response.json())
            .then((data) => setSegments(data));
    }, []);

    useEffect(() => {
        getSegmentsData();
    }, [getSegmentsData]);

    const getStrategyData = useCallback(() => {
        fetch(`${API_URI}/masterdata/strategy/all`)
            .then((response) => response.json())
            .then((data) => setStrategies(data));
    }, []);

    useEffect(() => {
        getStrategyData();
    }, [getStrategyData]);

    const getInterventionfamilyData = useCallback(() => {
        fetch(`${API_URI}/masterdata/interventionfamily/all`)
            .then((response) => response.json())
            .then((data) => setInterventionFamilies(data));
    }, []);

    useEffect(() => {
        getInterventionfamilyData();
    }, [getInterventionfamilyData]);

    const getInterventionGenusData = useCallback(() => {
        fetch(`${API_URI}/masterdata/interventiongenus/all`)
            .then((response) => response.json())
            .then((data) => setInterventionGenuses(data));
    }, []);

    useEffect(() => {
        getInterventionGenusData();
    }, [getInterventionGenusData]);

    const getCurrencyData = useCallback(() => {
        fetch(`${API_URI}/masterdata/currency/all`)
            .then((response) => response.json())
            .then((data) => setCurrencies(data));
    }, []);

    useEffect(() => {
        getCurrencyData();
    }, [getCurrencyData]);

    useEffect(() => {
        getAllProjects();
    }, [getAllProjects]);
    return (
        <AdminContext.Provider
            value={{
                countries,
                setCountries,
                regions,
                setRegions,
                activePartnerList,
                setActivePartnerList,
                partnerList,
                setPartnerList,
                segments,
                setSegments,
                strategies,
                setStrategies,
                interventionfamilies,
                setInterventionFamilies,
                interventionGenuses,
                setInterventionGenuses,
                isLoading,
                setIsLoading,
                currencies,
                setCurrencies,
                getAllPartnerData,
                projectList,
                setProjectList,
                getAllProjects,
            }}
        >
            {props.children}
        </AdminContext.Provider>
    );
};

export default AdminContextProvider;
