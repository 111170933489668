import { Alert, Breadcrumbs, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material';
import { Box } from '@mui/system';
import { Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { useContext, useEffect, useState } from 'react';
import CreatePartnerForm from './CreatePartnerForm';
import { AppContext } from '../../../context/AppContext';
import { API_URI } from '../../../util/urls';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { AdminContext } from '../../../context/AdminContext';
import { EditPartnerForm } from './EditPartnerForm';

import type { Year } from '../reporting-year/ReportingYear';
import type { ReportingState } from '../../../context/AppContext';
import type { FilteredPartners } from '../../../context/AdminContext';
import AlertDialog from '../../../widgets/AlertDialog';
import { PartnerSearch } from '../../../widgets/PartnerSearch';

export type Form = {
    name?: string,
    active?: string | undefined,
    salesForceId?: string,
    countryId?: any,
    regionId?: any,
    reportingState?: ReportingState,
}

const partnerStates = [
    "NotStarted",
    "Open",
    "Closed",
    "Locked"
];

function Partner() {
    const { reportingYearsList } = useContext(AppContext);
    const { partnerList, getAllPartnerData } = useContext(AdminContext)

    const [isOpen, setIsOpen] = useState(false);
    const [openEditForm, setOpenEditForm] = useState(false);
    const [editFormId, setEditFormId] = useState<undefined | number>(undefined);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [form, setForm] = useState<Form>({})
    const [filteredPartnerLists, setFilteredPartnerLists] = useState<Array<FilteredPartners>>([])
    const [notificationOpen, setNotificationOpen] = useState(false)
    const [message, setMessage] = useState("")

    const style = {
        color: 'black',
        fontSize: '14px',
        textDecoration: 'none'
    }

    const getPreviousAndNextPartnerState = (reportingState: string, step: "prev" | "next") => {
        return step === "prev" ? partnerStates[partnerStates.indexOf(reportingState) - 1] : partnerStates[partnerStates.indexOf(reportingState) + 1];
    }

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const columns = ["Id", "Name", "Status", "Partner SaleForce Id", "Country Name", "Region Name", "Reporting State", "", ""]

    const getActiveReportingYearState = (reportingYearsList: Array<Year>) => {
        return reportingYearsList.find(y => y.active === true)?.reportingState
    }

    const activeYearState = getActiveReportingYearState(reportingYearsList)
    const defaultPartnerStateIndex = activeYearState && partnerStates.indexOf(activeYearState);

    useEffect(() => {
        setFilteredPartnerLists(partnerList)
    }, [partnerList])

    const handleDeletePartner = (partnerId: number) => {
        fetch(`${API_URI}/admin/partner/${partnerId}/delete`, { method: "DELETE" })
            .then(async (response) => {
                if (response.ok) {
                    setMessage("Partner Successfully Deleted")
                    getAllPartnerData()
                }
                else {
                    const errorMessage = await response.text()
                    setMessage(errorMessage)
                }
                setNotificationOpen(false)
            })
    }

    return <main id='muiItemsContainer'>
        <Breadcrumbs aria-label="breadcrumb">
            <Link style={style} to="/data-entry">
                Home
            </Link>

            <Link style={style} to="/admin/partner">
                Partner
            </Link>
        </Breadcrumbs>
        <Box
            sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", mt: 1 }}
            onClick={() => setIsOpen(true)}>
            Add new Partner
            <AddIcon
                sx={{ color: 'green', fontSize: '40px', cursor: "pointer" }}
            />
        </Box>
        <Box sx={{ mr: 3, mb: 1 }}>
            <PartnerSearch
                partnerList={partnerList}
                setFilteredPartnerLists={setFilteredPartnerLists}
                setPage={setPage}
            />
        </Box>
        <Box sx={{ mr: 3 }}>
            <TableContainer component={Paper}>
                <Table
                    sx={{ minWidth: 650, textAlign: 'left' }}
                    size="small"
                    aria-label="a dense table">
                    <TableHead>
                        <TableRow sx={{ backgroundColor: "#A4BEC0" }}>
                            {columns.map((c, i) => <TableCell
                                key={i}
                                sx={{ fontWeight: "600", color: "white" }}>
                                {c}
                            </TableCell>)}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredPartnerLists.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row: FilteredPartners) => (
                            <TableRow
                                key={row.id}
                                sx={{ "&:last-child td, &:last-child th": { border: 0 }, transition: 'ease all .5s', "&:hover": { backgroundColor: '#F5F5F5' } }}
                            >
                                {(openEditForm && row.id === editFormId) && <EditPartnerForm
                                    setForm={setForm}
                                    row={row}
                                    form={form}
                                    defaultPartnerStateIndex={defaultPartnerStateIndex}
                                    partnerStates={partnerStates}
                                    getPreviousAndNextPartnerState={getPreviousAndNextPartnerState}
                                    setOpenEditForm={setOpenEditForm}
                                    setMessage={setMessage}
                                    editFormId={editFormId}
                                />}
                                {(!openEditForm) && <>
                                    <TableCell align="left"> {row.id}  </TableCell>
                                    <TableCell align="left">{row.name}</TableCell>
                                    <TableCell align="left">{row.active ? "Active" : "Inactive"}</TableCell>
                                    <TableCell align="left">{row.salesForceId}</TableCell>
                                    <TableCell align="left">{row.countryName}</TableCell>
                                    <TableCell align="left">{row.regionName}</TableCell>
                                    <TableCell align="left">{row.reportingState}</TableCell>
                                    <TableCell align="right"><EditIcon onClick={() => {
                                        setOpenEditForm(true)
                                        setEditFormId(row.id)
                                        setForm({
                                            "name": row.name,
                                            "active": row.active,
                                            "salesForceId": row.salesForceId,
                                            "countryId": row.countryId,
                                            "regionId": row.regionId,
                                            "reportingState": row.reportingState
                                        })
                                    }} sx={{ color: "blue", cursor: "pointer" }} /></TableCell>
                                    <TableCell align="right"><DeleteIcon onClick={() => {
                                        setNotificationOpen(true)
                                        setEditFormId(row.id)

                                    }} sx={{ color: "red", cursor: "pointer" }} /></TableCell>
                                </>}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    component="div"
                    count={partnerList.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </TableContainer>
            {message.length > 0 && <Alert
                severity={message.includes("Successfully") ? "success" : "error"}
                onClose={() => setMessage("")}>
                {message}
            </Alert>}
        </Box>
        <AlertDialog
            isOpen={notificationOpen}
            setIsOpen={setNotificationOpen}
            modalTitle=" Are you sure you want to delete partner ?"
            modalContent=" Selected partner will be deleted."
            handleAgree={() => { editFormId && handleDeletePartner(editFormId) }}
        />
        {isOpen && <CreatePartnerForm
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            setMessage={setMessage}
        />}
    </main>
}

export default Partner
