import React, { useCallback, useEffect, useState } from 'react'
type Props = {
    list: string,
    name: string,
    title: string,
    row: number,
    id: number,
    isPurple: boolean,
    enterprise: string,
    enterpriseId: number,
    isValid: boolean,
    isInvalid: boolean,
    isClosed: boolean,
    isCompleted: boolean,
    cellValue: string,
    prjName: string,
    isDuplicate?: boolean,
    historical?: any,
    editMode?: boolean,
    toggleModalEnt: any
}

function computeCellStyle(props: Props, conflict: boolean) {
    return [props.isPurple ? 'purple' : (props.isClosed || props.isCompleted) ? 'out-scope' : conflict ? 'warning' : props.isDuplicate ? 'warning' : props.isInvalid ? 'invalid' : props.isValid ? 'valid' : '', 'cell'].join(' ');
}

function CellProject(props: Props) {
    const [conflict, setConflict] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const style = computeCellStyle(props, conflict)

    const getData = useCallback(() => {
        setInputValue(props.prjName);
        if ((inputValue && props.historical) && props.historical !== inputValue)
            setConflict(true);
    }, [inputValue, props.historical, props.prjName])

    useEffect(() => {
        getData()
    }, [getData])

    return <div className={style}>
        <input
            type="text"
            readOnly={props.isClosed || !props.editMode}
            value={inputValue}
            style={{ cursor: 'pointer' }}
            onClick={() => props.toggleModalEnt()}
        />

        {props.isClosed && <span className="tooltip" md-direction="top">
            <strong>Enterprise is closed</strong><br />
            No data required.
        </span>}
    </div>
}

export default CellProject;
