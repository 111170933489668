import { IconButton, InputBase, Paper } from "@mui/material"
import { Box } from "@mui/system"
import SearchIcon from '@mui/icons-material/Search';

export const PartnerSearch = (props: any) => {
    const { partnerList, setFilteredPartnerLists, setPage } = props

    const filterData = (searchParam: string) => {
        setPage(0)
        if (searchParam === "") {
            setFilteredPartnerLists(partnerList)
        }
        else {
            // @ts-ignore
            let filteredPartners = partnerList.filter(partner => partner.name.toLowerCase().includes(searchParam.toLowerCase()))
            setFilteredPartnerLists(filteredPartners)
        }
    }

    return <Box >
        <Paper
            component="form"
            sx={{ display: 'flex', alignItems: 'center', width: "100%" }}
        >
            <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search partner"
                inputProps={{ 'aria-label': 'Search partner' }}
                onChange={(e) => filterData(e.target.value)}
            />
            <IconButton
                type="button"
                sx={{ p: '10px' }}
                aria-label="search">
                <SearchIcon />
            </IconButton>
        </Paper>
    </Box>
}