import {
    createBrowserRouter, Outlet,
} from "react-router-dom";
import Admin from '../pages/Admin';
import Form from '../pages/admin/user/Form';
import Grid from '../pages/admin/user/Grid';
import DataEntry from '../pages/DataEntry';
import Disclaimer from '../pages/Disclaimer';
import ForgotPassword from '../pages/ForgotPassword';
import Home from '../pages/Home';
import CreatePassword from '../pages/CreatePassword';
import AppContextProvider from '../context/AppContext';
import ReportingYear from '../pages/admin/reporting-year/ReportingYear';
import Partner from '../pages/admin/partner/Partner';
import Project from '../pages/admin/project/Project';
import ErrorPage from "../widgets/ErrorPage";
import AdminContextProvider from "../context/AdminContext";

export const router = createBrowserRouter(
    [{
        path: "/forgot-password",
        element: <ForgotPassword />,
    },
    {
        path: "/disclaimer",
        element: <Disclaimer />,
    },
    {
        path: "/create-password/:hash",
        element: <CreatePassword />,
    },
    {
        path: "/",
        element: <AppContextProvider><Outlet /></AppContextProvider>,
        errorElement: <ErrorPage />,
        children: [
            {
                path: "/",
                element: <Home />,
            }, {
                path: "/data-entry",
                element: <DataEntry />,
            },
            //Admin and Sub Routes
            {
                path: "/admin",
                element: <AdminContextProvider><Admin /></AdminContextProvider>,
                children: [{
                    path: "users",
                    element: <Grid />,
                }, {
                    path: "users/id/:id",
                    element: <Form />,
                },
                {
                    path: "users/create",
                    element: <Form />,
                },
                {
                    path: "reporting-year",
                    element: <ReportingYear />,
                },
                {
                    path: "partner",
                    element: <Partner />,
                },
                {
                    path: "project",
                    element: <Project />,
                },
                ]
            },
        ]
    }]
);
