import { User } from '../context/AppContext';

export function isReportingYearClosed(user: User | null) {
    if (user && user.userRole === "User" && user.reportingState !== "Open") {
        return true
    }
    else {
        return false
    }
}
