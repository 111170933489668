import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useState } from 'react';
import { Box } from '@mui/system';

export default function MuiDatePicker(props: any) {
    const [value, setValue] = useState(null);
    const { setDate, name, form } = props

    return (
        <Box sx={{ mt: 2 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs} >
                <DatePicker
                    label={name}
                    value={value}
                    onChange={(newValue) => {
                        //@ts-ignore
                        let convertedDate = new Date(newValue);
                        let newDate = (convertedDate.getDate() < 10 ? ("0" + convertedDate.getDate()) : convertedDate.getDate()) + "/" + ((convertedDate.getMonth() < 10 ? "0" + (convertedDate.getMonth() + 1) : convertedDate.getMonth() + 1)) + "/" + convertedDate.getFullYear()
                        setValue(newValue)
                        setDate({ ...form, [name]: newDate });
                    }}
                    renderInput={(params) => <TextField {...params} />}
                />
            </LocalizationProvider>
        </Box>
    );
}