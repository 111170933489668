import { Button, Checkbox, Dialog, DialogTitle, Divider, FormControlLabel, FormGroup, Grid, Stack, Typography } from "@mui/material";
import { ColumnsGroupType, SelectedHeaders } from "./GridHeaders";
import { DataGridHeaders, Header } from "./headers";
import { updateColumnVisibility } from "../../util/data-entry/dataEntryUtils";
import { convertVfmeDate } from "../../util/convertVfmeDate";

type Props = {
    openHeaderDialog: string;
    column: Header;
    handleSelectColumns: (c: ColumnsGroupType, isChecked: boolean) => void;
    selectedHeaders: SelectedHeaders;
    setSelectedHeaders: (selectedHeaders: SelectedHeaders) => void;
    gridData: DataGridHeaders;
    setGridData: (gridData: DataGridHeaders) => void;
    updateDataGridTable: (selectedHeaders: SelectedHeaders) => void;
    previousSelection: Record<string, boolean> | undefined;
};

export const GridHeaderSelectionDialog = ({
    openHeaderDialog,
    column,
    handleSelectColumns,
    selectedHeaders,
    setSelectedHeaders,
    gridData,
    setGridData,
    updateDataGridTable,
    previousSelection,
}: Props) => {
    return (
        <Dialog open={openHeaderDialog === column.header}>
            <DialogTitle>Set columns</DialogTitle>
            <Divider />
            <Stack direction="row" alignItems="center" justifyContent="flex-end" p={1} spacing={1}>
                <Grid
                    item
                    xs={2}
                    sx={{
                        backgroundColor: "#52B998",
                        py: 0.3,
                        px: 0.9,
                        cursor: "pointer",
                    }}
                    borderRadius={1}
                >
                    <Stack direction="row" alignItems="center" justifyContent="space-around" color="#FFFFFF" onClick={() => handleSelectColumns(column, true)}>
                        <Typography variant="caption" fontWeight={600}>
                            Select All
                        </Typography>
                    </Stack>
                </Grid>
                <Grid
                    item
                    xs={2}
                    sx={{
                        backgroundColor: "#ED6C02",
                        py: 0.3,
                        px: 0.9,
                        cursor: "pointer",
                    }}
                    borderRadius={1}
                >
                    <Stack direction="row" alignItems="center" justifyContent="space-around" color="#FFFFFF" onClick={() => handleSelectColumns(column, false)}>
                        <Typography variant="caption" fontWeight={600}>
                            Unselect All
                        </Typography>
                    </Stack>
                </Grid>
            </Stack>
            <FormGroup
                sx={{
                    p: 2,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                {column?.columns.map((sb) => {
                    return (
                        <FormControlLabel
                            key={sb.id}
                            sx={{ width: "48%" }}
                            control={
                                <Checkbox
                                    checked={selectedHeaders[sb.id] || false}
                                    onChange={() => {
                                        const newSelectedHeaders = {
                                            ...selectedHeaders,
                                        };

                                        if (newSelectedHeaders[sb.id]) {
                                            delete newSelectedHeaders[sb.id];
                                        } else {
                                            newSelectedHeaders[sb.id] = true;
                                        }

                                        setSelectedHeaders(newSelectedHeaders);
                                        const updatedDataGridTable = {
                                            ...gridData,
                                        };
                                        updateColumnVisibility(updatedDataGridTable, newSelectedHeaders);

                                        setGridData(updatedDataGridTable);
                                    }}
                                    value={sb.id}
                                    name={sb.header}
                                />
                            }
                            label={convertVfmeDate(sb.header)}
                        />
                    );
                })}
            </FormGroup>
            {/* Buttons for applying and resetting selections */}
            <Stack direction="row" mb={2} ml="auto" mr={2} spacing={2}>
                <Button variant="contained" onClick={() => updateDataGridTable(selectedHeaders)} size="small">
                    Apply Columns
                </Button>

                <Button
                    variant="contained"
                    color="warning"
                    onClick={() => {
                        previousSelection && updateDataGridTable(previousSelection);
                    }}
                    size="small"
                >
                    Reset Changes
                </Button>
            </Stack>
        </Dialog>
    );
};
