import { ColumnsGroupType, SelectedHeaders } from "../../components/data-entry/GridHeaders";
import { DataGridHeaders } from "../../components/data-entry/headers";

export const checkColumnsIfAllUnselectedComplete = (columnName: string, selectedHeaders: SelectedHeaders) => {
    const anySelected = Object.keys(selectedHeaders).some((k) => k.includes(columnName));
    return !anySelected;
};

// Function to update column visibility
export const updateHeaderVisibility = (group: ColumnsGroupType, selectedHeaders: SelectedHeaders) => {
    const checkColumnsIfAllUnselected = (columnName: string) => {
        return checkColumnsIfAllUnselectedComplete(columnName, selectedHeaders);
    };
    if (group.header === "Enterprise-Level Data") {
        if (
            checkColumnsIfAllUnselected("projectUID") &&
            checkColumnsIfAllUnselected("entOrgUID") &&
            checkColumnsIfAllUnselected("reportingStatusUID") &&
            checkColumnsIfAllUnselected("regionID") &&
            checkColumnsIfAllUnselected("countryUID") &&
            checkColumnsIfAllUnselected("sectorUID") &&
            checkColumnsIfAllUnselected("areaUID") &&
            checkColumnsIfAllUnselected("businessStatusUID") &&
            checkColumnsIfAllUnselected("enterpriseStartupYear") &&
            checkColumnsIfAllUnselected("dateFormalized") &&
            checkColumnsIfAllUnselected("missionUID") &&
            checkColumnsIfAllUnselected("partnerServiceLine") &&
            checkColumnsIfAllUnselected("interventionStartDate") &&
            checkColumnsIfAllUnselected("interventionEndDate") &&
            checkColumnsIfAllUnselected("baselineYear")
        ) {
            group.hide = true;
        } else {
            group.hide = false;
        }
    } else if (group.header === "Revenues of Enterprises Assisted") {
        if (checkColumnsIfAllUnselected("revenue")) {
            group.hide = true;
        } else {
            group.hide = false;
        }
    } else if (group.header === "Enterprise Full-Time Employees") {
        if (checkColumnsIfAllUnselected("fullTime")) {
            group.hide = true;
        } else {
            group.hide = false;
        }
    } else if (group.header === "Enterprise Part-Time Employees") {
        if (checkColumnsIfAllUnselected("partTime")) {
            group.hide = true;
        } else {
            group.hide = false;
        }
    } else if (group.header === "Value of Finance Mobilized for Enterprise") {
        if (checkColumnsIfAllUnselected("finance") && checkColumnsIfAllUnselected("seekingExternal")) {
            group.hide = true;
        } else {
            group.hide = false;
        }
    } else if (group.header === "General") {
        if (checkColumnsIfAllUnselected("comments") && checkColumnsIfAllUnselected("gender")) {
            group.hide = true;
        } else {
            group.hide = false;
        }
    }
};

// Function to update column visibility
export const updateColumnVisibility = (dataGridTable: DataGridHeaders, selectedHeaders: Record<string, boolean>) => {
    dataGridTable.columns.forEach((group) => {
        updateHeaderVisibility(group, selectedHeaders);

        group.columns.forEach((column) => {
            if (selectedHeaders[column.id]) {
                column.hide = false;
            } else {
                column.hide = true;
            }
        });
    });
};
