import { IconButton, MenuItem, Select, SelectChangeEvent, TableCell, TextField, Tooltip } from "@mui/material"

import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { useContext } from "react";
import { AdminContext } from "../../../context/AdminContext";
import { projectStatuses } from "./CreateProjectForm";

export const ProjectEditForm = (props: any) => {
    const { row, editFormId, form, setForm, setOpenEditForm, updateProjectForm, partnerList } = props;
    const { countries, regions, currencies, strategies, segments, interventionGenuses, interventionfamilies } = useContext(AdminContext)
    return <>
        <TableCell align="left">
            {row.id}
        </TableCell>
        <TableCell align="left">
            <Select
                sx={{ width: "160px" }}
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                defaultValue={form.partnerId}
                onChange={(e: SelectChangeEvent) => setForm({ ...form, "partnerId": e.target.value })}
                label="countries"
                size='small'
            >
                {/* @ts-ignore */}
                {partnerList.map(p => <MenuItem key={p.id} value={p.id}>{p.name}
                </MenuItem>)}
            </Select>
        </TableCell>
        <TableCell align="left">
            <TextField
                id="standard-size-small"
                defaultValue={form.name}
                size="small"
                variant="standard"
                onChange={(e) => setForm({ ...form, "name": e.target.value })}
            />
        </TableCell>
        <TableCell align="left">
            <TextField
                id="standard-size-small"
                defaultValue={form.prjID}
                size="small"
                variant="standard"
                onChange={(e) => setForm({ ...form, "prjID": e.target.value })}
            />
        </TableCell>
        <TableCell align="left">
            <TextField
                id="standard-size-small"
                defaultValue={form.salesForceId}
                size="small"
                variant="standard"
                onChange={(e) => setForm({ ...form, "salesForceId": e.target.value })}
            />
        </TableCell>
        <TableCell align="left">
            <Select
                sx={{ width: "160px" }}
                labelId="segments"
                id="segments"
                defaultValue={form.segmentId}
                onChange={(e: SelectChangeEvent) => setForm({ ...form, "segmentId": e.target.value })}
                label="segments"
                size='small'
            >
                {/* @ts-ignore */}
                {segments.map(s => <MenuItem key={s.id} value={s.id}>{s.name}
                </MenuItem>)}
            </Select>
        </TableCell>
        <TableCell align="left">
            <Select
                sx={{ width: "160px" }}
                labelId="strategies"
                id="strategies"
                defaultValue={form.strategyId}
                onChange={(e: SelectChangeEvent) => setForm({ ...form, "strategyId": e.target.value })}
                label="strategies"
                size='small'
            >
                {/* @ts-ignore */}
                {strategies.map(s => <MenuItem key={s.id} value={s.id}>
                    {s.name}
                </MenuItem>)}
            </Select>
        </TableCell>
        <TableCell align="left">
            <Select
                sx={{ width: "160px" }}
                labelId="Interventionfamily"
                id="Interventionfamily"
                defaultValue={form.interventionFamilyId}
                onChange={(e: SelectChangeEvent) => setForm({ ...form, "interventionFamilyId": e.target.value })}
                label="Interventionfamily"
                size='small'

            >
                {/* @ts-ignore */}
                {interventionfamilies.map(s => <MenuItem key={s.id} value={s.id}>
                    {s.name}
                </MenuItem>)}
            </Select>
        </TableCell>
        <TableCell align="left">
            <Select
                sx={{ width: "160px" }}
                labelId="InterventionGenus"
                id="InterventionGenus"
                defaultValue={form.interventionGenusId}
                onChange={(e: SelectChangeEvent) => setForm({ ...form, "interventionGenusId": e.target.value })}
                label="Interventiongenus"
                size='small'
            >
                {/* @ts-ignore */}
                {interventionGenuses.map(s => <MenuItem key={s.id} value={s.id}>
                    {s.name}
                </MenuItem>)}
            </Select>
        </TableCell>
        <TableCell align="left">
            <Select
                sx={{ width: "160px" }}
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                defaultValue={form.countryId}
                onChange={(e: SelectChangeEvent) => setForm({ ...form, "countryId": e.target.value })}
                label="countries"
                size='small'

            >
                {/* @ts-ignore */}
                {countries.map(c => <MenuItem key={c.id} value={c.id}>
                    {c.name}
                </MenuItem>)}
            </Select>
        </TableCell>
        <TableCell align="left">
            <Select
                sx={{ width: "160px" }}
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                defaultValue={form.regionId}
                onChange={(e: SelectChangeEvent) => setForm({ ...form, "regionId": e.target.value })}
                label="regions"
                size='small'
            >
                {/* @ts-ignore */}
                {regions.map(c => <MenuItem key={c.id} value={c.id}>
                    {c.name}
                </MenuItem>)}
            </Select>
        </TableCell>
        <TableCell align="left">
            <TextField
                id="standard-size-small"
                defaultValue={form.fiscalYear}
                size="small"
                variant="standard"
                onChange={(e) => setForm({ ...form, "fiscalYear": e.target.value })}
            />
        </TableCell>
        <TableCell align="left">
            <TextField
                id="standard-size-small"
                defaultValue={form.startDate}
                size="small"
                variant="standard"
                onChange={(e) => setForm({ ...form, "startDate": e.target.value })}
            />
        </TableCell>
        <TableCell align="left">
            <TextField
                id="standard-size-small"
                defaultValue={form.endDate}
                size="small"
                variant="standard"
                onChange={(e) => setForm({ ...form, "endDate": e.target.value })}
            />
        </TableCell>
        <TableCell align="left">
            <TextField
                id="standard-size-small"
                defaultValue={form.amountFinanced}
                size="small"
                variant="standard"
                onChange={(e) => setForm({ ...form, "amountFinanced": e.target.value })}
            />
        </TableCell>
        <TableCell align="left">
            <Select
                sx={{ width: "160px" }}
                labelId="currency"
                id="demo-simple-select-standard"
                defaultValue={form.currencyId}
                onChange={(e: SelectChangeEvent) => setForm({ ...form, "currencyId": (e.target.value) })}
                label="currencys"
                size='small'
            >
                {/* @ts-ignore */}
                {currencies.map((c, i) => <MenuItem key={i} value={c.id}>
                    {c.name}
                </MenuItem>)}
            </Select>
        </TableCell>
        <TableCell align="left">
            <Select
                sx={{ width: "160px" }}
                labelId="status"
                id="demo-simple-select-standard"
                defaultValue={form.projectStatus}
                onChange={(e: SelectChangeEvent) => setForm({ ...form, "projectStatus": (e.target.value) })}
                label="statuss"
                size='small'
            >
                {/* @ts-ignore */}
                {projectStatuses.map((c, i) => <MenuItem key={i} value={c}>
                    {c}
                </MenuItem>)}
            </Select>
        </TableCell>
        <TableCell align="left" sx={{ display: "flex", alignItems: "center" }}>
            <Tooltip title="Cancel Change">
                <IconButton
                    onClick={() => setOpenEditForm(false)}>
                    <ClearIcon sx={{ color: "red", cursor: "pointer" }} />
                </IconButton>
            </Tooltip>
            <Tooltip title="Save Status">
                <IconButton onClick={() => {
                    updateProjectForm(editFormId)
                    setOpenEditForm(false)
                }
                }>
                    <CheckIcon
                        sx={{ color: "green", cursor: "pointer" }} />
                </IconButton>
            </Tooltip>
        </TableCell>
    </>
}
