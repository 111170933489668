import { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Box, Button, TextField } from '@mui/material'
import { API_URI } from '../../util/urls';

type Mail = string | undefined;

function ForgotPasswordForm() {

    const navigate = useNavigate();


    const [mail, setMail] = useState<Mail>();
    const [message, setMessage] = useState("");

    const forgotPassword = (mail: Mail) => {


        if (mail) {
            axios
                .post(`${API_URI}/users/sendRecoveryEmail`, {
                    email: mail.trim(),
                })
                .then(() => {
                    setMail('');
                    setMessage("If this account exists, an e-mail have been sent with informations to recovery this account.");
                })
                .catch(() => {
                    setMessage("User not found");
                });
        }
    }

    return <>
        <div id="form-holder">
            <h2>Enterprise-Level Data Portal</h2>
            <p>Forgot my password</p>
            <Box component="form" onSubmit={(e) => {
                e.preventDefault();
                forgotPassword(mail)
            }} >
                <TextField
                    label="Email"
                    id="email"
                    sx={{ m: 1, width: '100%' }}
                    name="email"
                    variant="standard"
                    type="email"
                    value={mail}
                    required
                    onChange={(e) => setMail(e.target.value)}
                />
                {message.length > 1 ? <span>{message}</span> : null}
                <Button type="submit" fullWidth variant="contained" sx={{ backgroundColor: '#1D5D63', '&:hover': { backgroundColor: '#1d5d63c9' } }}>
                    RECOVER PASSWORD
                </Button>
            </Box>
            <hr />
            <Button variant="text" onClick={() => navigate("/")}>Go Back</Button>
        </div>
    </>
}

export default ForgotPasswordForm
