import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import './MuiTable.scss';
import { useNavigate } from "react-router-dom";
import { useCallback, useState } from "react";
import { Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TablePagination } from "@mui/material";
import { API_URI } from "../util/urls";

type Columns = Array<string>;
type Data = {
    id: number,
    name: string,
    email: string,
    disabled: boolean,
    userRole: string,
    partnerName?: string,
    partnerId?: string | number
}
type Datas = Array<Data>;
type Props = {
    columns: Columns,
    data: Datas,
    getData: any,
    page: number,
    setPage: any,
}

const tableCellStyles = { padding: '1rem', fontSize: '12px', cursor: 'pointer' };

export default function MuiTable(props: Props) {
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [notification, setNotification] = useState({ "show": false, "message": "" })
    const [confirmation, setConfirmation] = useState({ "show": false, "deleteId": -1 })
    let navigate = useNavigate();
    const { page, setPage } = props;

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleDeleteUser = useCallback((id: number) => {
        fetch(`${API_URI}/users/${id}/delete`, { method: 'DELETE' })
            .then(async (response) => {
                if (response.ok) {
                    setNotification({ "show": true, "message": "User Successfully Deleted" })
                    props.getData()
                }
                else {
                    const errorMessage = await response.text()
                    setNotification({ "show": true, "message": errorMessage })
                }
                setConfirmation({ ...confirmation, "show": false })
            })
    }, [confirmation, props])

    return <Box sx={{ mr: 3 }}>
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650, textAlign: 'left' }} size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        {props && props.columns.map((c, i) => <TableCell key={i}>{c}</TableCell>)}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                        <TableRow
                            key={row.id}
                            sx={{ "&:last-child td, &:last-child th": { border: 0 }, transition: 'ease all .5s', "&:hover": { backgroundColor: '#F5F5F5' } }}
                        >
                            <TableCell sx={tableCellStyles} align="left"> {row.id}  </TableCell>
                            <TableCell sx={tableCellStyles} align="left">{row.name}</TableCell>
                            <TableCell sx={tableCellStyles} align="left">{row.email}</TableCell>
                            <TableCell sx={tableCellStyles} align="left">{row.userRole}</TableCell>
                            <TableCell sx={tableCellStyles} align="left">{row.partnerName}</TableCell>
                            <TableCell sx={tableCellStyles} align="left">{row.disabled === true ? 'Inactive' : 'Active'}</TableCell>
                            <TableCell sx={tableCellStyles} align="left" onClick={() => navigate(`/admin/users/id/${row.id}`)}><EditIcon sx={{ color: 'blue' }} /></TableCell>
                            <TableCell sx={tableCellStyles} align="left" onClick={() => setConfirmation({ "show": true, "deleteId": row.id })}><DeleteIcon sx={{ color: 'red' }} /></TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
        <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            component="div"
            count={props.data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <Dialog
            open={confirmation.show}
            keepMounted
            onClose={() => setConfirmation({ ...confirmation, "show": false })}
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle>{"Are you sure you want to delete user?"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    User will be deleted after you confirm.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setConfirmation({ ...confirmation, "show": false })}>
                    Cancel
                </Button>
                <Button onClick={() => handleDeleteUser(confirmation.deleteId)}>
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
        {notification.show && <>
            <Alert
                severity={notification.message.includes("Successfully") ? "success" : "error"}
                onClose={() => setNotification({ "show": false, "message": "" })}>
                {notification.message}
            </Alert>
        </>}
    </Box>;
}
