import { useEffect, useState } from 'react'
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
type Props = {
    editMode: boolean,
    title: string,
    id: number,
    row: number,
    cellValue: string,
    enterpriseId: number,
    name: string,
    isClosed: boolean,
    isCompleted: boolean,
    isInvalid: boolean,
    isValid: boolean,
    baselineYear: string,
    typeError: string,
    hasSpace: boolean,
    isPurple?: boolean,
    historical?: any
}

function computeCellStyle(props: Props) {
    return [props.isPurple ? 'purple' : (props.isClosed || props.isCompleted) ? 'out-scope' : props.isInvalid ? 'invalid' : props.isValid ? 'valid' : '', 'cell'];
}

function CellDate(props: Props) {
    const [inputValue, setInputValue] = useState('');
    const [conflict, setConflict] = useState(false);
    const [historicalValue, setHistoricalValue] = useState('');
    const style = computeCellStyle(props).join(' ');

    useEffect(() => {
        if (props.cellValue && props.cellValue.length > 0) {
            const date = new Date(props.cellValue);
            if (
                date.getDate() >= 0 &&
                date.getMonth() >= 0 &&
                date.getFullYear() >= 0
            )
                setInputValue((date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + '/' + ((date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)) + '/' + date.getFullYear());
            else
                setInputValue('');
        }
        if (props.historical && props.historical.length > 0) {
            const dateHistorical = new Date(props.historical);
            if (
                dateHistorical.getDate() >= 0 &&
                dateHistorical.getMonth() >= 0 &&
                dateHistorical.getFullYear() >= 0
            )
                setHistoricalValue((dateHistorical.getDate() < 10 ? '0' + dateHistorical.getDate() : dateHistorical.getDate()) + '/' + ((dateHistorical.getMonth() + 1) < 10 ? '0' + (dateHistorical.getMonth() + 1) : (dateHistorical.getMonth() + 1)) + '/' + dateHistorical.getFullYear());
            else
                setHistoricalValue('');
        }
    }, [props.cellValue, props.historical])

    const confirmConflict = () => {
        setConflict(false);
        props.isValid = true;
    }
    const goBackHistory = () => {
        setConflict(false);
        props.isValid = true;
        setInputValue(historicalValue);
    }

    return (
        <div className={style}>
            <input type="text" placeholder="DD/MM/YYYY" disabled={props.isClosed || !props.editMode} value={inputValue} />
            {props.isInvalid && <span className="tooltip" md-direction="top">
                <strong>Cell empty</strong><br />
                Data is missing<br />
                <i>Improvement: fill in the blanks</i>
            </span>}
            {props.isClosed && <span className="tooltip" md-direction="top">
                <strong>Enterprise is closed</strong><br />
                No data required.
            </span>}
            {conflict && <span>
                <button onClick={confirmConflict}>
                    <DoneIcon />
                </button>
                <button className="clear" onClick={goBackHistory}>
                    <ClearIcon />
                </button>
            </span >}
            {props.isClosed && <span className="tooltip" md-direction="top">
                <strong>Enterprise is closed</strong><br />
                No data required.
            </span>}
            {conflict && <span className="tooltip" md-direction="top">
                <strong>Potential data conflict</strong><br />
                This data conflicts with the previously value: {historicalValue}.<br />
                <i>Please confirm the change, or go back to the original number.</i>
            </span>}

        </div >
    )
}

export default CellDate
