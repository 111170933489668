import { useContext, useState } from "react";
import { Link } from "react-router-dom";

import {
    Alert,
    Box,
    Breadcrumbs,
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";

import DeleteIcon from "@mui/icons-material/Delete";

import { AdminContext } from "../../../context/AdminContext";
import CreateProjectForm from "./CreateProjectForm";
import { ProjectEditForm } from "./ProjectEditForm";
import AlertDialog from "../../../widgets/AlertDialog";
import { PartnerSearch } from "../../../widgets/PartnerSearch";
import { API_URI } from "../../../util/urls";

export type Form = {
    id?: number;
    partnerId?: string;
    partnerName?: string;
    name?: string;
    prjID?: string;
    countryId?: string;
    countryName?: string;
    regionId?: string;
    regionName?: string;
    salesForceId?: string;
    segmentId?: string;
    segmentName?: string;
    strategyId?: string;
    strategyName?: string;
    interventionFamilyId?: string;
    interventionFamilyName?: string;
    interventionGenusId?: string;
    interventionGenusName?: string;
    fiscalYear?: string;
    startDate?: string;
    endDate?: string;
    amountFinanced?: number;
    currencyId?: string;
    currencyName?: string;
    projectStatus?: string;
};

function Project() {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [isOpen, setIsOpen] = useState(false);
    const [openEditForm, setOpenEditForm] = useState(false);
    const [editFormId, setEditFormId] = useState<number>(-1);
    const [selectedPartner, setSelectedPartner] = useState("");
    const [filteredPartnerLists, setFilteredPartnerLists] = useState<
        Array<any> | undefined
    >([]);
    const [form, setForm] = useState<any>({});
    const [notificationOpen, setNotificationOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [showSearch, setShowSearch] = useState(false);

    const {
        partnerList,
        projectList,
        isLoading,
        getAllProjects,
        setProjectList,
    } = useContext(AdminContext);

    const columns = [
        "id",
        "partnerName",
        "name",
        "prjID",
        "salesForceId",
        "segmentName",
        "strategyName",
        "interventionFamilyName",
        "interventionGenusName",
        "countryName",
        "regionName",
        "fiscalYear",
        "startDate",
        "endDate",
        "amountFinanced",
        "currencyName",
        "projectStatus",
    ];

    const style = {
        color: "black",
        fontSize: "14px",
        textDecoration: "none",
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const getProjectsOfPartner = (partnerId: string) => {
        fetch(`${API_URI}/admin/project/all?partnerId=${partnerId}`)
            .then((response) => response.json())
            .then((data) => setProjectList(data))
            .catch((error) => console.log(error));
    };

    const updateProjectForm = (id: number) => {
        fetch(`${API_URI}/admin/project/${id}/update`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(form),
        }).then(async (response) => {
            if (response.ok) {
                setMessage("Project Successfully Updated");
            } else {
                const errorMessage = await response.json();
                setMessage(errorMessage.error);
            }
        });
        getAllProjects();
    };

    const handleProjectDelete = (projectId: number) => {
        fetch(`${API_URI}/admin/project/${projectId}/delete`, {
            method: "DELETE",
        }).then(async (response) => {
            if (response.ok) {
                setMessage("Project Successfully Deleted");
                getAllProjects();
            } else {
                const errorMessage = await response.text();
                setMessage(errorMessage);
            }
        });
    };

    return (
        <main id="muiItemsContainer">
            <Breadcrumbs aria-label="breadcrumb">
                <Link style={style} to="/data-entry">
                    Home
                </Link>
                <Link style={style} to="/admin/project">
                    Project
                </Link>
            </Breadcrumbs>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    mt: 1,
                }}
                onClick={() => setIsOpen(true)}
            >
                Add new Project
                <AddIcon
                    sx={{ color: "green", fontSize: "40px", cursor: "pointer" }}
                />
            </Box>
            <Box sx={{ mb: 1 }}>
                <Box sx={{ mr: 2 }} onFocus={() => setShowSearch(true)}>
                    <PartnerSearch
                        partnerList={partnerList}
                        setFilteredPartnerLists={setFilteredPartnerLists}
                        setPage={setPage}
                    />
                </Box>
                <Box sx={{ maxHeight: "200px", overflow: "auto" }}>
                    {filteredPartnerLists &&
                        showSearch &&
                        filteredPartnerLists.map((o: any, i: number) => (
                            <Typography
                                sx={{
                                    cursor: "pointer",
                                    p: 1,
                                    "&:hover": {
                                        backgroundColor: "#C4E9DC",
                                    },
                                }}
                                variant="body1"
                                gutterBottom
                                key={i}
                                onClick={() => {
                                    setSelectedPartner(o.name);
                                    getProjectsOfPartner(o.id);
                                    setShowSearch(false);
                                }}
                            >
                                {o.name}
                            </Typography>
                        ))}
                    {selectedPartner.length > 0 && (
                        <Typography sx={{ mt: 2, mb: 2 }}>
                            <b>Selected Partner:</b> {selectedPartner}
                            <Button
                                onClick={() => {
                                    getAllProjects();
                                    setSelectedPartner("");
                                }}
                            >
                                View All Project
                            </Button>
                        </Typography>
                    )}
                </Box>
            </Box>
            <Box sx={{ mr: 3 }}>
                <TableContainer component={Paper}>
                    <Table
                        sx={{ width: "100%" }}
                        size="small"
                        aria-label="a dense table"
                    >
                        {projectList.length > 0 && (
                            <>
                                <TableHead>
                                    <TableRow
                                        sx={{ backgroundColor: "#A4BEC0" }}
                                    >
                                        {columns.map((c, i) => (
                                            <TableCell
                                                key={i}
                                                sx={
                                                    c === "name"
                                                        ? {
                                                              fontWeight: "600",
                                                              color: "white",
                                                              fontSize: "12px",
                                                              minWidth: "450px",
                                                          }
                                                        : {
                                                              fontWeight: "600",
                                                              color: "white",
                                                              fontSize: "12px",
                                                          }
                                                }
                                            >
                                                {c}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {projectList
                                        .slice(
                                            page * rowsPerPage,
                                            page * rowsPerPage + rowsPerPage
                                        )
                                        .map((row: any) => {
                                            return (
                                                <TableRow
                                                    key={row.id}
                                                    sx={{
                                                        "&:last-child td, &:last-child th":
                                                            { border: 0 },
                                                        transition:
                                                            "ease all .5s",
                                                        "&:hover": {
                                                            backgroundColor:
                                                                "#F5F5F5",
                                                        },
                                                    }}
                                                >
                                                    {!openEditForm &&
                                                        row.id !==
                                                            editFormId && (
                                                            <>
                                                                {columns.map(
                                                                    (c, i) => (
                                                                        <TableCell
                                                                            align="left"
                                                                            key={
                                                                                i
                                                                            }
                                                                        >
                                                                            {" "}
                                                                            {
                                                                                row[
                                                                                    c
                                                                                ]
                                                                            }{" "}
                                                                        </TableCell>
                                                                    )
                                                                )}

                                                                <TableCell align="right">
                                                                    <EditIcon
                                                                        onClick={() => {
                                                                            setOpenEditForm(
                                                                                true
                                                                            );
                                                                            setEditFormId(
                                                                                row.id
                                                                            );
                                                                            setForm(
                                                                                {
                                                                                    id: row.id,
                                                                                    partnerId:
                                                                                        row.partnerId,
                                                                                    partnerName:
                                                                                        row.partnerName,
                                                                                    name: row.name,
                                                                                    prjID: row.prjID,
                                                                                    countryId:
                                                                                        row.countryId,
                                                                                    countryName:
                                                                                        row.countryName,
                                                                                    regionId:
                                                                                        row.regionId,
                                                                                    regionName:
                                                                                        row.regionName,
                                                                                    salesForceId:
                                                                                        row.salesForceId,
                                                                                    segmentId:
                                                                                        row.segmentId,
                                                                                    segmentName:
                                                                                        row.segmentName,
                                                                                    strategyId:
                                                                                        row.strategyId,
                                                                                    strategyName:
                                                                                        row.strategyName,
                                                                                    interventionFamilyId:
                                                                                        row.interventionFamilyId,
                                                                                    interventionFamilyName:
                                                                                        row.interventionFamilyName,
                                                                                    interventionGenusId:
                                                                                        row.interventionGenusId,
                                                                                    interventionGenusName:
                                                                                        row.interventionGenusName,
                                                                                    fiscalYear:
                                                                                        row.fiscalYear,
                                                                                    startDate:
                                                                                        row.startDate,
                                                                                    endDate:
                                                                                        row.endDate,
                                                                                    amountFinanced:
                                                                                        row.amountFinanced,
                                                                                    currencyId:
                                                                                        row.currencyId,
                                                                                    currencyName:
                                                                                        row.currencyName,
                                                                                    projectStatus:
                                                                                        row.projectStatus,
                                                                                }
                                                                            );
                                                                        }}
                                                                        sx={{
                                                                            color: "blue",
                                                                            cursor: "pointer",
                                                                        }}
                                                                    />
                                                                </TableCell>
                                                                <TableCell align="right">
                                                                    <DeleteIcon
                                                                        onClick={() => {
                                                                            setNotificationOpen(
                                                                                true
                                                                            );
                                                                            setEditFormId(
                                                                                row.id
                                                                            );
                                                                        }}
                                                                        sx={{
                                                                            color: "red",
                                                                            cursor: "pointer",
                                                                        }}
                                                                    />
                                                                </TableCell>
                                                            </>
                                                        )}
                                                    {openEditForm &&
                                                        row.id ===
                                                            editFormId && (
                                                            <ProjectEditForm
                                                                partnerList={
                                                                    partnerList
                                                                }
                                                                row={row}
                                                                editFormId={
                                                                    editFormId
                                                                }
                                                                form={form}
                                                                setForm={
                                                                    setForm
                                                                }
                                                                setOpenEditForm={
                                                                    setOpenEditForm
                                                                }
                                                                updateProjectForm={
                                                                    updateProjectForm
                                                                }
                                                            />
                                                        )}
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </>
                        )}
                        {isLoading && (
                            <Alert
                                variant="filled"
                                severity="warning"
                                sx={{ fontWeight: "bolder" }}
                            >
                                <Typography
                                    variant="caption"
                                    display="block"
                                    gutterBottom
                                >
                                    LOADING <CircularProgress color="inherit" />
                                </Typography>
                            </Alert>
                        )}
                        {!isLoading && projectList.length < 1 && (
                            <Alert
                                variant="filled"
                                severity="warning"
                                sx={{ fontWeight: "bolder" }}
                            >
                                THERE IS NO PROJECT FOR THIS PARTNER
                                <Typography
                                    variant="caption"
                                    display="block"
                                    gutterBottom
                                >
                                    You can create new project clicking green
                                    "+" icon from right side
                                </Typography>
                            </Alert>
                        )}
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                        component="div"
                        count={projectList.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </TableContainer>
                {message.length > 0 && (
                    <Alert
                        severity={
                            message.includes("Successfully")
                                ? "success"
                                : "error"
                        }
                        onClose={() => setMessage("")}
                    >
                        {message}
                    </Alert>
                )}
            </Box>
            <AlertDialog
                isOpen={notificationOpen}
                setIsOpen={setNotificationOpen}
                modalTitle=" Are you sure you want to delete project ?"
                modalContent=" Selected project will be deleted."
                handleAgree={() => {
                    editFormId && handleProjectDelete(editFormId);
                    setNotificationOpen(false);
                }}
            />
            {isOpen && (
                <CreateProjectForm
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    setMessage={setMessage}
                    getAllProjects={getAllProjects}
                />
            )}
        </main>
    );
}

export default Project;
